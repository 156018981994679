import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { accountCodeApi } from '@app/store';
import { IAccountCode, AccountCodeDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { AccountCodeForm } from './AccountCodeForm';
import { AccountCodeFormValues } from './account-code-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormAccountCode = (accountCode?: Partial<IAccountCode>): AccountCodeFormValues => {
  return {
    name: accountCode?.name,
    code: accountCode?.code
  }
}

interface AccountCodeUpdateProps {
  accountCode?: IAccountCode;
  isFetching?: boolean;
  onDelete: (product: Partial<IAccountCode>) => void;
}

export const AccountCodeUpdate = ({ accountCode, isFetching, onDelete }: AccountCodeUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = accountCodeApi.useUpdateOneAccountCodesMutation();

  const formMethods = useForm<AccountCodeFormValues>({
    resolver: classValidatorResolver(AccountCodeDTO),
    defaultValues: transformToUpdatableFormAccountCode(accountCode),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormAccountCode(accountCode));
  }, [accountCode, formMethods.reset]);

  const onUpdate = async (formValues: AccountCodeFormValues) => {
    try {
      const updatedAccountCode = await update({ _id: accountCode?._id, ...formValues }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedAccountCode });

      toast.success('Votre compte comptable a bien été modifié.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre compte comptable.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(accountCode!)}
              bordered
              important />
          </Panel.Header>

          <AccountCodeForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

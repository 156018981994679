import { IsNotEmpty } from "class-validator";
import { IPaymentMethod, IBase, PaymentType } from "../interfaces";

export class PaymentMethodDTO implements Omit<IPaymentMethod, keyof IBase> {
  @IsNotEmpty({ message: 'Le nom du type de paiement est requis' })
  name: string;

  @IsNotEmpty({ message: 'Le type de paiement est requis' })
  specialType: PaymentType;

  disabled: boolean;

  return: string;

  negativeReturn: boolean;

  openCashRegister: boolean;

  authorizeCount: boolean;

  position: number;

  accountingCode: number;

  apiKey: string;

}
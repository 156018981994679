import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
export const SvgSave = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <Path d="M42 17.91v22.545c0 .853-.692 1.545-1.545 1.545H9.545A1.545 1.545 0 0 1 8 40.455V9.545C8 8.692 8.692 8 9.545 8H32.09c.405-.001.794.158 1.082.444l8.384 8.384c.286.288.445.677.444 1.082h0Z" />
      <Path d="M16 42V29.556c0-.86.709-1.556 1.583-1.556h15.834c.874 0 1.583.696 1.583 1.556V42M29.688 14.062H18.75" />
    </G>
  </Svg>
);


import { IBase } from "../base.interface";
import { IStepToProductVariation } from "./step-to-product-variation.interface";

export interface IStep extends IBase {
  name: string;

  disabled: boolean;

  mandatory: boolean;

  maxChoiceCount: number;

  stepToProductVariations: IStepToProductVariation[];
}

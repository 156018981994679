
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder, QueryDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { IBase, IPaginatedData, QueryManyBuilder, QueryOneBuilder } from '@tickeat/common';
import { baseQueryWithReauth, providesList, rootApi, TagTypes } from './api';
import { isDefined, isFunction } from '@app/helpers/kindOf';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { PascalCase } from 'type-fest';

type Builder<TType> = EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, TagTypes, "api">

type DeleteMutation<TType> = MutationDefinition<string, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, any, TType, "api">
type UpdateMutation<TType> = MutationDefinition<Partial<TType> & { formData?: FormData }, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, any, TType, "api">

type CreateOne<TType extends IBase, TUrl extends string> = {
  [key in `createOne${PascalCase<TUrl>}`]: UpdateMutation<TType>;
}

type UpdateOne<TType extends IBase, TUrl extends string> = {
  [key in `updateOne${PascalCase<TUrl>}`]: UpdateMutation<TType>;
}

type DeleteOne<TType extends IBase, TUrl extends string> = {
  [key in `deleteOne${PascalCase<TUrl>}`]: DeleteMutation<TType>;
}

type ReadMany<TType extends IBase, TUrl extends string> = {
  [key in `readMany${PascalCase<TUrl>}`]: QueryDefinition<QueryManyBuilder<TType>, typeof baseQueryWithReauth, TagTypes, IPaginatedData<TType>, 'api'>;
}

type ReadOne<TType extends IBase, TUrl extends string> = {
  [key in `readOne${PascalCase<TUrl>}`]: QueryDefinition<QueryOneBuilder<TType>, typeof baseQueryWithReauth, TagTypes, TType, 'api'>;
}

type CrudEndpointsUnion<TType extends IBase, TUrl extends string> = CreateOne<TType, TUrl>
  & ReadMany<TType, TUrl>
  & ReadOne<TType, TUrl>
  & UpdateOne<TType, TUrl>
  & DeleteOne<TType, TUrl>;

export const createOneEndpoint = <TType extends IBase>(builder: Builder<TType>, url: string, tagType: TagTypes) => {
  return <V extends IBase = TType>() => {
    return builder.mutation<V, Omit<Partial<V>, keyof IBase>>({
      invalidatesTags: [{ type: tagType, id: 'LIST' }],
      query: (body) => ({
        url,
        method: 'POST',
        body,
      }),
    });
  }
}

export const readManyEndpoint = <TType extends IBase>(builder: Builder<TType>, url: string, tagType: TagTypes) => {
  return () => {
    return builder.query<IPaginatedData<TType>, QueryManyBuilder<TType>>({
      providesTags: (result) => providesList(result?.data, tagType),
      query: (params) => ({
        url,
        params,
        method: 'GET',
      }),
    });
  }
}

export const readOneEndpoint = <TType extends IBase>(builder: Builder<TType>, url: string, tagType: TagTypes) => {
  return () => {
    return builder.query<TType, QueryOneBuilder<TType>>({
      // Not sure about this
      providesTags: (result) => {
        if (isDefined(result)) {
          return providesList([result], tagType)
        }

        return [];
      },
      query: (params) => ({
        url: `${url}/singleResult`,
        params,
        method: 'GET'
      }),
    });
  }
}

export const updateOneEndpoint = <TType extends IBase>(builder: Builder<TType>, url: string, tagType: TagTypes) => {
  return <V extends IBase = TType>() => {
    return builder.mutation<V, Partial<V> & { formData?: FormData }>({
      invalidatesTags: (_, __, arg) => [{ type: tagType, id: arg._id }],
      query: ({ _id, formData, ...body }) => {
        return {
          url: `${url}/${_id}`,
          method: 'PUT',
          body: formData || body,
        }
      },
    });
  }
}

export const deleteOneEndpoint = <TType extends IBase>(builder: Builder<TType>, url: string, tagType: TagTypes) => {
  return <V extends IBase = TType>() => {
    return builder.mutation<V, string>({
      invalidatesTags: (_, __, arg) => [{ type: tagType, id: arg }],
      query: (id) => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
    });
  }
}

export const crudEndpointsFactory = <TType extends IBase>() => {
  return <TUrl extends string>(url: TUrl, tagType: TagTypes) => {
    const resource = url
      .replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase())
      .replace(/\-/g, '')

    return rootApi.injectEndpoints({
      endpoints: (builder) => ({
        [`createOne${resource}`]: createOneEndpoint<TType>(builder, url, tagType)(),
        [`readMany${resource}`]: readManyEndpoint<TType>(builder, url, tagType)(),
        [`readOne${resource}`]: readOneEndpoint<TType>(builder, url, tagType)(),
        [`updateOne${resource}`]: updateOneEndpoint<TType>(builder, url, tagType)(),
        [`deleteOne${resource}`]: deleteOneEndpoint<TType>(builder, url, tagType)(),
      } as CrudEndpointsUnion<TType, TUrl>)
    });
  }
}

export const crudEndpoints = <TType extends IBase>(builder: Builder<any>, url: string, tagType: TagTypes) => {
  return {
    createOne: createOneEndpoint<TType>(builder, url, tagType),
    readMany: readManyEndpoint<TType>(builder, url, tagType),
    readOne: readOneEndpoint<TType>(builder, url, tagType),
    updateOne: updateOneEndpoint<TType>(builder, url, tagType),
    deleteOne: deleteOneEndpoint<TType>(builder, url, tagType)
  }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animatedSubcontainer {
  transition: transform 0.5s ease-out;
}

.animatedSubcontainer.open {
  transform: translateX(calc(-64vw + 85px));
}

.animatedSubcontainer.close {
  transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/triptych.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".animatedSubcontainer {\n  transition: transform 0.5s ease-out;\n}\n\n.animatedSubcontainer.open {\n  transform: translateX(calc(-64vw + 85px));\n}\n\n.animatedSubcontainer.close {\n  transform: translateX(0);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { BaseSelect, TextInput } from "@app/components";
import { NumberSelector } from "@app/components/form/NumberSelector";
import { Toggle } from "@app/components/form/Toggle";
import { ProductVariationSelect } from "@app/components/form/selects/features/ProductVariationSelect";
import { StyledForms } from "@app/components/styled/form/Form";
import { createColumnHelper } from "@tanstack/react-table";
import { PriceInput } from "@app/components/form/PriceInput";

const columnHelper = createColumnHelper<any>();

const selectedColumns = [
  columnHelper.accessor('name', {
    header: () => (
      <BaseSelect.ListHeaderItem width="65%">
        Nom
      </BaseSelect.ListHeaderItem >
    ),
    cell: (info) => (
      <BaseSelect.ListItemCol width="65%">
        <BaseSelect.ListItemText numberOfLines={1} bold>
          {info.row.original.productVariation.name}
        </BaseSelect.ListItemText>
      </BaseSelect.ListItemCol>
    ),
  }),
  columnHelper.display({
    id: 'price',
    header: () => (
      <BaseSelect.ListHeaderItem width="35%">
        Prix
      </BaseSelect.ListHeaderItem>
    ),
    cell: (info) => (
      <BaseSelect.ListItemCol width="35%">
        <BaseSelect.ListItemText numberOfLines={1} bold>
          <PriceInput name={`stepToProductVariations.${info.row.index}.price`} size='small' />
        </BaseSelect.ListItemText>
      </BaseSelect.ListItemCol>
    ),
  }),
];

export const StepForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <Toggle
        name="disabled"
        options={[
          { title: "Actif", value: false },
          { title: "Inactif", value: true },
        ]} />

      <Toggle
        name='mandatory'
        options={[
          { title: "Obligatoire", value: true },
          { title: "Facultative", value: false },
        ]} />

      <ProductVariationSelect
        name="stepToProductVariations"
        transform={{
          value: (item) => ({ productVariation: item, price: 0 }),
          accessor: (item) => (item.productVariation?._id),
          display: (item) => (item?.productVariation?.name)
        }}
        selectedColumns={selectedColumns}
        isMultiple />

      <NumberSelector name='maxChoiceCount' label='Nombre max' />

    </StyledForms.Form>
  );
};

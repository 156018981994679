import { IBase } from "../base.interface";
import { ICatalogShop } from "./catalog-shop.interface";
import { ICatalogToPack } from "./catalog-to-pack.interface";
import { ICatalogToProduct } from "./catalog-to-product.interface";
import { ICatalogToPromotion } from "./catalog-to-promotion.interface";
import { ICatalogToTag } from "./catalog-to-tag.interface";
import { IPlatform } from "./platform.interface";

export interface ICatalogTicketInfos {
  logo?: string;
  bottomLines?: string;
}

export interface ICatalog extends IBase {
  name: string;

  startDate: string;

  endDate: string;

  platforms: IPlatform[];

  catalogToPromotions: ICatalogToPromotion[];

  catalogToTags: ICatalogToTag[];

  catalogToShops: ICatalogShop[];

  catalogToPacks: ICatalogToPack[];

  catalogToProducts: ICatalogToProduct[];

  ticketInfos?: ICatalogTicketInfos;

  hubriseCatalogId?: string;
}

export const isCatalogWithHubrise = (catalog: ICatalog): boolean => {
  return !!catalog.hubriseCatalogId;
}
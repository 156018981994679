import { Card, CardButtonActionMode, HeaderGradient, MiniCard, Row } from '@app/components';
import React, { memo } from 'react';
import SvgInfo from '@app/components/icons/SvgInfo';
import SvgStats from '@app/components/icons/SvgStats';
import SvgList from '@app/components/icons/SvgList';
import { SlantTitle } from '@app/components/typography';
import { theme } from '@app/theme';
import { useDynamicSizes } from '@app/hooks';
import { ButtonIcon } from '@app/components/form/buttons/ButtonIcon';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet } from 'react-native';
import { IProduct } from '@tickeat/common';
import SvgImage from '@app/components/icons/SvgImage';
import { UseFormReturn, useWatch } from 'react-hook-form';

type ProductCardProps = {
  product?: Partial<IProduct>;
  formMethods: UseFormReturn<any, any, any>;
  onOpenImagePicker: () => void;
  toggle: (mode: CardButtonActionMode) => void;
};

const formatCardData = (partialProduct: Partial<IProduct>) => {
  return {
    name: partialProduct?.name || 'Nouveau produit',
    tags: partialProduct?.tags?.map(tag => tag.name).join(', ') || '-',
    totalOfProductVariations: partialProduct?.productVariations?.length || '-',
    catalogs: partialProduct?.catalogToProducts?.map(catalogToProduct => (catalogToProduct?.catalog?.name?.replace('Catalogue', '')) || '').join(', ') || '-'
  }
}

export const ProductCard = memo(({ product, formMethods, toggle, onOpenImagePicker }: ProductCardProps) => {
  const { subContainerHeight } = useDynamicSizes();

  const cardData = useWatch({
    control: formMethods.control,
  });

  const {
    name,
    tags,
    catalogs,
    totalOfProductVariations
  } = formatCardData(cardData);

  const previewUrl = cardData.image ? URL.createObjectURL(cardData.image) : null;

  return (
    <Card>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient}
        />
      </HeaderGradient>

      <Card.TopHeader>
        <Card.TopHeaderLabel>Fiche produit</Card.TopHeaderLabel>
      </Card.TopHeader>

      <Card.Container height={subContainerHeight}>
        <Card.Background source={require('@app/assets/img/cardbackground.png')} />

        <Card.Content>
          <Card.Header>
            <Row justify="space-between" align="center">
              <Card.HeaderTitles>
                <Card.Title numberOfLines={2}>{name}</Card.Title>
                {product?._id && <Card.Subtitle>Ref: {product._id}</Card.Subtitle>}
              </Card.HeaderTitles>

              <Card.ButtonAction onPress={toggle} />
            </Row>

            <Card.HeaderValues>
              <Card.HeaderValuesItem>
                <Card.Key numberOfLines={1}>Catégories</Card.Key>
                <Card.Value>{tags}</Card.Value>
              </Card.HeaderValuesItem>
              <Card.HeaderValuesItem>
                <Card.Key numberOfLines={1}>Déclinaisons</Card.Key>
                <Card.Value>{totalOfProductVariations}</Card.Value>
              </Card.HeaderValuesItem>
              <Card.HeaderValuesItem>
                <Card.Key numberOfLines={1}>Catalogues</Card.Key>
                <Card.Value numberOfLines={3}>{catalogs}</Card.Value>
              </Card.HeaderValuesItem>
            </Card.HeaderValues>
          </Card.Header>

          <Card.VisualContainer>
            <Card.Visual source={{ uri: previewUrl ? previewUrl : product?.images?.xxlarge?.webp?.url }} />

            <Card.VisualEdit>
              <ButtonIcon Icon={SvgImage} onPress={() => onOpenImagePicker()} />
            </Card.VisualEdit>
          </Card.VisualContainer>
        </Card.Content>

        <MiniCard>
          <MiniCard.Tabs>
            <MiniCard.TabsHolder>
              <MiniCard.Tab Icon={SvgInfo} isActive />
              <MiniCard.Tab Icon={SvgStats} />
              <MiniCard.Tab Icon={SvgList} />
            </MiniCard.TabsHolder>
          </MiniCard.Tabs>
          <MiniCard.Container>
            <MiniCard.Content>
              <SlantTitle label="Aide" color={theme.colors.gold} />
            </MiniCard.Content>
          </MiniCard.Container>
        </MiniCard>
      </Card.Container>
    </Card>
  );
});

const styles = StyleSheet.create({
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});

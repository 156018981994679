import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IPack } from "@tickeat/common";

interface PackDeleteModalProps extends ModalInstance<IPack>, Omit<DeleteModalProps<IPack>, 'children'> {
  onDelete: (product: Partial<IPack>) => void;
}

export const PackDeleteModal = (props: PackDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer un menu.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible. Vous perdrez toutes les données liées à ce menu.
      </Modal.Message>
    </DeleteModal>
  )
}
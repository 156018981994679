import styled from '@2pulse/responsive';
import { normalize, withComponents } from '@app/helpers';
import { row } from '@app/style';

const Container = styled.View<{ height: number }>(({ theme, props }) => ({
  marginTop: theme.sizes.listHeaderHeight,
  height: props.height - theme.sizes.listHeaderHeight,
  backgroundColor: theme.colors.oxfordblue,
  paddingBottom: 0,
  flexDirection: 'row',
  width: '100%'
  // overflow: 'hidden'
}));

const Content = styled.View(({ theme }) => ({
  // flexGrow: 1,
  height: '100%',
  width: theme.sizes.layoutForm,
  flexShrink: 1
}));

const Header = styled.View({
  ...row,
  justifyContent: 'flex-end',
  marginBottom: normalize(34),
});

const FormContainer = styled.ScrollView({
  paddingTop: normalize(55),
  paddingHorizontal: normalize(55),
  paddingBottom: 144,
  height: '100%',
  flexGrow: 1,
  overflow: 'hidden',
});

const Actions = styled.View(({ theme }) => ({
  ...row,
  columnGap: normalize(144),
  paddingTop: normalize(34),
  paddingBottom: normalize(55),
  justifyContent: 'space-between',
  backgroundColor: theme.colors.oxfordblue,

  position: 'absolute',
  width: '100%',
  paddingHorizontal: normalize(55),
  bottom: 0,
  left: 0,
}));

export const Panel = withComponents(styled.View({
  // width: '58%',
  flexDirection: 'row',
  width: '100%',
  zIndex: 99,
  flexShrink: 0,
  display: 'flex',
}), {
  Container,
  Content,
  Header,
  FormContainer,
  Actions
});

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { stepApi } from '@app/store';
import { IStep, StepDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { StepForm } from './StepForm';
import { StepFormValues, transformStepFormValuesToPayload } from './step-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const StepCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = stepApi.useCreateOneStepMutation();

  const formMethods = useForm<StepFormValues>({
    resolver: classValidatorResolver(StepDTO),
    defaultValues: {
      name: '',
      disabled: false,
      mandatory: false,
      maxChoiceCount: 0,
      stepToProductVariations: []
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: Partial<IStep>) => {
    try {
      const step = await create(transformStepFormValuesToPayload(formValues)).unwrap();

      triptych.close({ mode: 'UPDATE', data: step });

      toast.success('Votre étape a bien été ajoutée.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre étape.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>
          <StepForm />
        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            title="Valider"
            loading={isLoading}
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { OptionList, OptionCard, OptionCreate, OptionUpdate, OptionDeleteModal } from '@app/features/Option';
import { optionApi } from '@app/store';
import { ProductHeader } from '@app/features';
import { useResourceScreenHelpers } from '@app/hooks';
import { IOption } from '@tickeat/common';

export const OptionScreen = () => {
  const [readOne, { isFetching }] = optionApi.useLazyReadOneOptionQuery();
  const [deleteOne, { isLoading }] = optionApi.useDeleteOneOptionMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: option } = useResourceScreenHelpers<IOption>();

  const openCard = (item: Partial<IOption>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
      populate: [
        'products'
      ]
    }), item);
  }

  return (
    <MainLayout
      header={
        <ProductHeader
          buttonTitle='Ajouter une option'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      }>

      <OptionDeleteModal
        {...modal}
        onDelete={(option) => onDelete(deleteOne(option._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <OptionList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={<OptionCard option={option} toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />}
        form={(
          mode === 'CREATE'
            ? <OptionCreate />
            : <OptionUpdate
              option={option}
              isFetching={isFetching}
              onDelete={modal.open} />)} />
    </MainLayout>
  );
};


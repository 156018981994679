import { IProduct } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi, rootMiddleware } from '../api';
import { isAnyOf } from '@reduxjs/toolkit';
import { productVariationApi } from './product-variation';

const url = 'products';
const tagType = 'Products';

export const productApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<IProduct>(builder, url, tagType);

    return {
      createOneProducts: builder.mutation<IProduct, FormData>({
        invalidatesTags: [{ type: tagType, id: 'LIST' }],
        query: (body) => ({
          url,
          method: 'POST',
          body,
        }),
      }),
      updateOneProducts: builder.mutation<Partial<IProduct>, { _id: string, formData: FormData }>({
        invalidatesTags: (_, __, arg) => [{ type: tagType, id: arg._id }],
        query: ({ _id, formData }) => ({
          url: `${url}/${_id}`,
          method: 'PUT',
          body: formData,
        }),
      }),
      readManyProducts: endpoints.readMany(),
      __readOneProducts: endpoints.readOne(),
      readOneProduct: builder.query<IProduct, string>({
        queryFn: async (productId, { dispatch }) => {
          const product = await dispatch(productApi.endpoints.__readOneProducts.initiate({
            filters: { _id: { $eq: productId } },
            populate: [
              'tags',
              'options',
              'catalogToProducts',
              'catalogToProducts.catalog',
            ]
          }, { forceRefetch: true })).unwrap();

          const paginatedProductVariations = await dispatch(productVariationApi.endpoints.readManyProductVariations.initiate({
            filters: { productId: { $eq: productId } },
            populate: [
              'variation',
              'tax',
              'takeAwayTax',
              'deliveryTax',
              'onSpotTax',
              'accountCode',
              'productVariationToIngredients',
              'productVariationToIngredients.ingredient'
            ]
          }, { forceRefetch: true })).unwrap();

          const productVariations = paginatedProductVariations?.data ?? [];

          const data: IProduct = {
            ...product,
            productVariations
          }

          return { data };
        },
      }),
      deleteOneProducts: endpoints.deleteOne(),
    }
  },
});


rootMiddleware.startListening({
  matcher: isAnyOf(
    productApi.endpoints.createOneProducts.matchFulfilled,
    productApi.endpoints.deleteOneProducts.matchFulfilled,
    productApi.endpoints.updateOneProducts.matchFulfilled,
  ),
  effect: (_, listenerApi) => {
    listenerApi.dispatch(productApi.util.invalidateTags(['ProductVariations']));
  }
})
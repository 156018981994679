import React from 'react';
import Svg, { SvgProps, Defs, RadialGradient, Stop, Path, G, Use } from 'react-native-svg';

export const SvgAppbackground = (props: SvgProps) => (
  <Svg viewBox="0 0 1366 1024" width="100%" height="100%" preserveAspectRatio="none" {...props}>
    <Defs>
      <RadialGradient
        cx="115.634%"
        cy="95.129%"
        fx="115.634%"
        fy="95.129%"
        r="100%"
        gradientTransform="matrix(0 1 -.74963 0 1.87 -.205)"
        id="appbackground_svg__b">
        <Stop stopColor="#F0F9FC" offset="0%" />
        <Stop stopColor="#F8FCFD" offset="100%" />
      </RadialGradient>
      <RadialGradient
        cx="-1.093%"
        cy="-2.043%"
        fx="-1.093%"
        fy="-2.043%"
        r="100%"
        gradientTransform="matrix(0 1 -.74963 0 -.026 -.01)"
        id="appbackground_svg__c">
        <Stop stopColor="#F0F9FC" offset="0%" />
        <Stop stopColor="#F8FCFD" offset="100%" />
      </RadialGradient>
      <Path id="appbackground_svg__a" d="M0 0h1366v1024H0z" />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Use fill="#FFF" xlinkHref="#appbackground_svg__a" />
      <Use fill="url(#appbackground_svg__b)" xlinkHref="#appbackground_svg__a" />
      <Use fill="url(#appbackground_svg__c)" xlinkHref="#appbackground_svg__a" />
    </G>
  </Svg>
);

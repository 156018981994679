import { Select, SelectProps } from '@app/components';
import { extractData } from '@app/helpers/utils';
import { usePagination, useThrottle } from '@app/hooks';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useState } from 'react';
import { IBase, IPaginatedData, QueryManyBuilder } from '@tickeat/common';
import { baseQueryWithReauth } from '@app/store/api';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';

export type ResourceSelectParentProps<TData> = Omit<SelectProps<TData>, 'table' | 'control' | 'onSelect' | 'onRemove' | 'render' | 'values'>;

type ResourceSelectProps<TData> = {
  label: string;
  nameKey?: string;
  useQuery: UseQuery<QueryDefinition<QueryManyBuilder<TData>, typeof baseQueryWithReauth, any, IPaginatedData<TData>>>
  columns: ColumnDef<TData, any>[];
} & ResourceSelectParentProps<TData>

export const ResourceSelect = <TData,>({ useQuery, nameKey = 'name', columns, ...props }: ResourceSelectProps<any>) => {
  const [search, setSearch] = useState('');

  const throttledValue = useThrottle(search, 250);

  const { pagination } = usePagination({
    take: 25,
  });

  const { data } = useQuery({
    pagination,
    sort: [`${nameKey}:ASC`],
    filters: {
      [nameKey]: {
        $contains: throttledValue
      }
    }
  } as any);

  const table = useReactTable({
    data: extractData(data),
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Select
      {...props}
      table={table}
      onSearch={setSearch} />
  );
};

import * as React from 'react';
import { SvgProps, Svg, G, Path, Circle, Rect } from 'react-native-svg';
function SvgImage({ strokePath, ...props }: SvgProps) {
  return (
    <Svg width={'100%'} height={'100%'} viewBox="0 0 50 50" {...props}>
      <G transform="translate(6 10)" fill="none" fillRule="evenodd">
        <Rect
          stroke={strokePath || props.color}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          width={38}
          height={31}
          rx={1.563}
        />
        <Path
          d="m0 23.972 9.955-9.52A1.62 1.62 0 0 1 11.083 14c.424 0 .83.163 1.128.452l8.828 8.44a1.62 1.62 0 0 0 1.128.453c.424 0 .83-.163 1.128-.452l4.077-3.899a1.62 1.62 0 0 1 1.128-.452c.424 0 .83.163 1.128.452L38 27"
          stroke={strokePath || props.color}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Circle fill={props.color} fillRule="nonzero" cx={24} cy={11} r={3} />
      </G>
    </Svg>
  );
}
export default SvgImage;

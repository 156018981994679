import { Button } from "@app/components";
import { Modal } from "@app/components/modals";
import { useModal } from "@app/hooks/useModal";
import { modalsSelectors } from "@app/store";
import { useAppSelector } from "@app/store/hooks";

interface ShopHubriseConnectModalProps {
  onConnect: (data: any) => void;
  isLoading?: boolean;
}

export const ShopHubriseConnectModalId = 'ShopHubriseConnectModalId';

export const ShopHubriseConnectModal = ({ onConnect, isLoading }: ShopHubriseConnectModalProps) => {
  const modalInstance = useModal(ShopHubriseConnectModalId);
  const modal = useAppSelector(state => modalsSelectors.selectById(state, ShopHubriseConnectModalId));

  return (
    <Modal id={ShopHubriseConnectModalId} isOpen={!!modal?.isOpen}>
      <Modal.Title>Informations</Modal.Title>

      <Modal.Subtitle>Vous allez connecter ce restaurant à Hubrise.</Modal.Subtitle>

      <Modal.Message>
      </Modal.Message>

      <Modal.Action>
        <Button title='Annuler' onPress={() => modalInstance.close()} large />
        <Button title='Connecter' onPress={() => onConnect(modal?.data)} large bordered loading={isLoading} />
      </Modal.Action>
    </Modal>
  );
}
import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const SvgMenuMenus = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 50 50" width={'100%'} height={'100%'} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h50v50H0z" />
      <Path
        d="M39.063 34.375V12.5a4.668 4.668 0 0 0-4.688-4.688H7.812M20.313 20.313h12.5M20.313 26.563h12.5"
        stroke={strokePath}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.316 15.625A4.688 4.688 0 1 1 12.5 12.5v25a4.688 4.688 0 1 0 8.184-3.125h21.875a4.688 4.688 0 0 1-3.496 7.813H17.186"
        stroke={strokePath}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

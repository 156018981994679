import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { paymentMethodApi } from '@app/store';
import { PaymentMethodDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { PaymentMethodForm } from './PaymentMethodForm';
import { PaymentMethodFormValues } from './payment-method-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const PaymentMethodCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = paymentMethodApi.useCreateOnePaymentMethodsMutation();

  const formMethods = useForm<PaymentMethodFormValues>({
    resolver: classValidatorResolver(PaymentMethodDTO),
    defaultValues: {
      name: '',
      disabled: false,
      specialType: undefined
    },
  });

  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: PaymentMethodFormValues) => {
    try {
      const paymentMethod = await create(formValues).unwrap();

      triptych.close({ mode: 'UPDATE', data: paymentMethod });

      toast.success('Votre moyen de paiement a bien été ajouté.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre moyen de paiement.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <PaymentMethodForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

import { registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";

const isSorted = (val: number[]): boolean => {
  return !!val.every((value: number, index: number, array: number[]) => !index || array[index - 1] < value);
}

@ValidatorConstraint()
export class IsValidSlotDTOConstraint implements ValidatorConstraintInterface {
  validate(slots: number[][], args: ValidationArguments) {
    const slotsFlated = slots.flat(1);
    const slotsFiltered = slots.filter(slot => Array.isArray(slot) && slot.length === 2);
    const slotsFlatedFiltered = slotsFlated.filter(slot => typeof slot === 'number' && slot >= 0 && slot <= 1440);

    if (!isSorted(slotsFlated)) return false;
    if (slotsFlatedFiltered.length !== slotsFlated.length) return false;
    if (slotsFiltered.length !== slots.length) return false;

    return true;
  }
}

export function IsValidSlot(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
      registerDecorator({
        target: object.constructor,
        propertyName: propertyName,
        options: validationOptions,
        constraints: [],
        validator: IsValidSlotDTOConstraint,
      });
    };
  }
import React from 'react';
import { Text, TextProps } from 'react-native';

export const Label = ({ children, ...props }: TextProps) => {
  return (
    <Text accessibilityRole="label" {...props}>
      {children}
    </Text>
  );
};

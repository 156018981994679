import { useCallback, useContext } from "react";
import { WizardDispatchContext } from "./WizardProvider";
import { ACTIONS, AddStepPayload, RemoveStepPayload } from "./wizard.reducer";

export function useWizard() {
  const { dispatch } = useContext(WizardDispatchContext);

  const addStep = useCallback((payload: AddStepPayload) => {
    dispatch({
      type: ACTIONS.ADD_STEP,
      payload
    });
  }, []);

  const removeStep = useCallback((payload: RemoveStepPayload) => {
    dispatch({
      type: ACTIONS.REMOVE_STEP,
      payload
    });
  }, []);

  const updateStep = useCallback(() => {
    dispatch({
      type: ACTIONS.NEXT_STEP,
    });
  }, []);

  const nextStep = useCallback(() => {
    dispatch({
      type: ACTIONS.NEXT_STEP,
    });
  }, []);

  const previousStep = useCallback(() => {
    dispatch({
      type: ACTIONS.PREVIOUS_STEP,
    });
  }, []);

  const goToStep = useCallback((step: number) => {
    dispatch({
      type: ACTIONS.GO_TO_STEP,
      payload: {
        step
      }
    });
  }, []);

  return {
    addStep,
    updateStep,
    nextStep,
    previousStep,
    goToStep,
    removeStep
  }
}
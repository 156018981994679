import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { CatalogCreate, CatalogDeleteModal, CatalogHeader, CatalogList, CatalogUpdate } from '@app/features/Catalog';
import { useResourceScreenHelpers, useToast } from '@app/hooks';
import { catalogApi } from '@app/store';
import { ICatalog } from '@tickeat/common';
import { CatalogHubrisePublishModal, CatalogHubrisePublishModalId } from '@app/features/Catalog/CatalogHubrisePublishModal';
import { useModal } from '@app/hooks/useModal';

const extractApiCustomMessage = (error: any) => {
  return error?.data?.data?.message;
}

export const CatalogScreen = () => {
  const toast = useToast();
  const modal = useModal(CatalogHubrisePublishModalId);

  const [readOne, { isFetching }] = catalogApi.useLazyReadOneCatalogWithRelationsQuery();
  const [deleteOne, { isLoading }] = catalogApi.useDeleteOneCatalogsMutation();
  const [publish, { isLoading: isPublishing }] = catalogApi.useSendToHubriseMutation();

  const { triptych, modal: deleteModal, onDelete, onUpdate, mode, selectedItem, item: catalog } = useResourceScreenHelpers<ICatalog>();

  const openCard = (item: Partial<ICatalog>) => {
    onUpdate(readOne(item?._id!), item);
  }

  const onPublish = async (params: { catalogId: string }) => {
    try {
      await publish(params).unwrap();

      toast.success('Votre catalogue à bien été publié.')
    } catch (error) {
      const message = extractApiCustomMessage(error) || 'Une erreur est survenue lors de la publication du catalogue.';

      toast.error(message, { autoClose: 5000 })
    }

    modal.close();
  }

  return (
    <MainLayout
      header={(
        <CatalogHeader
          buttonTitle='Ajouter un catalogue'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <CatalogHubrisePublishModal
        {...modal}
        isLoading={isPublishing}
        onPublish={onPublish} />

      <CatalogDeleteModal
        {...deleteModal}
        onDelete={(catalog) => onDelete(deleteOne(catalog._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <CatalogList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onPublish={(catalog) => modal.open({ catalogId: catalog._id })}
            onUpdate={openCard}
            onDelete={deleteModal.open} />
        )}
        form={(
          mode === 'CREATE'
            ? <CatalogCreate />
            : <CatalogUpdate
              catalog={catalog ?? selectedItem}
              toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.close()}
              isFetching={isFetching}
              onDelete={deleteModal.open} />
        )} />
    </MainLayout>
  );
};


import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IAdvertisement, IPack } from "@tickeat/common";

interface AdvertisementDeleteModalProps extends ModalInstance<IPack>, Omit<DeleteModalProps<IAdvertisement>, 'children'> {
  onDelete: (advertisement: Partial<IAdvertisement>) => void;
}

export const AdvertisementDeleteModal = (props: AdvertisementDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer une publicité.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible. Vous perdrez toutes les données liées à cette publicité.
      </Modal.Message>
    </DeleteModal>
  )
}
import React from 'react';
import { BaseResourceListProps, SimpleList, SvgBin, SvgEdit } from '@app/components';
import { catalogApi } from '@app/store';
import { ICatalog, isCatalogWithHubrise } from '@tickeat/common';
import { theme } from '@app/theme';
import { createColumnHelper } from '@tanstack/react-table';
import { useTable } from '@app/hooks/useTable';
import { SvgTransfer } from '@app/components/icons/SvgTransfer';

const columnHelper = createColumnHelper<ICatalog>();

interface CatalogListProps extends BaseResourceListProps<ICatalog> {
  onPublish: (catalog: ICatalog) => void;
}

export const CatalogList = ({ onPublish, onUpdate, onDelete, onRowPress, selectedRowItem }: CatalogListProps) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <SimpleList.HeaderItem width="50%">
          <SimpleList.HeaderItemLabel>Nom</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="50%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.display({
      id: 'published',
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Publié sur Hubrise</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {isCatalogWithHubrise(info.row.original) ? 'Oui' : 'Non'}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    // columnHelper.accessor('catalogToPacks', {
    //   header: () => (
    //     <SimpleList.HeaderItem width="12.5%">
    //       <SimpleList.HeaderItemLabel>Menus</SimpleList.HeaderItemLabel>
    //     </SimpleList.HeaderItem>
    //   ),
    //   cell: (info) => (
    //     <SimpleList.ItemCol width="12.5%">
    //       <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
    //         {info.getValue()?.length}
    //       </SimpleList.ItemText>
    //     </SimpleList.ItemCol>
    //   ),
    // }),
    // columnHelper.accessor('promotions', {
    //   header: () => (
    //     <SimpleList.HeaderItem width="12.5%">
    //       <SimpleList.HeaderItemLabel>Promotions</SimpleList.HeaderItemLabel>
    //     </SimpleList.HeaderItem>
    //   ),
    //   cell: (info) => (
    //     <SimpleList.ItemCol width="12.5%">
    //       <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
    //         {info.getValue()?.length}
    //       </SimpleList.ItemText>
    //     </SimpleList.ItemCol>
    //   ),
    // }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <SimpleList.HeaderItem width="30%" align='center'>
          <SimpleList.HeaderItemLabel>Actions</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: ({ row, active }) => (
        <SimpleList.ItemCol width="30%">
          <SimpleList.ItemActions>
            <SimpleList.ItemAction onPress={() => onPublish(row.original)}>
              <SvgTransfer color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
            <SimpleList.ItemAction onPress={() => onUpdate(row.original)}>
              <SvgEdit color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
            <SimpleList.ItemAction onPress={() => onDelete(row.original)}>
              <SvgBin color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
          </SimpleList.ItemActions>
        </SimpleList.ItemCol >
      ),
    }),
  ];

  const { table, setSearch, isFetching } = useTable({
    columns,
    useQuery: ({ pagination, search }) => catalogApi.useReadManyCatalogsQuery({
      pagination,
      filters: {
        name: {
          $contains: search
        }
      },
      sort: ['name:ASC'],
      populate: [
        // 'promotions',
        // 'catalogToProducts',
        // 'catalogToPacks'
      ]
    })
  });

  return <SimpleList
    table={table}
    isFetching={isFetching}
    onSearch={setSearch}
    selectedRowItem={selectedRowItem}
    onRowPress={onRowPress} />;
};

import { extractFormOrApiErrorMessage, isFetchBaseQueryError } from "@app/helpers";
import React, { createContext, memo, useContext } from "react";
import { useFormContext } from "react-hook-form";

const ApiErrorsContext = createContext<{ errorMessages: Record<string, string>, showError?: boolean }>({} as any);

export const useApiErrors = () => {
  return useContext(ApiErrorsContext);
}

export const useError = (name: string) => {
  const { formState } = useFormContext()
  const { errorMessages: apiError, showError } = useApiErrors();

  if (typeof showError === 'boolean' && !showError) {
    return undefined;
  }

  return extractFormOrApiErrorMessage(formState.errors, apiError, name);
}

interface ApiErrorsProviderProps {
  children: React.ReactNode;
  apiErrors?: any; // FetchBaseQueryError | SerializedError
  showError?: boolean;
}

export const ApiErrorsProvider = memo(({ children, apiErrors, showError }: ApiErrorsProviderProps) => {
  let errorMessages = {};

  if (apiErrors && isFetchBaseQueryError(apiErrors)) {
    errorMessages = apiErrors.data as any;
  }

  return (
    <ApiErrorsContext.Provider value={{ errorMessages, showError }}>
      {children}
    </ApiErrorsContext.Provider>
  )
});
import { Transform } from "class-transformer";
import { IsBoolean, IsOptional } from "class-validator";
import { IProductVariation } from "../../interfaces";

export class CatalogProductToProductVariationDTO {
  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  price: number;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  deliveryPrice: number;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  takeAwayPrice: number;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  onSpotPrice: number;

  @IsOptional()
  loyaltyPointOffered: number;

  @IsOptional()
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsOptional()
  productVariation: IProductVariation;
}

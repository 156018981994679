import React, { Fragment, useCallback } from 'react';
import styled from '@2pulse/responsive';
import { Portal } from '@tickeat/ui';
import { Button } from '../form';
import { normalize, withComponents } from '@app/helpers';
import { theme } from '@app/theme';
import SvgInfo from '../icons/SvgInfo';
import SvgCheck from '../icons/SvgCheck';
import { IToast } from '@app/store';
import { useToast } from '@app/hooks';
import { useFocusEffect } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';

const Container = styled.View(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: normalize(89),
  bottom: normalize(34),
  zIndex: 99999,
  justifyContent: 'center',
  alignItems: 'center',
}));

const Content = styled.View<{ error?: boolean }>(({ props, theme }) => ({
  width: '79%',
  maxWidth: 980,
  minWidth: 610,
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: props.error ? theme.colors.crimson : theme.colors.babypowder,
  height: '100%',
  paddingHorizontal: normalize(24),
  borderRadius: normalize(89),
  overflow: 'hidden',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 0.21,
  shadowRadius: 13,
  elevation: 13,
}));

const Icon = styled.View(({ theme }) => ({
  width: 48,
  height: 48,
}));

const Data = styled.View(({ theme }) => ({
  display: 'flex',
  flexShrink: 1,
  height: '100%',
  justifyContent: 'center',
  paddingLeft: normalize(21),
  paddingRight: normalize(34),
}));

const Title = styled.Text<{ error?: boolean }>(({ props, theme }) => ({
  fontFamily: 'Work Sans',
  color: props.error ? theme.colors.babypowder : theme.colors.oxfordblue,
  fontSize: normalize(18),
  lineHeight: normalize(19),
  fontWeight: '700',
  paddingBottom: normalize(5),
  letterSpacing: 0.21,
}));

const Message = styled.Text<{ error?: boolean }>(({ props, theme }) => ({
  fontFamily: 'Work Sans',
  color: props.error ? theme.colors.babypowder : theme.colors.oxfordblue,
  fontSize: normalize(15),
  fontWeight: '600',
  lineHeight: normalize(19),
}));

const Action = styled.View(({ theme }) => ({
  justifyContent: 'flex-end',
  flexGrow: 1,
}));

export interface ToastProps {
  toast: IToast;
}

export const Toast = withComponents(({ toast }: ToastProps) => {
  const { dismiss } = useToast();

  const isError = toast.type === 'error';

  useFocusEffect(
    useCallback(() => {
      if (typeof toast.options?.autoClose !== 'number') {
        return;
      }

      const timeoutId = setTimeout(() => {
        dismiss(toast.id);
      }, toast.options?.autoClose || 3000);

      return () => {
        clearTimeout(timeoutId);
      }
    }, [toast.id, toast.options?.autoClose])
  );

  return (
    <Fragment>
      <Portal.Gate hostName="root">
        <Toast.Container>
          <Toast.Content error={isError}>
            <Toast.Icon>
              {toast.type === 'error' && <SvgInfo color={theme.colors.babypowder} />}
              {toast.type === 'success' && <SvgCheck color={theme.colors.celadonblue} />}
              {toast.type === 'promise' && <ActivityIndicator size='large' color={theme.colors.celadonblue} />}
            </Toast.Icon>
            <Toast.Data>
              <Toast.Title error={isError}>{isError ? 'Une erreur est survenue' : 'Super!'}</Toast.Title>
              <Toast.Message error={isError}>{toast.message}</Toast.Message>
            </Toast.Data>
            {/* <Toast.Action>
              <Button title='Fermer' inverted={isError} onPress={() => dismiss(toast.id)} />
            </Toast.Action> */}
          </Toast.Content>
        </Toast.Container>
      </Portal.Gate>
    </Fragment>
  );
}, {
  Container,
  Content,
  Icon,
  Data,
  Title,
  Message,
  Action,
});

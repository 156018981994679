import React from 'react';
import styled from '@2pulse/responsive';
import { col } from '@app/style';
import { Menu, MenuItem, MenuItemHolder } from './Menu';
import {
  SvgMenuCards,
  SvgMenuMenus,
  SvgMenuProducts,
  SvgMenuSettings,
} from '../icons';
import { normalize } from '@app/helpers';
import SvgSignOut from '../icons/SvgSignOut';
import { useAppDispatch } from '@app/store/hooks';
import { authSlice } from '@app/store';

const SidebarContainer = styled.View(({ theme }) => ({
  zIndex: 999,
  position: 'absolute',
  left: 0,
  top: '2%',
  height: '96%',
  width: theme.sizes.sidebarWidth,
  borderTopRightRadius: 21,
  borderBottomRightRadius: 21,
  backgroundColor: theme.colors.oxfordblue,
  shadowColor: theme.colors.oxfordblue,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.55,
  shadowRadius: 8,
  elevation: 18,
  ...col,
  justifyContent: 'center',
  alignItems: 'center',
}));

const Logo = styled.Image({
  position: 'absolute',
  top: normalize(34),
  width: normalize(55),
  height: normalize(55),
  resizeMode: 'contain',
});

const SignOut = styled.View({
  position: 'absolute',
  bottom: normalize(34),
});

export const Sidebar = () => {
  const dispatch = useAppDispatch();

  return (
    <SidebarContainer>
      <Logo source={require('@app/assets/img/logo--icon.png')} />

      <Menu>
        <MenuItem
          label="Produits"
          Icon={SvgMenuProducts}
          routeName='ProductScreen'
          activeOnRouteNames={['IngredientScreen', 'OptionScreen', 'ProductScreen', 'VariationScreen', 'AccountCodeScreen']} />

        <MenuItem
          label="Menus"
          Icon={SvgMenuMenus}
          routeName='PackScreen'
          activeOnRouteNames={['PackScreen', 'StepScreen']} />

        <MenuItem
          label="Catalogues"
          Icon={SvgMenuCards}
          routeName='CatalogScreen' />
          
        {/* <MenuItem label="Recherche" Icon={SvgMenuSearch} /> */}

        <MenuItem
          label="Réglages"
          routeName='TagScreen'
          Icon={SvgMenuSettings}
          activeOnRouteNames={['TagScreen']} />
      </Menu>

      <SignOut>
        <MenuItemHolder onPress={() => dispatch(authSlice.actions.logout())}>
          <SvgSignOut />
        </MenuItemHolder>
      </SignOut>

    </SidebarContainer>
  );
};

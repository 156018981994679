import { variationApi } from "@app/store";
import { UNITARY_VARIATION_NAME } from "@tickeat/common";


export const useGetUnitaryVariation = () => {
  const { data } = variationApi.useReadOneVariationQuery({
    filters: { name: { $eq: UNITARY_VARIATION_NAME } }
  });

  return data;
}
import React, { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { AdvertisementDTO, IAdvertisement, IShop, } from '@tickeat/common';
import { Button, CardButtonActionMode, Form, Panel } from '@app/components';
import { AdvertisementFormValues, useAdvertisementFormSubmit } from './advertisement-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { AdvertisementForm } from './AdvertisementForm';
import { InputFileRef } from '@app/components/form/InputFile';
import { AdvertisementCard } from './AdvertisementCard';
import { advertisementApi } from '@app/store/services/advertisement';

const transformToUpdatableFormAdvertisement = (advertisement?: Partial<IAdvertisement>): AdvertisementFormValues => {
  return {
    name: advertisement?.name,
    type: advertisement?.type,
    description: advertisement?.description,
    disabled: advertisement?.disabled,
    startDate: advertisement?.startDate,
    endDate: advertisement?.endDate,
    url: advertisement?.url,
    images: advertisement?.images,
    shopIds: advertisement?.shopIds as IShop[]
  }
}

interface AdvertisementUpdateProps {
  advertisement?: Partial<IAdvertisement>;
  isFetching: boolean;
  toggle: (mode: CardButtonActionMode) => void;
  onDelete: (product: Partial<IAdvertisement>) => void;
}

export const AdvertisementUpdate = ({ advertisement, isFetching, toggle, onDelete }: AdvertisementUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych();

  const imageRef = useRef<InputFileRef>(null);

  const onSubmit = useAdvertisementFormSubmit();

  const [update, { isLoading, error: apiErrors }] = advertisementApi.useUpdateOneAdvertisementMutation();

  const formMethods = useForm<AdvertisementFormValues>({
    resolver: classValidatorResolver(AdvertisementDTO),
    defaultValues: transformToUpdatableFormAdvertisement(advertisement)
  });

  const onUpdate = async (formData: FormData) => {
    try {
      const updatedAdvertisement = await update({
        _id: advertisement?._id!,
        formData
      }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedAdvertisement });

      toast.success('Votre publicité a bien été modifiée.');
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre publicité.');
    }
  }

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormAdvertisement(advertisement));
  }, [advertisement, formMethods.reset]);

  return (
    <Fragment>
      <AdvertisementCard
        advertisement={advertisement}
        formMethods={formMethods}
        toggle={toggle}
        onOpenImagePicker={() => imageRef.current?.open()}
      />

      <Panel.Content>

        <Form formMethods={formMethods} apiErrors={apiErrors} isLoading={isFetching}>

          <Panel.FormContainer>

            <Panel.Header>
              <Button
                title="Supprimer"
                disabled={!advertisement}
                bordered
                important
                onPress={() => onDelete(advertisement!)} />
            </Panel.Header>

            <AdvertisementForm ref={imageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              loading={isLoading}
              disabled={isFetching}
              onPress={() => onSubmit(formMethods, onUpdate)}
              title="Valider"
              grow
              large />
          </Panel.Actions>
        </Form>
      </Panel.Content>
    </Fragment>
  );
};

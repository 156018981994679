import { IsNotEmpty, IsOptional, ValidateNested, ValidateIf, isDefined } from 'class-validator';
import { AdvertisementTypes, IImageBySizes } from '../../interfaces';
import { Type } from 'class-transformer';

export class AdvertisementDTO {
  @IsNotEmpty({ message: 'Le type de la publicité est requis' })
  type: AdvertisementTypes;

  @IsNotEmpty({ message: 'Le nom est requis' })
  name: string;

  @IsNotEmpty({ message: "L'url est requis" })
  url: string;

  
  @ValidateIf(o => !isDefined(o.images))
  @IsNotEmpty({ message: "L'image est requise" })
  @Type(() => Object)
  image: any;

  @IsNotEmpty({ message: "La date de début n'est pas correcte" })
  startDate: Date;

  @IsNotEmpty({ message: "La date de fin n'est pas correcte" })
  endDate: Date;

  @IsNotEmpty({ message: 'Le status depLa publicité doit être soit active ou inactive' })
  disabled: boolean;

  @IsOptional()
  description?: string;
}
import { isNumber } from "@app/helpers/kindOf";
import { IBase, IStep, convertToCents } from "@tickeat/common";

export interface StepFormValues extends Partial<IStep> { }

export const transformStepFormValuesToPayload = (values: Omit<Partial<IStep>, keyof IBase>): Partial<IStep> => {
  return {
    ...values,
    stepToProductVariations: values.stepToProductVariations?.map(stepToProductVariation => ({
      ...stepToProductVariation,
    }))
  }
}
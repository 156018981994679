import { ButtonIcon, Card, CardButtonActionMode, HeaderGradient, MiniCard, Row } from '@app/components';
import React, { memo } from 'react';
import { theme } from '@app/theme';
import { useDynamicSizes } from '@app/hooks';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet } from 'react-native';
import { IAdvertisement } from '@tickeat/common';
import { UseFormReturn, useWatch } from 'react-hook-form';
import SvgImage from '@app/components/icons/SvgImage';

type AdvertisementCardProps = {
  advertisement?: Partial<IAdvertisement>;
  formMethods: UseFormReturn<any, any, any>;
  onOpenImagePicker: () => void;
  toggle: (mode: CardButtonActionMode) => void;
};

const formatCardData = (partialAdvertisement: Partial<IAdvertisement>) => {
  return {
    name: partialAdvertisement?.name || 'Nouvelle publicité',
  }
}

export const AdvertisementCard = memo(({ advertisement, toggle, formMethods, onOpenImagePicker }: AdvertisementCardProps) => {
  const { subContainerHeight } = useDynamicSizes();

  const cardData = useWatch({
    control: formMethods.control,
  });

  const {
    name,
  } = formatCardData(cardData);

  const previewImageUrl = (cardData.image instanceof File || cardData.image instanceof Blob)
    ? URL.createObjectURL(cardData.image)
    : typeof cardData.image === 'string'
      ? cardData.image
      : null;

  return (
    <Card>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient}
        />
      </HeaderGradient>

      <Card.TopHeader>
        <Card.TopHeaderLabel>Fiche publicité</Card.TopHeaderLabel>
      </Card.TopHeader>

      <Card.Container height={subContainerHeight}>
        <Card.Background source={require('@app/assets/img/cardbackground.png')} />

        <Card.Content>
          <Card.Header>
            <Row justify="space-between" align="center">
              <Card.HeaderTitles>
                <Card.Title numberOfLines={2}>{name}</Card.Title>
              </Card.HeaderTitles>

              <Card.ButtonAction onPress={toggle} />
            </Row>

          </Card.Header>

          <Card.VisualContainer>

            <Card.Visual source={{ uri: !!previewImageUrl ? previewImageUrl : advertisement?.images?.xxlarge?.webp?.url }} />

            <Card.VisualEdit>
              <ButtonIcon Icon={SvgImage} onPress={onOpenImagePicker} />
            </Card.VisualEdit>
          </Card.VisualContainer>
        </Card.Content>

        <MiniCard>
          <MiniCard.Container />
        </MiniCard>
      </Card.Container>
    </Card>
  );
});

const styles = StyleSheet.create({
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});


import { IShop} from '@tickeat/common';
import { crudEndpointsFactory } from '../crud-endpoints.factory';

const tagType = 'Shops';

export const shopApi = crudEndpointsFactory<IShop>()('shops', 'Shops').injectEndpoints({
  endpoints: (builder) => ({
    connectToHubrise: builder.mutation<{ authorizationUrl: string }, { shopId: string }>({
      query: (body) => ({
        url: 'shops/hubrise',
        method: 'POST',
        body,
      }),
    }),
    disconnectHubrise: builder.mutation<IShop, { shopId: string }>({
      query: (body) => ({
        url: 'shops/hubrise-disconnect',
        method: 'POST',
        body,
      }),
    }),
  }),
});

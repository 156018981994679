import { Button } from "@app/components";
import { Modal } from "@app/components/modals";
import { modalsSelectors } from "@app/store";
import { useAppSelector } from "@app/store/hooks";

interface CatalogHubrisePublishModalProps {
  id: string;
  close: () => void;
  onPublish: (data: any) => void;
  isLoading?: boolean;
}

export const CatalogHubrisePublishModalId = 'CatalogHubrisePublishModal';

export const CatalogHubrisePublishModal = ({ id, onPublish, close, isLoading }: CatalogHubrisePublishModalProps) => {
  const modal = useAppSelector(state => modalsSelectors.selectById(state, id));

  return (
    <Modal isOpen={!!modal?.isOpen}>
      <Modal.Title>Informations</Modal.Title>

      <Modal.Subtitle>Vous allez publier ce catalogue sur les plateformes de livraisons.</Modal.Subtitle>

      <Modal.Message>
      </Modal.Message>

      <Modal.Action>
        <Button title='Annuler' onPress={close} large />
        <Button title='Publier' onPress={() => onPublish(modal?.data)} large bordered loading={isLoading} />
      </Modal.Action>
    </Modal>
  );
}
import React, { useState } from 'react';
import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { col, row, worksansoxford } from '@app/style';
import SvgArrowDown from '../icons/SvgArrowDown';
import LinearGradient from 'react-native-linear-gradient';
import { SvgBin } from '../icons/SvgBin';
import { Row } from '../layout';

const DropdownContainer = styled.View(({ theme }) => ({
  ...col,
}));

const DropdownTrigger = styled.TouchableOpacity<{ isOpen?: boolean, deletable?: boolean }>(({ theme, props }) => ({
  ...row,
  height: normalize(76),
  flexGrow: 1,
  borderTopLeftRadius: 5,
  borderTopRightRadius: props.deletable ? 0 : 5,
  borderBottomLeftRadius: props.isOpen ? 0 : 5,
  borderBottomRightRadius: props.isOpen ? 0 : 5,
  backgroundColor: props.isOpen ? theme.colors.celadonblue : 'rgba(240, 249 , 252, .04)',
  paddingLeft: normalize(34),
  alignItems: 'center',
  justifyContent: 'space-between',
  overflow: 'hidden',
}));

const DropdownLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  fontSize: normalize(21),
  fontWeight: '500',
  color: theme.colors.babypowder,
  paddingRight: normalize(21),
}));

const DropdownToggle = styled.View<{ deletable?: boolean }>(({ theme, props }) => ({
  width: normalize(21 + 34 + 21),
  paddingHorizontal: normalize(21),
  height: "100%",
  borderLeftWidth: props.deletable ? 0 : normalize(1),
  borderLeftColor: theme.colors.oxfordblue,
}));

const DropdownContent = styled.View(({ theme }) => ({
  width: '100%',
  rowGap: normalize(2),
  minHeight: 76,
  borderWidth: normalize(2),
  borderTopWidth: 0,
  borderColor: theme.colors.celadonblue,
}));

export const DropdownDelete = styled.TouchableOpacity<{ bordered?: boolean }>(({ theme, props }) => ({
  width: normalize(21 + 34 + 21),
  paddingHorizontal: normalize(21),
  height: normalize(76),
  borderTopRightRadius: 5,
  backgroundColor: theme.colors.celadonblue,
  borderWidth: props.bordered ? normalize(2) : 0,
  borderColor: "#00BEE6",
  borderLeftWidth: normalize(1),
  borderLeftColor: theme.colors.oxfordblue,
}));

interface DropdownProps {
  label: string;
  children: React.ReactNode | React.ReactNode[];
  onDelete?: () => void;
}

export const Dropdown = ({ children, onDelete, label }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  }

  const isDeletable = typeof onDelete === 'function';

  return (
    <DropdownContainer>
      <Row>
        <DropdownTrigger onPress={toggle} isOpen={isOpen} deletable={isDeletable}>
          <DropdownLabel>{label}</DropdownLabel>
          <DropdownToggle deletable={isDeletable}>
            <SvgArrowDown color="#fff" />
          </DropdownToggle>
        </DropdownTrigger>

        {isDeletable && (
          <DropdownDelete onPress={() => onDelete()}>
            <SvgBin color="#fff" />
            <LinearGradient
              style={{ flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}
              start={{ x: 0, y: 0.75 }}
              end={{ x: 1, y: 0.25 }}
              locations={[0, .7]}
              colors={['rgba(2,24,43,0.13)', 'rgba(2,24,43,0)']} >
            </LinearGradient>
          </DropdownDelete>
        )}
      </Row>

      {isOpen &&
        <DropdownContent>
          {children}
        </DropdownContent>
      }

    </DropdownContainer>
  );
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { SvgAppbackground, MainContainer, Container, Header, Tab, Button } from '@app/components';
import { useNavigation, useNavigationState } from '@react-navigation/native';

export interface MainLayoutHeaderProps {
  onPress: () => void;
  buttonTitle: string;
  tabs: {
    label: string;
    screenName: keyof ReactNavigation.RootParamList;
  }[];
}

export const MainLayoutHeader = ({ onPress, tabs, buttonTitle }: MainLayoutHeaderProps) => {
  const navigation = useNavigation();
  const screenName = useNavigationState((state) => state?.routes[state.index]?.name)

  const goTo = (screenName: keyof ReactNavigation.RootParamList) => {
    navigation.navigate(screenName);
  }

  return (
    <Header>
      <Tab.Tabs>
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            isActive={tab.screenName === screenName}
            onPress={() => goTo(tab.screenName)}>
            <Tab.Label isActive={tab.screenName === screenName}>{tab.label}</Tab.Label>
          </Tab>
        ))}

      </Tab.Tabs>

      <Button onPress={onPress} title={buttonTitle} />
    </Header>
  );
};

interface MainLayoutProps {
  header: React.ReactNode;
  children: React.ReactNode;
}

export const MainLayout = ({ header, children }: MainLayoutProps) => {
  return (
    <>
      <SvgAppbackground style={styles.background} />

      <MainContainer>

        {header}

        <Container>

          {children}

        </Container>

      </MainContainer>
    </>
  );
};

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    opacity: 1,
    position: 'absolute',
    top: 0,
    left: 0
  },
});
import { ArrayMinSize, IsArray, IsBoolean, IsInt, IsNotEmpty, Min } from "class-validator";
import { IBase, IStep, IStepToProductVariation } from "../../interfaces";

export class StepDTO implements Omit<IStep, keyof IBase>  {
  @IsNotEmpty({ message: 'Le nom de la rubrique menu est requis' })
  name: string;

  @IsNotEmpty({ message: 'La rubrique menu est-elle active ?' })
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsNotEmpty({ message: 'La rubrique menu est obligatoire ?' })
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  mandatory: boolean;

  @IsNotEmpty({ message: 'Le nombre maximum de produits sélectionnable est requis' })
  @IsInt({ message: 'Le nombre maximum de produits sélectionnable doit être une valeur numérique' })
  @Min(1, { message: 'Le nombre maximum de produits sélectionnable doit être au minimum de 1' })
  maxChoiceCount: number;

  @IsArray({ message: 'Au moins une déclinaison de produit est requise' })
  @ArrayMinSize(1, { message: 'Au moins une déclinaison de produit est requise' })
  stepToProductVariations: IStepToProductVariation[];
}

import { rootApi } from "../api";

export const permissionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    readAllPermissions: builder.query<any, any>({
      query: () => ({
        url: 'permissions',
        method: 'GET',
      }),
    }),
  }),
})

import React, { useState } from 'react';
import { IBase } from '@tickeat/common';
import { BaseSelect, BaseSelectProps } from './BaseSelect';
import { useInput } from '@app/hooks/useInput';
import { isDefined } from '@app/helpers/kindOf';
import { TextInput } from 'react-native';

export interface SimpleSelectProps<T> extends Omit<BaseSelectProps<T>, 'isOpen' | 'setIsOpen' | 'formattedLabel' | 'onSelect' | 'onRemove' | 'isActive' | 'control' | 'render' | 'values'> { }

const baseTransform = {
  value: (item: any) => item,
  accessor: (item: any) => item?.['_id'],
  display: (item: any) => item?.['name'],
}

export const SimpleSelect = <T extends IBase,>({
  label,
  name,
  table,
  ...props
}: SimpleSelectProps<T>) => {
  const { field } = useInput(name);

  const transform = {
    ...baseTransform,
    ...props?.transform ?? {},
  }

  const [isOpen, setIsOpen] = useState(false);

  const values = isDefined(field.value) ? [field.value] : [];

  const onSelect = (item: T) => {
    field.onChange(item);

    setIsOpen(false);
  }

  const isActive = (item: T) => {
    return transform.accessor(field.value) === transform.accessor(item);
  }

  return (
    <BaseSelect
      name={name}
      label={label}
      table={table}
      {...props}
      values={values}
      render={(item, index) => (
        <BaseSelect.ListItem key={index} compact isActive onPress={() => field.onChange(null)}>
          <BaseSelect.ListItemCol width='100%'>
            <BaseSelect.ListItemText numberOfLines={1} bold>
              {transform.display!(item)}
            </BaseSelect.ListItemText>
          </BaseSelect.ListItemCol>
        </BaseSelect.ListItem>
      )}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      formattedLabel={transform.display!(field.value) || label!}
      onSelect={onSelect}
      isActive={isActive}
    />
  );
};

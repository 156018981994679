import { TextInput } from '@app/components';
import { DateTimePicker } from '@app/components/form/DateTimePicker';
import { NumberSelector } from '@app/components/form/NumberSelector';
import { Toggle } from '@app/components/form/Toggle';
import { OrderModeSelect } from '@app/components/form/selects/features/OrderModeSelect';
import { ProductVariationSelect } from '@app/components/form/selects/features/ProductVariationSelect';
import { ShopSelect } from '@app/components/form/selects/features/ShopSelect';
import { StyledForms } from '@app/components/styled/form/Form';
import { PromotionDiscountModes, PromotionTypes, isPromotionCode } from '@tickeat/common';
import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

export const PromotionForm = () => {
  const { watch } = useFormContext();

  const [type, code]: [PromotionTypes, string] = watch(['type', 'code']);

  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <TextInput
        name="description"
        label="Description"
        autoCapitalize="none" />

      <Toggle
        name="disabled"
        options={[
          { title: "Active", value: false },
          { title: "Inactive", value: true },
        ]} />

      <DateTimePicker name="startDate" label="Date de début" />

      <DateTimePicker name="endDate" label="Date de fin" />

      <Toggle
        name="type"
        options={[
          { title: "Classique", value: PromotionTypes.LEAST_EXPENSIVE },
          { title: "Offrir", value: PromotionTypes.GIVEAWAY },
          { title: "Réduction", value: PromotionTypes.DISCOUNT },
        ]} />

      {type !== PromotionTypes.LEAST_EXPENSIVE && (
        <TextInput
          name="code"
          label="Code"
          autoCapitalize="none" />
      )}

      {isPromotionCode({ code }) && (
        <Fragment>
          <Toggle
            name="discountMode"
            options={[
              { title: "Remise fixe", value: PromotionDiscountModes.FIXED },
              { title: "Remise en pourcentage", value: PromotionDiscountModes.PERCENTAGE },
            ]} />

          <TextInput
            name="discountValue"
            label="Montant"
            autoCapitalize="none" />
        </Fragment>
      )}

      <NumberSelector name='neededCount' label="Nombre de produits requis" />

      <NumberSelector name='offeredCount' label="Nombre de produits remisés" />

      <Toggle
        name="cumulative"
        options={[
          { title: "Cumulable", value: true },
          { title: "Non cumulable", value: false },
        ]} />

      <OrderModeSelect
        name="orderModes"
        isMultiple />

      <ShopSelect
        label="Restaurants éligibles"
        name="shopIds"
        isMultiple />

      <ProductVariationSelect
        label="Produits requis"
        name='requiredProductVariations'
        isMultiple />

      <ProductVariationSelect
        label="Produits"
        name='discountableProductVariations'
        isMultiple />

      <TextInput
        name="minPrice"
        label="Montant minimum"
        autoCapitalize="none" />

      <Toggle
        name="uniqueByCustomer"
        options={[
          { title: "Unique par client", value: true },
          { title: "Non unique par client", value: false },
        ]} />

      <NumberSelector name='maxUses' label="Nombre maximal d'utilisation" />

    </StyledForms.Form>
  );
};

import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { row } from '@app/style';

export const Header = styled.View(({ theme }) => ({
  ...row,
  height: theme.sizes.headerHeight,
  paddingLeft: normalize(34) + theme.sizes.sidebarWidth,
  paddingBottom: normalize(8),
  paddingRight: normalize(34),
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  // backgroundColor: '#F8FCFD',
}));

import { Button } from "@app/components";
import { Modal } from "@app/components/modals";
import { modalsSelectors } from "@app/store";
import { useAppSelector } from "@app/store/hooks";

interface ShopHubriseConnectModalProps {
  id: string;
  close: () => void;
  onDisconnect: (data: any) => void;
  isLoading?: boolean;
}

export const ShopHubriseDisconnectModalId = 'ShopHubriseDisconnectModalId';

export const ShopHubriseDisconnectModal = ({ id, onDisconnect, close, isLoading }: ShopHubriseConnectModalProps) => {
  const modal = useAppSelector(state => modalsSelectors.selectById(state, id));

  return (
    <Modal isOpen={!!modal?.isOpen}>
      <Modal.Title>Informations</Modal.Title>

      <Modal.Subtitle>Vous allez déconnecter ce restaurant de Hubrise.</Modal.Subtitle>

      <Modal.Message>
      </Modal.Message>

      <Modal.Action>
        <Button title='Annuler' onPress={close} large />
        <Button title='Déconnecter' onPress={() => onDisconnect(modal?.data)} bordered large loading={isLoading} />
      </Modal.Action>
    </Modal>
  );
}
import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IAccountCode } from "@tickeat/common";

interface AccountCodeDeleteModalProps extends ModalInstance<IAccountCode>, Omit<DeleteModalProps<IAccountCode>, 'children'> {
  onDelete: (product: Partial<IAccountCode>) => void;
}

export const AccountCodeDeleteModal = (props: AccountCodeDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer un code comptable.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible.
      </Modal.Message>
    </DeleteModal>
  )
}
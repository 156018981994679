import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { promotionApi } from '@app/store';
import { IPromotion, PromotionDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { PromotionForm } from './PromotionForm';
import { PromotionFormValues, transformStepFormValuesToPayload } from './promotion-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormPromotion = (promotion?: Partial<IPromotion>): PromotionFormValues => {
  return {
    name: promotion?.name,
    type: promotion?.type,
    description: promotion?.description,
    code: promotion?.code,
    minPrice: promotion?.minPrice,
    neededCount: promotion?.neededCount,
    offeredCount: promotion?.offeredCount,
    uniqueByCustomer: promotion?.uniqueByCustomer,
    requiredProductVariations: promotion?.requiredProductVariations,
    discountableProductVariations: promotion?.discountableProductVariations,
    maxUses: promotion?.maxUses,
    shopsIds: promotion?.shopsIds,
    discountMode: promotion?.discountMode,
    discountValue: promotion?.discountValue,
    orderModes: promotion?.orderModes,
    disabled: promotion?.disabled,
    cumulative: promotion?.cumulative,
    startDate: promotion?.startDate,
    endDate: promotion?.endDate,
  }
}

interface PromotionUpdateProps {
  promotion?: IPromotion;
  isFetching?: boolean;
  onDelete: (product: Partial<IPromotion>) => void;
}

export const PromotionUpdate = ({ promotion, isFetching, onDelete }: PromotionUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = promotionApi.useUpdateOnePromotionsMutation();

  const formMethods = useForm<PromotionFormValues>({
    resolver: classValidatorResolver(PromotionDTO),
    defaultValues: transformToUpdatableFormPromotion(promotion),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormPromotion(promotion));
  }, [promotion, formMethods.reset]);

  const onUpdate = async (formValues: PromotionFormValues) => {
    try {
      const updatedPromotion = await update(transformStepFormValuesToPayload({ _id: promotion?._id, ...formValues })).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedPromotion });

      toast.success('Modification effectuée avec succès.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(promotion!)}
              bordered
              important />
          </Panel.Header>

          <PromotionForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

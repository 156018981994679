import React from 'react';
import { SimpleList } from '@app/components';
import { theme } from '@app/theme';
import { CatalogCalendarHeaderItem } from '@app/screens/CatalogScreen';

interface CatalogCalendarHeaderProps {
  items: CatalogCalendarHeaderItem[];
  headerItemWidth: number;
}

export const CatalogCalendarHeader = ({ items, headerItemWidth }: CatalogCalendarHeaderProps) => {
  return (
    <SimpleList.Header extraStyle={{ paddingLeft: theme.sizes.sidebarWidth }}>
      {items.map(({ month, year }) => (
        <SimpleList.HeaderItem key={`${month}_${year}`} width={headerItemWidth} align="center">
          <SimpleList.HeaderItemLabel>
            {month} {year}
          </SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ))}
    </SimpleList.Header>
  );
};

import { Primitive } from "@tickeat/common";

export const isDefined = (value: unknown): value is Omit<Primitive, 'null' | 'undefined'> => {
  return typeof value !== 'undefined' && value !== null && (typeof value !== 'number' ? !!value : true);
}

export const isFunction = (value: unknown): value is Function => {
  return typeof value === 'function';
}

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
}

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
}

export const isEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
}
import { useCallback, useState } from 'react';

type UsePagininationProps = {
  take: number;
  skip?: number;
};

export const usePagination = ({ take, skip = 0 }: UsePagininationProps) => {
  const [pagination, setPagination] = useState({ take, skip });

  const nextPage = useCallback(() => {
    setPagination((prev) => ({
      take: prev.take,
      skip: prev.skip + prev.take,
    }));
  }, [take, skip]);

  return {
    pagination,
    nextPage,
  };
};

import { IBase } from "../base.interface";
import { ICatalogToTag } from "./catalog-to-tag.interface";
import { IProduct } from "./product.interface";

export interface ITag extends IBase {
  name: string;

  parent: ITag;

  color: string;

  parentTagId: string;

  products: IProduct[];

  // catalogToTags: ICatalogToTag[];

  childrens: ITag[];

  description: string;

  imageUrl: string;

  slug: string;

  position: number;
}
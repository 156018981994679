import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { col } from '@app/style';
import { withComponents } from '@app/helpers';
import { theme } from '@app/theme';
import { Button } from '../form/buttons';
import { triptychSelectors } from '@app/store';
import { useAppSelector } from '@app/store/hooks';

const TopHeader = styled.View({
  marginTop: -theme.sizes.listHeaderHeight,
  height: theme.sizes.listHeaderHeight,
  justifyContent: 'center',
});

const TopHeaderLabel = styled.Text({
  fontFamily: 'Work Sans',
  width: '100%',
  textAlign: 'center',
  flexShrink: 0,
  flexGrow: 0,
  fontSize: normalize(18),
  color: '#97999B',
});

const Background = styled.Image({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '100%',
  height: '100%',
  resizeMode: 'stretch',
});

const Content = styled.View({
  paddingHorizontal: normalize(34),
  paddingTop: normalize(55),
  paddingBottom: normalize(34),
});

const Header = styled.View({
  width: '100%',
});

const HeaderTitles = styled.View({
  ...col,
  maxWidth: '65%',
});

const Title = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  letterSpacing: -0.1,
  fontWeight: '700',
  fontSize: normalize(30),
  color: theme.colors.babypowder,
}));

const Subtitle = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  fontSize: normalize(14),
  color: theme.colors.babypowder,
  opacity: 0.5
  // textTransform: 'uppercase',
}));

const HeaderValues = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-around',
});

const HeaderValuesItem = styled.View({
  justifyContent: 'flex-start',
  width: '33.33%',
});

const Key = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  fontSize: normalize(19),
  color: theme.colors.celadonblue,
  fontWeight: '700',
  textAlign: 'center',
  marginTop: 34,
}));

const Value = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  fontSize: normalize(18),
  color: theme.colors.babypowder,
  textAlign: 'center',
  paddingTop: 3,
  fontWeight: '500',
}));

const VisualContainer = styled.View({
  marginTop: normalize(55),
  height: normalize(180),
});

const Visual = styled.Image({
  width: '100%',
  height: '100%',
  resizeMode: 'contain',
});

const VisualEdit = styled.View({
  position: 'absolute',
  width: 48,
  height: 48,
  bottom: 0,
  right: 0,
});

const Container = styled.View<CardProps>(({ props }) => ({
  height: props.height,
  shadowColor: '#000',
  shadowOffset: {
    width: 8,
    height: 0,
  },
  shadowOpacity: 0.21,
  shadowRadius: 8,
  elevation: 13,
}));

export type CardButtonActionMode = "CREATE" | "UPDATE" | "CLOSE";

interface ButtonActionProps {
  onPress: (mode: CardButtonActionMode) => void;
}

const ButtonAction = ({ onPress }: ButtonActionProps) => {
  const mode = useAppSelector(triptychSelectors.selectMode);
  const isOpen = useAppSelector(triptychSelectors.selectIsOpen);

  const title = isOpen
    ? "Fermer"
    : mode === 'CREATE'
      ? "Ajouter"
      : "Editer"

  return (
    <Button
      title={title}
      bordered={isOpen}
      onPress={() => onPress(isOpen ? "CLOSE" : mode)} />
  )
}

type CardProps = {
  height: number;
};

export const Card = withComponents(
  styled.View(({ theme }) => ({
    width: theme.sizes.layoutCard,
    flexShrink: 0,
    flexGrow: 0,
    zIndex: 99,
  })),
  {
    ButtonAction,
    TopHeader,
    TopHeaderLabel,
    Container,
    Background,
    Content,
    Header,
    Title,
    HeaderTitles,
    Subtitle,
    HeaderValues,
    HeaderValuesItem,
    Key,
    Value,
    VisualContainer,
    Visual,
    VisualEdit,
  },
);

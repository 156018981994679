import { normalize } from "@app/helpers";
import styled from '@2pulse/responsive';
import { theme } from "@app/theme";

const BaseButton = styled.TouchableOpacity<{ square?: boolean, grow?: boolean, inverted?: boolean, large?: boolean, bordered?: boolean, important?: boolean }>(({ props }) => ({
  paddingHorizontal: normalize(34),
  display: 'flex',
  flexGrow: props.grow ? 1 : 0,
  overflow: 'hidden',
  height: props.large ? 67 : 48,
  borderRadius: props.square ? 5 : 100,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.inverted ? '#FFF' : props.bordered ? 'transparent' : '#0089C8',
  borderWidth: props.bordered ? normalize(2) : 0,
  borderColor: props.important ? '#D7263D' : '#00BEE6',
}));

const Label = styled.Text<{ large?: boolean, inverted?: boolean, smallText?: boolean, important?: boolean }>(({ props }) => ({
  fontWeight: '600',
  fontFamily: 'Work Sans',
  textAlign: 'center',
  fontSize: props.smallText ? normalize(21) : props.large ? normalize(25) : normalize(21),
  color: props.inverted ? '#D7263D' : props.important ? '#D7263D' : '#FFF',
}));

const BaseButtonDouble = styled.View<{ grow?: boolean, large?: boolean, bordered?: boolean }>(({ props }) => ({
  borderRadius: 100,
  flexDirection: 'row',
  flexGrow: props.grow ? 1 : 0,
  overflow: 'hidden',
  height: props.large ? 67 : 48,
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: props.bordered ? 'transparent' : '#0089C8',
  borderWidth: props.bordered ? normalize(3) : 0,
  borderColor: '#00BEE6',
}));

const ButtonDoubleLabelFrame = styled.TouchableOpacity<{ large?: boolean }>(({ props }) => ({
  flexGrow: 1,
  paddingHorizontal: props.large ? normalize(55) : normalize(34),
}));

const ButtonDoubleLabel = styled.Text<{ large?: boolean }>(({ props }) => ({
  fontWeight: '600',
  fontFamily: 'Work Sans',
  textAlign: 'center',
  fontSize: props.large ? normalize(25) : normalize(21),
  color: '#FFF',
}));

const ButtonDoubleIconFrame = styled.TouchableOpacity<{ large?: boolean }>(({ props }) => ({
  height: '100%',
  width: props.large ? 80 : 55,
  borderLeftColor: '#01799F',
  borderLeftWidth: 2,
  paddingVertical: props.large ? 13 : 8,
  paddingLeft: props.large ? 13 : 8,
  paddingRight: props.large ? 17 : 12,
}));


const Container = styled.TouchableOpacity<{ inactive?: boolean, active?: boolean, small?: boolean }>(({ theme, props }) => ({
  width: props.small ? 34 : 120,
  display: 'flex',
  height: 34,
  borderRadius: 100,
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 2,
  backgroundColor: props.active ? theme.colors.celadonblue : props.small ? theme.colors.babypowder : theme.colors.celadonblue,
  borderColor: !props.small ? theme.colors.celadonblue : props.inactive ? '#CCC' : theme.colors.celadonblue,
  opacity: props.inactive ? 0.34 : 1,
}));

const NewLabel = styled.Text<{ small?: boolean }>(({ theme, props }) => ({
  color: props.small ? theme.colors.celadonblue : theme.colors.babypowder,
  fontWeight: '600',
  lineHeight: 1,
}));

export const StyledButtons = {
  BaseButton,
  Label,
  Container,
  NewLabel,
  BaseButtonDouble,
  ButtonDoubleLabelFrame,
  ButtonDoubleLabel,
  ButtonDoubleIconFrame
}

import { BaseSelect } from '@app/components';
import { productVariationApi } from '@app/store';
import { ColumnDef, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IProductVariation } from '@tickeat/common';
import React from 'react';
import { ResourceSelect, ResourceSelectParentProps } from '../ResourceSelect';
import { PriceInput } from '../../PriceInput';

const columnHelper = createColumnHelper<IProductVariation>();

export const ProductVariationSelect = ({ label = 'Produits', ...props }: ResourceSelectParentProps<IProductVariation>) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
  ];

  const selectedColumns = [
    columnHelper.accessor('name', {
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Nom
        </BaseSelect.ListHeaderItem >
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
    columnHelper.display({
      id: 'price',
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Prix
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => {
        return <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            <PriceInput name={`stepToProductVariations.${info.row.index}.price`} />
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      },
    }),
  ];

  return (
    <ResourceSelect
      label={label}
      columns={columns}
      useQuery={productVariationApi.useReadManyProductVariationsQuery}
      {...props} />
  );
};

import { IBase } from "../base.interface";
import { IProduct } from "./product.interface";

export interface IOption extends IBase {
  name: string;

  disabled: boolean;

  mandatory: boolean;

  freeQuantity: number;

  maxChoiceCount: number;

  products: IProduct[];

  position?: number;
}

import { StyledButtons } from '@app/components/styled/form/Buttons';
import { TouchableOpacityProps } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

type ButtonProps = {
  title: string;
  grow?: boolean;
  large?: boolean;
  bordered?: boolean,
  Icon: (...args: any[]) => JSX.Element;
} & TouchableOpacityProps;

export const ButtonDouble = ({ Icon, title, large, grow, bordered, ...props }: ButtonProps) => {
  return (
    <StyledButtons.BaseButtonDouble grow={grow} large={large} bordered={bordered} {...props}>
      {!bordered &&
        <LinearGradient
          style={{ flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}
          start={{ x: .5, y: 0 }}
          end={{ x: 0.5, y: 1.0 }}
          locations={[0, .7]}
          colors={['#008FCB', '#00B4E0']} >
        </LinearGradient>
      }
      <StyledButtons.ButtonDoubleLabelFrame>
        <StyledButtons.ButtonDoubleLabel large={large}>
          {title}
        </StyledButtons.ButtonDoubleLabel>
      </StyledButtons.ButtonDoubleLabelFrame>
      <StyledButtons.ButtonDoubleIconFrame large={large}>
        <Icon strokePath='#FFF' />
        <LinearGradient
          style={{ flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}
          start={{ x: 0, y: 0.75 }}
          end={{ x: 1, y: 0.25 }}
          locations={[0, .7]}
          colors={['rgba(2,24,43,0.13)', 'rgba(2,24,43,0)']} >
        </LinearGradient>
      </StyledButtons.ButtonDoubleIconFrame>
    </StyledButtons.BaseButtonDouble>
  )
}
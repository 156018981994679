import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { theme } from '@app/theme';
import SvgSlant from '../decor/SvgSlant';

export const SlantTitleHolder = styled.View({
  display: 'flex',
  alignItems: 'center',
});

export const SlantTitleLabel = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  fontSize: normalize(30),
  fontWeight: '900',
  color: theme.colors.babypowder,
  textAlign: 'center',
}));

type SlantTitleProps = { color?: string; label: string };

export const SlantTitle = ({ color, label }: SlantTitleProps) => {

  const width = label.length * normalize(20);

  const slantStyle = { fill: color ? color : theme.colors.babypowder, width: width };

  return (
    <SlantTitleHolder>
      <SlantTitleLabel>{label}</SlantTitleLabel>
      <SvgSlant {...slantStyle} />
    </SlantTitleHolder>
  );
};
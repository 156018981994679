import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ingredientApi } from '@app/store';
import { IIngredient, IngredientDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { IngredientForm } from './IngredientForm';
import { IngredientFormValues } from './ingredient-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormIngredient = (ingredient?: Partial<IIngredient>): IngredientFormValues => {
  return {
    name: ingredient?.name || '',
    unit: ingredient?.unit || '',
    bundle: ingredient?.bundle || '',
    supplier: ingredient?.supplier,
    price: ingredient?.price!,
  }
}

interface IngredientUpdateProps {
  isFetching?: boolean;
  ingredient?: IIngredient;
  onDelete: (product: Partial<IIngredient>) => void;
}

export const IngredientUpdate = ({ ingredient, isFetching, onDelete }: IngredientUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = ingredientApi.useUpdateOneIngredientMutation();

  const formMethods = useForm<IngredientFormValues>({
    resolver: classValidatorResolver(IngredientDTO),
    defaultValues: transformToUpdatableFormIngredient(ingredient),
  });

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormIngredient(ingredient));
  }, [ingredient, formMethods.reset]);

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onUpdate = async (formValues: IngredientFormValues) => {
    try {
      const updatedIngredient = await update({ _id: ingredient?._id!, ...formValues }).unwrap();

      triptych.close({ data: updatedIngredient });

      toast.success('Votre ingrédient a bien été modifié.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre ingrédient.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors} skeletonInputTotal={1}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              bordered
              important
              onPress={() => onDelete(ingredient!)} />
          </Panel.Header>

          <IngredientForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { tagApi } from '@app/store';
import { ITag, TagDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { TagForm } from './TagForm';
import { TagFormValues, transformStepFormValuesToPayload } from './tag-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormTag = (tag?: Partial<ITag>): TagFormValues => {
  return {
    name: tag?.name,
    description: tag?.description,
    position: tag?.position,
    parent: tag?.parent,
    color: tag?.color,
    products: tag?.products
  }
}

interface TagUpdateProps {
  tag?: ITag;
  isFetching?: boolean;
  onDelete: (product: Partial<ITag>) => void;
}

export const TagUpdate = ({ tag, isFetching, onDelete }: TagUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = tagApi.useUpdateOneTagMutation();

  const formMethods = useForm<TagFormValues>({
    resolver: classValidatorResolver(TagDTO),
    defaultValues: transformToUpdatableFormTag(tag),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormTag(tag));
  }, [tag, formMethods.reset]);

  const onUpdate = async (formValues: TagFormValues) => {
    try {
      const updatedTag = await update(transformStepFormValuesToPayload({ _id: tag?._id, ...formValues })).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedTag });

      toast.success('Modification effectuée avec succès.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(tag!)}
              bordered
              important />
          </Panel.Header>

          <TagForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

import React, { Fragment, useEffect } from 'react';
import { Modal as RNModal } from 'react-native';
import styled from '@2pulse/responsive';
import { normalize, withComponents } from '@app/helpers';
import { registerModal, unregisterModal } from '@app/store';
import { useAppDispatch } from '@app/store/hooks';

const Background = styled.View({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 1000,
  paddingVertical: '8%',
  backgroundColor: 'rgba(2,24,43,0.98)',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(66),
  fontWeight: '900',
  paddingBottom: normalize(5),
  maxWidth: 610,
  textAlign: 'center',
}));

const Subtitle = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(34),
  fontWeight: '300',
  maxWidth: 610,
  textAlign: 'center',
}));

const Message = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(21),
  fontWeight: '500',
  paddingVertical: normalize(34),
  lineHeight: normalize(32),
  maxWidth: 610,
  textAlign: 'center',
}));

const Action = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 55,
  marginTop: normalize(8),
});


export interface ModalProps {
  id?: string;
  isOpen: boolean;
  children: React.ReactNode;
}

export const Modal = withComponents(({ id, isOpen, children }: ModalProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(registerModal({ id }));

      return () => {
        dispatch(unregisterModal(id));
      }
    }
  }, [id]);

  return (
    <RNModal visible={isOpen}>
      <Modal.Background>
        {children}
      </Modal.Background>
    </RNModal>
  );
}, {
  Background,
  Title,
  Subtitle,
  Message,
  Action,
});

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { shopApi } from '@app/store';
import { IShop, ShopDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { ShopForm } from './ShopForm';
import { ShopFormValues, transformShopFormValuesToPayload } from './shop-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormShop = (shop?: Partial<IShop>): ShopFormValues => {
  return {
    publicName: shop?.publicName,
    internalName: shop?.internalName,
    description: shop?.description,
    address: shop?.address,
    siret: shop?.siret,
    phone: shop?.phone,
    email: shop?.email,
    disabled: shop?.disabled,
    location: shop?.location,
    isDeliveryOpen: !!shop?.isDeliveryOpen,
    isTakeawayOpen: !!shop?.isTakeawayOpen,
    socialMediaLinks: shop?.socialMediaLinks,
  }
}

interface ShopUpdateProps {
  shop?: IShop;
  isFetching?: boolean;
  onDelete: (product: Partial<IShop>) => void;
}

export const ShopUpdate = ({ shop, isFetching, onDelete }: ShopUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = shopApi.useUpdateOneShopsMutation();

  const formMethods = useForm<ShopFormValues>({
    resolver: classValidatorResolver(ShopDTO),
    defaultValues: transformToUpdatableFormShop(shop),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['publicName']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormShop(shop));
  }, [shop, formMethods.reset]);

  const onUpdate = async (formValues: ShopFormValues) => {
    try {
      const updatedShop = await update(transformShopFormValuesToPayload({ _id: shop?._id, ...formValues })).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedShop });

      toast.success('Modification effectuée avec succès.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(shop!)}
              bordered
              important />
          </Panel.Header>

          <ShopForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

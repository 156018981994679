import React from 'react';
import { SubContainer } from '@app/components';
import { useDynamicSizes } from '@app/hooks';
import { Panel } from './Panel';
import { useAppSelector } from '@app/store/hooks';
import { triptychSelectors } from '@app/store';
import './triptych.css';

interface TriptychProps {
  list: React.ReactNode;
  card?: React.ReactNode;
  form: React.ReactNode;
}

export const Triptych = ({ list, card, form }: TriptychProps) => {
  const { containerHeight } = useDynamicSizes();

  const isOpen = useAppSelector(triptychSelectors.selectIsOpen);

  return (
    <div className={`animatedSubcontainer ${isOpen ? 'open' : 'close'}`}>
      <SubContainer>

        {list}
        
        <Panel>
          <Panel.Container height={containerHeight}>

            {card}

            {form}

          </Panel.Container>
        </Panel>

      </SubContainer>
    </div>
  );
};

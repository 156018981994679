import { TextInput } from '@app/components';
import { NumberSelector } from '@app/components/form/NumberSelector';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

export const VariationForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <NumberSelector name='position' label='Position' />

    </StyledForms.Form>
  );
};

import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ingredientApi } from '@app/store';
import { IngredientDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { IngredientForm } from './IngredientForm';
import { IngredientFormValues } from './ingredient-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const IngredientCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = ingredientApi.useCreateOneIngredientMutation();

  const formMethods = useForm<IngredientFormValues>({
    resolver: classValidatorResolver(IngredientDTO),
    defaultValues: {
      name: '',
      unit: 'N/C',
      bundle: 'N/C',
      price: 0,
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: IngredientFormValues) => {
    try {
      const ingredient = await create(formValues).unwrap();

      triptych.close({ mode: 'UPDATE', data: ingredient });

      toast.success('Votre ingrédient a bien été ajouté.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre ingrédient.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>
          <IngredientForm />
        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

import { theme } from '@app/theme';
import { useWindowDimensions } from 'react-native';

export const useDynamicSizes = () => {
  const { width, height } = useWindowDimensions();

  const fullScreen = width;
  const movingWindow = (64 * width) / 100 - theme.sizes.sidebarWidth;
  const subContainerHeight = height - theme.sizes.headerHeight - theme.sizes.listHeaderHeight;
  const containerHeight = height - theme.sizes.headerHeight;
  const screenWithoutSidebar = width - theme.sizes.sidebarWidth;

  return {
    fullScreen,
    movingWindow,
    containerHeight,
    subContainerHeight,
    screenWithoutSidebar,
  };
};

import React, { memo, useCallback, useEffect, useId, useMemo } from 'react';
import { PortalHost } from './PortalHost';
import { PortalProvider } from './PortalProvider';
import { usePortal } from './usePortal';

type PortalProps = {
  hostName: string;
  children: React.ReactNode;
};

const PortalComponent = memo(({ hostName, children }: PortalProps) => {
  const id = useId();

  const { addUpdatePortal, removePortal } = usePortal(hostName);

  const name = useMemo(() => id, [id]);

  const handleOnMountOrUpdate = useCallback(() => {
    addUpdatePortal(name, children);
  }, [addUpdatePortal, children]);

  const handleOnUnmount = useCallback(() => {
    removePortal(name);
  }, [removePortal]);

  useEffect(() => {
    handleOnMountOrUpdate();

    return () => {
      handleOnUnmount();
    };
  }, []);

  useEffect(() => {
    handleOnMountOrUpdate();
  }, [children]);

  return null;
});

export const Portal = {
  Gate: PortalComponent,
  Host: PortalHost,
  Provider: PortalProvider
};

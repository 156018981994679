import styled from '@2pulse/responsive';
import { withComponents } from '@app/helpers';
import { normalize } from '@app/helpers';
import { row } from '@app/style';

const Label = styled.Text<{ isActive?: boolean }>(({ theme, props }) => ({
  fontFamily: 'Work Sans',
  fontSize: normalize(23),
  fontWeight: '500',
  color: props.isActive ? '#0089C8' : theme.colors.oxfordblue,
}));

const Tabs = styled.View({
  ...row,
  alignItems: 'flex-end',
});

export const Tab = withComponents(styled.TouchableOpacity<{ isActive?: boolean }>(({ props }) => ({
  padding: normalize(21),
  borderRadius: 8,
  backgroundColor: props.isActive ? '#C7E4F2' : 'transparent',
})), {
  Tabs,
  Label,
});

import { normalize, withComponents } from '@app/helpers';
import styled from '@2pulse/responsive';
import { row, worksansoxford } from '@app/style';

export interface StyledTextInputProps {
  inside?: boolean;
  size?: "large" | "small";
  center?: boolean;
  round?: boolean;
  isBlurred?: boolean;
  error?: boolean;
  nude?:boolean;
}

const Holder = styled.View({
  flexGrow: 1,
});

const Label = styled.Text<{ isBlurred: boolean, isDirty: boolean, isFilled: boolean }>(({ theme, props }) => ({
  position: 'absolute',
  left: props.isFilled ? normalize(21) : props.isBlurred ? normalize(34) : normalize(21),
  top: props.isFilled ? -9 : props.isBlurred ? 22 : -9,
  zIndex: 10,
  ...worksansoxford,
  fontSize: props.isFilled ? normalize(17) : props.isBlurred ? normalize(21) : normalize(17),
  fontWeight: props.isFilled ? '600' : props.isBlurred ? '500' : '600',
  color: theme.colors.babypowder,
  opacity: 0.89,
  pointerEvents: 'none',
}));

const Input = styled.TextInput<StyledTextInputProps>(({ theme, props }) => ({
  height:
    props.size === "large" ? normalize(92) :
      props.size === "small" ? normalize(58) :
        normalize(76),
  ...worksansoxford,
  fontSize: normalize(21),
  fontWeight: '500',
  aspectRatio: props.round ? "1/1" : undefined,
  color: theme.colors.babypowder,
  textAlign: props.center ? 'center' : 'left',
  borderRadius: props.inside ? 0 : props.round ? 100 : 5,
  backgroundColor: props.size === "small" ? theme.colors.oxfordblue : 'rgba(240, 249 , 252, .04)',
  paddingHorizontal: props.nude ? 0 : props.size === "small" ? normalize(21) : normalize(34),
  outlineWidth: 0,
  borderBottomColor: props.nude ? 'transparent' : props.error ? theme.colors.crimson : props.isBlurred ? 'rgba(255,255,255,0)' : theme.colors.celadonblue,
  borderBottomWidth: props.nude ? 0 : 3,
  opacity: 1,
  width: '100%',
  zIndex: 999,
}));

const Search = styled.TextInput<StyledTextInputProps>(({ theme, props }) => ({
  height: normalize(68),
  ...worksansoxford,
  fontSize: normalize(19),
  fontWeight: props.isBlurred ? '400' : '600',
  aspectRatio: props.round ? "1/1" : undefined,
  color: props.isBlurred ? '#999' : theme.colors.babypowder,
  textAlign: props.center ? 'center' : 'left',
  paddingLeft: 136,
  backgroundColor: props.isBlurred ? 'rgba(0,137,200,0.04)' : theme.colors.celadonblue,
  outlineWidth: 0,
  borderTopColor: props.isBlurred ? 'rgba(0,137,200,0.02)' : theme.colors.celadonblue,
  borderBottomColor: props.isBlurred ? 'rgba(255,255,255,0)' : theme.colors.celadonblue,
  borderBottomWidth: 2,
  borderTopWidth: 2
}));

const IconHolder = styled.View(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  top: 20,
  left: 106,
  width: 20,
  height: 20,
}));


const InputDoubleContainer = styled.View(({ theme }) => ({
  ...row,
  height: normalize(76),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const InputDoubleFirstAction = styled.TouchableOpacity<{ inside?: boolean }>(({ theme, props }) => ({
  flexGrow: 1,
  flexDirection: 'row',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: normalize(34),
  paddingRight: normalize(21),
  borderTopLeftRadius: props.inside ? 0 : 5,
  borderBottomLeftRadius: props.inside ? 0 : 5,
  backgroundColor: 'rgba(240, 249 , 252, .04)',
}));

const InputDoubleSecondAction = styled.TouchableOpacity<{ inside?: boolean }>(({ theme, props }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: normalize(21 + 34 + 21),
  backgroundColor: 'rgba(240, 249 , 252, .04)',
  borderTopRightRadius: props.inside ? 0 : 5,
  borderBottomRightRadius: props.inside ? 0 : 5,
  marginLeft: normalize(2),
}));

const InputDoubleLabel = styled.Text(({ theme }) => ({
  fontSize: normalize(21),
  color: theme.colors.babypowder,
}));

const InputDoubleIconFrame = styled.View(({ theme }) => ({
  width: normalize(34),
  height: normalize(34),
}));

export const StyledInputs = {
  Input,
  Search,
  Label,
  Holder,
  IconHolder,
  InputDoubleContainer,
  InputDoubleFirstAction,
  InputDoubleSecondAction,
  InputDoubleLabel,
  InputDoubleIconFrame
}
import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { IngredientCard, IngredientCreate, IngredientDeleteModal, IngredientList, IngredientUpdate } from '@app/features/Ingredient';
import { useResourceScreenHelpers } from '@app/hooks';
import { ingredientApi } from '@app/store';
import { ProductHeader } from '@app/features';
import { IIngredient } from '@tickeat/common';

export const IngredientScreen = () => {
  const [readOne, { isFetching }] = ingredientApi.useLazyReadOneIngredientQuery();
  const [deleteOne, { isLoading }] = ingredientApi.useDeleteOneIngredientMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: ingredient } = useResourceScreenHelpers<IIngredient>();

  const openCard = (item: Partial<IIngredient>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
    }), item);
  }

  return (
    <MainLayout
      header={(
        <ProductHeader
          buttonTitle='Ajouter un ingrédient'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <IngredientDeleteModal
        {...modal}
        onDelete={(ingredient) => onDelete(deleteOne(ingredient._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <IngredientList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={<IngredientCard toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />}
        form={(
          mode === 'CREATE'
            ? <IngredientCreate />
            : <IngredientUpdate
              ingredient={ingredient}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


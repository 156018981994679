import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { AccountCodeCard, AccountCodeCreate, AccountCodeDeleteModal, AccountCodeList, AccountCodeUpdate } from '@app/features/AccountCode';
import { useResourceScreenHelpers } from '@app/hooks';
import { accountCodeApi } from '@app/store';
import { ProductHeader } from '@app/features';
import { IAccountCode } from '@tickeat/common';

export const AccountCodeScreen = () => {
  const [readOne, { isFetching }] = accountCodeApi.useLazyReadOneAccountCodesQuery();
  const [deleteOne, { isLoading }] = accountCodeApi.useDeleteOneAccountCodesMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: accountCode } = useResourceScreenHelpers<IAccountCode>();

  const openCard = (item: Partial<IAccountCode>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
    }), item);
  }

  return (
    <MainLayout
      header={(
        <ProductHeader
          buttonTitle='Ajouter un code comptable'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <AccountCodeDeleteModal
        {...modal}
        onDelete={(accountCode) => onDelete(deleteOne(accountCode._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <AccountCodeList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={<AccountCodeCard toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />}
        form={(
          mode === 'CREATE'
            ? <AccountCodeCreate />
            : <AccountCodeUpdate
              accountCode={accountCode}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


import React from 'react';
import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { col, row, worksansoxford } from '@app/style';
import { PriceInput } from './PriceInput';
import { TaxSelect } from './selects/features/TaxSelect';
import { useInput } from '@app/hooks/useInput';

const PriceFormContainer = styled.View(({ theme }) => ({
  ...col,
  minHeight: 76,
  backgroundColor: 'rgba(240, 249 , 252, .04)',
}));

const PriceFormLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  fontWeight: '700',
  fontSize: normalize(21),
  paddingTop: normalize(34),
  paddingBottom: normalize(21),
  textAlign: 'center',
  color: theme.colors.babypowder,
}));

const PriceFormContent = styled.View(({ theme }) => ({
  ...row,
  columnGap: normalize(21),
  paddingBottom: normalize(34),
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

const PriceFormCol = styled.View(({ theme }) => ({
  ...col,
  rowGap: normalize(21),
  width: '20%',
  overflow: 'hidden',
}));

const PriceFormErrors = styled.View(({ theme }) => ({
  ...col,
  gap: 8,
  paddingHorizontal: 34,
  marginBottom: 21,
}));

const PriceFormError = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.crimson,
  paddingHorizontal: 13,
  paddingVertical: 8,
  borderRadius: 5,
}));

const PriceFormErrorLabel = styled.Text(({ theme }) => ({
  color: theme.colors.babypowder,
  fontSize: normalize(15),
  fontWeight: '800',
}));

const PriceLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  textAlign: 'center',
  fontWeight: '400',
  fontSize: normalize(21),
  color: theme.colors.babypowder,
}));

interface PriceFormProps {
  label: string;
  names: {
    price: string,
    tax: string,
    takeAwayPrice: string;
    takeAwayTax: string;
    deliveryPrice: string;
    deliveryTax: string;
    onSpotPrice: string;
    onSpotTax: string;
  };
}

export const PriceForm = ({ label, names }: PriceFormProps) => {
  // https://stackoverflow.com/questions/53906843/why-cant-react-hooks-be-called-inside-loops-or-nested-function
  const errors = Object.values(names)
    .map(useInput)
    .filter(error => !!error.error);

  return (
    <PriceFormContainer>
      <PriceFormLabel>{label}</PriceFormLabel>

      <PriceFormErrors>
        {errors.map(error => (
          <PriceFormError key={error.field.name}>
            <PriceFormErrorLabel>{error.error}</PriceFormErrorLabel>
          </PriceFormError>
        ))}
      </PriceFormErrors>

      <PriceFormContent>
        <PriceFormCol>
          <PriceLabel>Base</PriceLabel>
          <PriceInput
            center
            size="small"
            keyboardType='numeric'
            showError={false}
            name={names.price}
            placeholder="Prix TTC"
            placeholderTextColor="rgba(255,255,255,0.55)" />

          <TaxSelect name={names.tax} center />
        </PriceFormCol>
        <PriceFormCol>
          <PriceLabel>Emporter</PriceLabel>
          <PriceInput
            center
            size="small"
            keyboardType='numeric'
            showError={false}
            name={names.takeAwayPrice}
            placeholder="Prix TTC"
            placeholderTextColor="rgba(255,255,255,0.55)" />

          <TaxSelect name={names.takeAwayTax} center />
        </PriceFormCol>
        <PriceFormCol>
          <PriceLabel>Livraison</PriceLabel>
          <PriceInput
            center
            size="small"
            keyboardType='numeric'
            name={names.deliveryPrice}
            showError={false}
            placeholder="Prix TTC"
            placeholderTextColor="rgba(255,255,255,0.55)" />

          <TaxSelect name={names.deliveryTax} center />
        </PriceFormCol>

        <PriceFormCol>
          <PriceLabel>Sur place</PriceLabel>
          <PriceInput
            center
            keyboardType='numeric'
            size="small"
            name={names.onSpotPrice}
            showError={false}
            placeholder="Prix TTC"
            placeholderTextColor="rgba(255,255,255,0.55)" />

          <TaxSelect name={names.onSpotTax} center />
        </PriceFormCol>
      </PriceFormContent>

    </PriceFormContainer>
  );
};

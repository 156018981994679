import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IPromotion } from "@tickeat/common";

interface PromotionDeleteModalProps extends ModalInstance<IPromotion>, Omit<DeleteModalProps<IPromotion>, 'children'> {
  onDelete: (product: Partial<IPromotion>) => void;
}

export const PromotionDeleteModal = (props: PromotionDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer une promotion.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible.
      </Modal.Message>
    </DeleteModal>
  )
}
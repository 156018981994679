import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const SvgBin = ({ strokePath, ...props }: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        stroke={props.color}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M42.188 10.938H7.813M20.313 20.313v12.5M29.688 20.313v12.5M39.063 10.938v29.687c0 .863-.7 1.563-1.563 1.563h-25c-.863 0-1.563-.7-1.563-1.563V10.937"
      />
      <Path
        d="M32.813 10.938V7.812c0-1.725-1.4-3.125-3.126-3.125h-9.375a3.125 3.125 0 0 0-3.125 3.125v3.125"
        stroke={props.color}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);


import { ArrayMinSize, IsArray, IsBoolean, IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { IBase, IOption, IProduct } from "../../interfaces";
import { Type } from "class-transformer";
import { TypeORMRelationId } from "../utils.dto";

export class OptionDTO implements Omit<IOption, keyof IBase>  {
  @IsNotEmpty({ message: 'Le nom de l\'option est requis' })
  name: string;

  @IsNotEmpty({ message: 'L\'option est-elle active ?' })
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsNotEmpty({ message: 'L\'option est-elle obligatoire ?' })
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  mandatory: boolean;

  @IsNotEmpty({ message: 'La quantité gratuite de produits est requise' })
  freeQuantity: number;

  @IsNotEmpty({ message: 'Le nombre maximum de produits sélectionnable est requis' })
  maxChoiceCount: number;

  @IsOptional()
  position?: number | undefined;

  @IsArray({ message: 'Au moins un produit est requis' })
  @ArrayMinSize(1, { message: 'Au moins un produit est requis' })
  @ValidateNested({
    each: true,
    message: 'Les produits ne sont pas du bon type'
  })
  @Type(() => TypeORMRelationId)
  products: IProduct[];
}

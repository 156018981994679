import { TextInputProps as RNTextInputProps } from 'react-native';
import { memo, useEffect, useRef, useState } from "react";
import { convertToCents, convertToEuros } from "@tickeat/common";
import { StyledInputs, StyledTextInputProps } from "../styled/form/Inputs";
import { StyledForms } from "../styled/form/Form";
import { useInput } from "@app/hooks/useInput";
import { isNumber } from '@app/helpers/kindOf';

export interface PriceInputProps extends RNTextInputProps, StyledTextInputProps {
  name: string;
  showError?: boolean;
}

const priceRegex = /^[0-9]*([,.][0-9]{0,2})?$/g;

export const PriceInput = memo(({ name, showError = true, ...props }: PriceInputProps) => {
  const { field, error } = useInput(name);

  const ref = useRef<any>(null);

  const [value, setValue] = useState<string | number | null>(isNumber(field.value) ? convertToEuros(field.value) : null);

  useEffect(() => {
    setValue(isNumber(field.value) ? convertToEuros(field.value) : null);
  }, [setValue, field.value]);

  const onChangeText = (text: string) => {
    if (text.match(priceRegex)) {
      setValue(text);

      field.onChange(!!text.length ? convertToCents(text.toString().replace(',', '.')) : null);
    }
  }

  return (
    <StyledInputs.Holder>
      {(!!error && showError) && (
        <StyledForms.Error>
          <StyledForms.ErrorLabel>
            {error}
          </StyledForms.ErrorLabel>
        </StyledForms.Error>
      )}

      <StyledInputs.Input
        onChangeText={onChangeText}
        onBlur={field.onBlur}
        value={value as string}
        keyboardType='number-pad'
        ref={(e) => {
          field.ref(e);
          ref.current = e;
        }}
        {...props} />
    </StyledInputs.Holder>
  );

})
import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IIngredient } from "@tickeat/common";

interface IngredientDeleteModalProps extends ModalInstance<IIngredient>, Omit<DeleteModalProps<IIngredient>, 'children'> {
  onDelete: (product: Partial<IIngredient>) => void;
}

export const IngredientDeleteModal = (props: IngredientDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer un ingrédient.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible. Vous perdrez toutes les données liées à cette ingrédient. Il sera supprimé des produits auquel il est associé.
      </Modal.Message>
    </DeleteModal>
  )
}
import { triptychSlice, TriptychSliceState } from "@app/store";
import { useAppDispatch } from "@app/store/hooks";
import { useCallback } from "react";

export const useTriptych = () => {
  const dispatch = useAppDispatch();

  const open = useCallback(<T>(props?: { mode?: TriptychSliceState['mode']; data?: T; }) => {
    dispatch(triptychSlice.actions.open(props));
  }, []);

  const close = useCallback(<T>(props?: { mode?: TriptychSliceState['mode']; data?: T; }) => {
    dispatch(triptychSlice.actions.close(props));
  }, []);

  const toggle = useCallback(() => {
    dispatch(triptychSlice.actions.toggle());
  }, []);

  const setData = useCallback(<T>(data: T) => {
    dispatch(triptychSlice.actions.setData(data));
  }, []);

  const setCardData = useCallback(<T>(data: T) => {
    dispatch(triptychSlice.actions.setCardData(data));
  }, []);

  const reset = useCallback(() => {
    dispatch(triptychSlice.actions.reset());
  }, []);

  return {
    setData,
    setCardData,
    open,
    close,
    toggle,
    reset
  }
}
import { Type } from "class-transformer";
import { ArrayMinSize, IsArray, IsBoolean, IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { CatalogProductToProductVariationDTO } from "./catalog-product-to-product-variation.dto";
import { ICatalogProductToProductVariation } from "../../interfaces/catalog/catalog-product-to-product-variation.interface";

export class CatalogToProductDTO {
  @IsNotEmpty({ message: 'L\'identifiant du produit est requis' })
  product: {
    _id: string
  };

  @IsOptional()
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsOptional()
  @IsArray({ message: 'Le format de données est inccorect'})
  @ArrayMinSize(1, { message: 'Au moins une déclinaison de produit est requise'})
  @Type(() => CatalogProductToProductVariationDTO)
  @ValidateNested({
    message: 'La déclinaison de produit n\'est pas correct'
  })
  catalogProductToProductVariations: ICatalogProductToProductVariation[];
}

import { IBase, IImageBySizes } from "./base.interface";

interface IHubriseConfig {
  locationId: string;
  accessToken: string;
  accountId: string;
  accountName: string;
  locationName: string;
  catalogId: string;
  catalogName: string;
  customerListId: string;
  customerListName: string;
}

interface IExternalApiConfig {
  hubrise?: IHubriseConfig;
}

export interface IShop extends IBase {
  internalName: string;

  slug: string;

  siret: string;

  publicName: string;

  disabled: boolean;

  description: string;

  address: string;

  location: {
    coordinates: number[];
    type: 'Point';
  };

  phone: string;

  email: string;

  website: string;

  brands: string[] | any[];

  takeAwaySchedule: any;

  deliverySchedule: any;

  deliveryZones: any[];

  takeAwayTime: number;

  deliveryTime: number;

  defaultTakeAwayTime: number;

  defaultDeliveryTime: number;

  printers: any[];

  eshopMessage: string;

  isDeliveryOpen: boolean;

  isTakeawayOpen: boolean;

  externalApiConfig?: IExternalApiConfig;

  socialMediaLinks?: ISocialMediaLinks;

}

export type ISocialMediaLinks = {
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
}

export interface IAdvertisement extends IBase {
  name: string;
  type: AdvertisementTypes;
  images: IImageBySizes;
  description?: string;
  disabled: boolean;
  startDate: Date;
  endDate: Date;
  url: string;
  shopIds?: IShop[];
}

export const isShopWithHubrise = (shop: Partial<IShop>): boolean => {
  return !!shop?.externalApiConfig?.hubrise?.locationId;
}

export interface IFacadeAdvertisement
  extends Pick<
    IAdvertisement,
    '_id' | 'name' | 'type' | 'description' | 'startDate' | 'endDate'
  > {}

export enum AdvertisementTypes {
  DELIVERY = 'DELIVERY',
  TAKEAWAY = 'TAKEAWAY'
}

import { IBase } from "../base.interface";

export interface IUser extends IBase {
  firstname: string;
  
  lastname: string;

  email: string;

  password: string;

  organization: string;

  phone: string;

  deletedAt: Date;
}

import { IAccountCode } from "./account-code.interface";
import { IBase } from "../base.interface";
import { IProductVariationToIngredient } from "./product-variation-to-ingredient.interface";
import { IProduct } from "./product.interface";
import { ITax } from "./tax.interface";
import { IVariation } from "./variation.interface";

export interface IProductVariation extends IBase {
  name: string;

  imageUrl?: string;

  kitchenName: string;

  description: string;

  price: number;

  tax: ITax;

  taxId: string;

  deliveryPrice: number;

  deliveryTax: ITax;

  deliveryTaxId: string

  takeAwayPrice: number;

  takeAwayTax: ITax;

  takeAwayTaxId: string;

  onSpotPrice: number;

  onSpotTax: ITax;

  onSpotTaxId: string;

  loyaltyPointOffered: number;

  disabled: boolean;

  variation?: IVariation;

  variationId: string;

  accountCode: IAccountCode;

  accountCodeId: string;

  product: IProduct;

  productId: string;

  productVariationToIngredients: IProductVariationToIngredient[];

}

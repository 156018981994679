import { IOption } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi } from '../api';

export const optionApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<IOption>(builder, 'options', 'Options');

    return {
      createOneOption: endpoints.createOne(),
      readManyOption: endpoints.readMany(),
      readOneOption: endpoints.readOne(),
      updateOneOption: endpoints.updateOne(),
      deleteOneOption: endpoints.deleteOne(),
    }
  },
});

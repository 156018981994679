import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
export const SvgTransferBack = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <Path d="M18 30H5V17" />
      <Path d="M44 36c.003-7.684-4.534-14.612-11.495-17.553-6.96-2.941-14.972-1.315-20.298 4.12L6 28.897" />
    </G>
  </Svg>
);


import { IBase } from '@tickeat/common';
import { MultipleSelect, MultipleSelectProps } from './MultipleSelect';
import { SimpleSelect, SimpleSelectProps } from './SimpleSelect';
import { useMemo } from 'react';

export type SelectProps<T> = (SimpleSelectProps<T> | MultipleSelectProps<T>) & { isMultiple?: boolean };

export const Select = <T extends any,>(props: Omit<SelectProps<T>, 'onSelect' | 'onRemove'>) => {
  const Component = useMemo(() => {
    return props.isMultiple ? MultipleSelect : SimpleSelect;
  }, [props.isMultiple]);

  return (
    // @ts-ignore
    <Component {...props} />
  )
};

import { ButtonIcon, Card, CardButtonActionMode, HeaderGradient, MiniCard, Row } from '@app/components';
import React, { memo } from 'react';
import { theme } from '@app/theme';
import { useDynamicSizes } from '@app/hooks';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet } from 'react-native';
import { ICatalog } from '@tickeat/common';
import SvgImage from '@app/components/icons/SvgImage';
import { UseFormReturn, useWatch } from 'react-hook-form';

type CatalogCardProps = {
  catalog?: Partial<ICatalog>;
  formMethods: UseFormReturn<any, any, any>;
  onOpenImagePicker: () => void;
  toggle: (mode: CardButtonActionMode) => void;
};

const formatCardData = (partialCatalog: Partial<ICatalog>) => {
  return {
    name: partialCatalog?.name || 'Nouveau catalogue',
    ticketInfos: partialCatalog?.ticketInfos
  }
}

export const CatalogCard = memo(({ toggle, catalog, onOpenImagePicker, formMethods }: CatalogCardProps) => {
  const { subContainerHeight } = useDynamicSizes();

  const cardData = useWatch({
    control: formMethods.control,
  });

  const { name, ticketInfos } = formatCardData(cardData);

  const logo = catalog?.ticketInfos?.logo ?? ticketInfos?.logo;

  const previewUrl = cardData.image ? URL.createObjectURL(cardData.image) : null;

  return (
    <Card>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient}
        />
      </HeaderGradient>

      <Card.TopHeader>
        <Card.TopHeaderLabel>Fiche catalogue</Card.TopHeaderLabel>
      </Card.TopHeader>

      <Card.Container height={subContainerHeight}>
        <Card.Background source={require('@app/assets/img/cardbackground.png')} />

        <Card.Content>
          <Card.Header>
            <Row justify="space-between" align="center">
              <Card.HeaderTitles>
                <Card.Title numberOfLines={2}>{name}</Card.Title>
              </Card.HeaderTitles>

              <Card.ButtonAction onPress={toggle} />
            </Row>
          </Card.Header>

          <Card.VisualContainer>
            <Card.Visual
              // @ts-ignore
              extraStyle={{ filter: 'grayscale(100%)' }}
              source={{ uri: previewUrl ? previewUrl : logo }} />

            <Card.VisualEdit>
              <ButtonIcon Icon={SvgImage} onPress={() => onOpenImagePicker()} />
            </Card.VisualEdit>
          </Card.VisualContainer>
        </Card.Content>

        <MiniCard>
          <MiniCard.Container />
        </MiniCard>
      </Card.Container>
    </Card>
  );
});


const styles = StyleSheet.create({
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { promotionApi } from '@app/store';
import { OrderMode, PromotionDTO, PromotionDiscountModes, PromotionTypes } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { PromotionForm } from './PromotionForm';
import { PromotionFormValues, transformStepFormValuesToPayload } from './promotion-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const PromotionCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = promotionApi.useCreateOnePromotionsMutation();

  const formMethods = useForm<PromotionFormValues>({
    resolver: classValidatorResolver(PromotionDTO),
    defaultValues: {
      name: '',
      description: '',
      code: undefined,
      minPrice: 0,
      neededCount: undefined,
      offeredCount: undefined,
      uniqueByCustomer: false,
      maxUses: undefined,
      shopsIds: [],
      discountMode: PromotionDiscountModes.FIXED,
      orderModes: Object.values(OrderMode),
      disabled: false,
      cumulative: true,
      type: PromotionTypes.LEAST_EXPENSIVE
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: PromotionFormValues) => {
    try {
      const promotion = await create(transformStepFormValuesToPayload(formValues)).unwrap();

      triptych.close({ mode: 'UPDATE', data: promotion });

      toast.success('Votre promotion a bien été ajoutée.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre promotion.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <PromotionForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { ILogin } from '@app/types';
import { authApi } from '@app/store/services/auth';
import styled from '@2pulse/responsive';
import { KeyboardAvoidingView } from 'react-native';
import { TextInput } from '@app/components';
import { Button, Form } from '@app/components';
import { StyledForms } from '@app/components/styled/form/Form';

const LoginView = styled.View({
  width: '100%',
  height: '100%',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#02182B',
});

const LoginForm = styled.View({
  width: 420,
});

const LoginSubmit = styled.View({
  width: 300,
  marginLeft: 60,
});

const Logo = styled.Image({
  marginTop: 21,
  marginBottom: 34,
  alignSelf: 'center',
  height: 70,
  width: 345,
});

const TextError = styled.Text({
  color: 'red',
  marginBottom: 10,
});

const schema = yup
  .object({
    email: yup.string().required('Ce champ est requis'),
    password: yup.string().required('Ce champ est requis'),
  })
  .required();

export const Login = () => {
  const formMethods = useForm<ILogin>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { errors } = formMethods.formState;

  const [onLogin, { isLoading }] = authApi.useLoginMutation();

  return (
    <LoginView>
      <KeyboardAvoidingView behavior="position">
        <Logo source={require('@app/assets/img/logo.png')} />

        {errors.email && <TextError>{errors.email.message}</TextError>}

        <LoginForm>
          <Form formMethods={formMethods}>

            <StyledForms.Form>

              <TextInput
                name="email"
                label="Email"
                placeholderTextColor="black"
                autoCapitalize="none"
                autoComplete="off"
              />

              {errors.password && <TextError>{errors.password.message}</TextError>}

              <TextInput
                name="password"
                label="Mot de passe"
                placeholderTextColor="black"
                secureTextEntry={true}
                autoCapitalize="none"
                autoComplete="off"
              />

              <LoginSubmit>
                <Button
                  large
                  title='Connexion'
                  loading={isLoading}
                  onPress={formMethods.handleSubmit(onLogin)} />
              </LoginSubmit>

            </StyledForms.Form>

          </Form>

        </LoginForm>
      </KeyboardAvoidingView>

    </LoginView>
  );
};

import { useAppDispatch } from '@app/store/hooks';
import { openModal, registerModal, unregisterModal } from '@app/store/slices/modal.slice';
import { useCallback, useEffect, useMemo } from 'react';

export const useModal = <T = any>(id: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(registerModal({ id }));

    return () => {
      dispatch(unregisterModal(id));
    }
  }, []);

  const open = useCallback((data?: T) => {
    dispatch(openModal({ id, changes: { isOpen: true, data } }));
  }, []);

  const close = useCallback(() => {
    dispatch(openModal({ id, changes: { isOpen: false } }));
  }, []);

  return {
    id,
    open,
    close
  }
}

export type ModalInstance<T = any> = ReturnType<typeof useModal<T>>;
import React from 'react';
import {
  useNavigationBuilder,
  TabRouter,
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabNavigationState,
} from '@react-navigation/native';
import { Sidebar } from './components';
import { RootStackParamList } from './types';
import { View } from 'react-native';
import { Portal } from '@tickeat/ui';

type TabNavigationOptions = {
  title?: string;
};

type CustomProps = {
  screensWithoutSidebar?: (keyof RootStackParamList)[];
};

type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  {}
> & CustomProps;

function SidebarNavigator({
  initialRouteName,
  children,
  screenOptions,
  screensWithoutSidebar = []
}: Props) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(TabRouter, {
      children,
      screenOptions,
      initialRouteName,
    });

  const activeRouteName = state.routeNames[state.index] as keyof RootStackParamList;
  const isSidebarHidden = screensWithoutSidebar?.includes(activeRouteName);

  return (
    <NavigationContent>
      {!isSidebarHidden && <Sidebar />}

      <Portal.Host name='root' />

      {state.routes.map((route, index) => {
        return (
          <View key={route.key} style={{ display: index === state.index ? 'flex' : 'none', height: '100%' }}>
            {index === state.index && descriptors[route.key].render()}
          </View>
        );
      })}
    </NavigationContent>
  );
}

export const createSidebarNavigator = createNavigatorFactory(SidebarNavigator);

import styled from '@2pulse/responsive';

export const HeaderGradient = styled.View(({ theme }) => ({
  width: '100%',
  height: theme.sizes.listHeaderHeight,
  position: 'absolute',
  top: 0,
  left: 0,
}));
export const TopCalendarGradient = styled.View(({ theme }) => ({
  width: '100%',
  height: 34,
  position: 'absolute',
  top: theme.sizes.listHeaderHeight,
  left: 0,
}));

export const TopGradient = styled.View(({ theme }) => ({
  width: '100%',
  height: 34,
  position: 'absolute',
  top: theme.sizes.listHeaderHeight,
  left: 0,
}));

export const BottomGradient = styled.View(({ theme }) => ({
  width: '100%',
  height: 34,
  position: 'absolute',
  bottom: 0,
  left: 0,
}));

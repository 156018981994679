import { CardComposer, Row } from "@app/components";
import SvgArrowDown from "@app/components/icons/SvgArrowDown";
import { SvgTransferBack } from "@app/components/icons/SvgTransferBack";
import { theme } from "@app/theme";
import { ICatalogToProduct } from "@tickeat/common/src/interfaces/catalog/catalog-to-product.interface";
import { useState } from "react";
import { CatalogProductToProductVariationForm } from "./CatalogProductToProductVariationForm";
import { useFieldArray } from "react-hook-form";

interface CatalogToCatalogProductProps {
  name: string;
  catalogToProduct: ICatalogToProduct;
  onRemove: () => void;
}

export const CatalogToCatalogProduct = ({ catalogToProduct, name, onRemove }: CatalogToCatalogProductProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { fields } = useFieldArray({
    name: `${name}.catalogProductToProductVariations`,
  });

  const tagsAsString = (catalogToProduct?.product?.tags || []).map(tag => tag.name).join(', ');

  if (!isOpen) {
    return (
      <CardComposer.SelectedItem>

        <CardComposer.SelectedItemTrigger onPress={() => setIsOpen(true)}>

          <Row align='center'>
            <CardComposer.SelectedItemIcon onPress={() => onRemove()}>
              <SvgTransferBack color={theme.colors.babypowder} />
            </CardComposer.SelectedItemIcon>
            <CardComposer.SelectedItemName>{catalogToProduct.product.name}</CardComposer.SelectedItemName>
          </Row>

          <Row align='center'>
            <CardComposer.SelectedItemCategory>{tagsAsString}</CardComposer.SelectedItemCategory>
            <CardComposer.SelectedItemDivider />

            <CardComposer.SelectedItemIcon onPress={() => setIsOpen(true)}>
              <SvgArrowDown color={theme.colors.babypowder} />
            </CardComposer.SelectedItemIcon>
          </Row>

        </CardComposer.SelectedItemTrigger>

        <CardComposer.SelectedItemDropdown />

      </CardComposer.SelectedItem>
    )
  }

  return (
    <CardComposer.SelectedItem>

      <CardComposer.SelectedItemTrigger isOpen onPress={() => setIsOpen(false)}>

        <Row align='center'>
          <CardComposer.SelectedItemIcon onPress={() => onRemove()}>
            <SvgTransferBack color={theme.colors.babypowder} />
          </CardComposer.SelectedItemIcon>
          <CardComposer.SelectedItemName isOpen>{catalogToProduct.product.name}</CardComposer.SelectedItemName>
        </Row>

        <Row align='center'>
          <CardComposer.SelectedItemCategory>{tagsAsString}</CardComposer.SelectedItemCategory>
          <CardComposer.SelectedItemDivider />

          <CardComposer.SelectedItemIcon onPress={() => setIsOpen(false)}>
            <SvgArrowDown color={theme.colors.babypowder} />
          </CardComposer.SelectedItemIcon>
        </Row>

      </CardComposer.SelectedItemTrigger>

      <CardComposer.SelectedItemDropdown isOpen>

        <CardComposer.DropdownHeader>
          <CardComposer.DropdownHeaderName>Déclinaison</CardComposer.DropdownHeaderName>
          <CardComposer.DropdownHeaderItem>Base</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Livraison</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Emporter</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Sur place</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Actif</CardComposer.DropdownHeaderItem>
        </CardComposer.DropdownHeader>

        {(fields as unknown as (ICatalogToProduct['catalogProductToProductVariations'][number] & { id: string })[]).map((field, index: number) => {
          return (
            <CatalogProductToProductVariationForm
              key={field.id}
              name={`${name}.catalogProductToProductVariations.${index}`}
              productVariation={field.productVariation} />
          )
        })}

      </CardComposer.SelectedItemDropdown>

    </CardComposer.SelectedItem>
  )
}
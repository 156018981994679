import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IShop } from "@tickeat/common";

interface ShopDeleteModalProps extends ModalInstance<IShop>, Omit<DeleteModalProps<IShop>, 'children'> {
  onDelete: (product: Partial<IShop>) => void;
}

export const ShopDeleteModal = (props: ShopDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer un magasin.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible.
      </Modal.Message>
    </DeleteModal>
  )
}
import { ReactNode, useCallback, useContext } from "react";
import { ACTIONS } from "./portal.reducer";
import { PortalDispatchContext } from "./PortalProvider";

export function usePortal(hostName: string) {
  const { dispatch } = useContext(PortalDispatchContext);

  const addUpdatePortal = useCallback((name: string, node: ReactNode) => {
    dispatch({
      type: ACTIONS.ADD_UPDATE_PORTAL,
      payload: { name, hostName, node }
    });
  }, []);

  const removePortal = useCallback((name: string) => {
    dispatch({
      type: ACTIONS.REMOVE_PORTAL,
      payload: { name, hostName }
    });
  }, []);

  const registerHost = useCallback(() => {
    dispatch({
      type: ACTIONS.REGISTER_HOST,
      payload: { hostName }
    });
  }, []);

  const unregisterHost = useCallback(() => {
    dispatch({
      type: ACTIONS.UNREGISTER_HOST,
      payload: { hostName }
    });
  }, []);

  return {
    addUpdatePortal,
    removePortal,
    registerHost,
    unregisterHost
  }
}
import { Calendar } from "@app/components";
import { ICatalog } from "@tickeat/common";
import { DateTime } from "luxon";
import { useMemo } from "react";

interface CatalogCardItemProps {
  catalog: ICatalog;
  headerItemWidth: number;
  minDate: DateTime;
  maxDate: DateTime;
}

export const CatalogCardItem = ({ catalog, headerItemWidth, minDate, maxDate }: CatalogCardItemProps) => {

  const { width, leftDistance } = useMemo(() => {
    const startDate = DateTime.fromISO(catalog.startDate ?? catalog.createdAt);
    const endDate = DateTime.fromISO(catalog.endDate);

    // Add 1 month to the diff if there is no end date to
    // make the card item overflow the calendar
    const { months } = endDate.isValid
      ? endDate.diff(startDate, ['months'])
      : maxDate.diff(startDate, ['months']).plus({ months: 1 });

    const width = (headerItemWidth * months);

    const diff = startDate.diff(minDate, ['months']);

    const leftDistance = (headerItemWidth * Math.abs(diff.months));

    return { width, leftDistance }
  }, [catalog, headerItemWidth, minDate, maxDate])

  return (
    <Calendar.Item width={width} leftDistance={leftDistance}>
      <Calendar.ItemHeader>
        <Calendar.ItemTitle>{catalog.name}</Calendar.ItemTitle>
        <Calendar.ItemSubtitle>{catalog.name}</Calendar.ItemSubtitle>
      </Calendar.ItemHeader>
      {/* <Calendar.ItemText>Saumur, Tours, Cholet</Calendar.ItemText> */}
    </Calendar.Item>
  )

}
import { Button, TextInput, Wizard } from '@app/components';
import { Toggle } from '@app/components/form/Toggle';
import React, { forwardRef, useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { defaultProductVariationsFormValues } from './product-form.helpers';
import { ProductVariationForm } from './ProductVariationForm';
import { OptionSelect } from '@app/components/form/selects/features/OptionSelect';
import { TagSelect } from '@app/components/form/selects/features/TagSelect';
import { StyledForms } from '@app/components/styled/form/Form';
import { InputFile, InputFileRef } from '@app/components/form/InputFile';
import { AllergenSelect } from '@app/components/form/selects/features/AllergenSelect';

export const ProductForm = forwardRef<any>((_, ref: React.ForwardedRef<InputFileRef>) => {
  const isDeclinable = useWatch({
    name: 'isDeclinable',
  });

  const { append, remove, fields } = useFieldArray({
    name: 'productVariations',
  });

  const addProductVariation = () => {
    append(defaultProductVariationsFormValues);
  }

  useEffect(() => {
    if (!isDeclinable) {
      const [_, ...fieldsIdx] = fields.map((_, index) => index);

      remove(fieldsIdx);
    }
  }, [isDeclinable]);

  return (
    <StyledForms.Form>

      <InputFile name="image" ref={ref} />

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <TextInput
        name="description"
        label="Description"
        autoCapitalize="none" />
      {/* 
      <Toggle
        name='disabled'
        options={[
          { title: "Actif", value: false },
          { title: "Inactif", value: true },
        ]} /> */}

      <OptionSelect name='options' isMultiple />

      <Toggle
        name='isAlcohol'
        options={[
          { title: "Avec alcool", value: true },
          { title: "Sans alcool", value: false },
        ]} />

      <AllergenSelect name="allergens" isMultiple />

      <TagSelect name='tags' isMultiple />

      <Toggle
        name='isDeclinable'
        options={[
          { title: "Produit unique", value: false },
          { title: "Produit déclinable", value: true },
        ]} />

      {fields.map((field, index) => (
        <ProductVariationForm
          key={field.id}
          index={index}
          isUnitary={!isDeclinable}
          remove={() => remove(index)}
          field={field} />
      ))}

      {isDeclinable && (
        <Button
          onPress={addProductVariation}
          title="Ajouter une déclinaison"
          bordered
          grow
          large
          square
          smallText />
      )}

    </StyledForms.Form>
  );
});

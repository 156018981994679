import { TextInput } from '@app/components';
import { DateTimePicker } from '@app/components/form/DateTimePicker';
import { InputFile, InputFileRef } from '@app/components/form/InputFile';
import { Toggle } from '@app/components/form/Toggle';
import { ShopSelect } from '@app/components/form/selects/features/ShopSelect';
import { StyledForms } from '@app/components/styled/form/Form';
import { AdvertisementTypes } from '@tickeat/common';
import React, { forwardRef, useRef } from 'react';

export const AdvertisementForm = forwardRef<any>((_, ref: React.ForwardedRef<InputFileRef>) => {


  return (
    <StyledForms.Form>
      
      <InputFile name="image" ref={ref} />

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <TextInput
        name="url"
        label="Lien"
        autoCapitalize="none" />

      <TextInput
        name="description"
        label="Description"
        autoCapitalize="none" />

      <Toggle
        name="disabled"
        options={[
          { title: "Active", value: false },
          { title: "Inactive", value: true },
        ]} />

      <DateTimePicker name="startDate" label="Date de début" />

      <DateTimePicker name="endDate" label="Date de fin" />

      <Toggle
        name="type"
        options={Object.values(AdvertisementTypes).map((type) => ({
          title: type === AdvertisementTypes.DELIVERY ? "Livraison" : "À Emporter",
          value: type,
        }))}
      />

      <ShopSelect
        label="Restaurants concernés"
        name="shopIds"
        isMultiple
      />
    </StyledForms.Form>
  );
});

import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { ITag } from "@tickeat/common";

interface TagDeleteModalProps extends ModalInstance<ITag>, Omit<DeleteModalProps<ITag>, 'children'> {
  onDelete: (product: Partial<ITag>) => void;
}

export const TagDeleteModal = (props: TagDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer une catégorie.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible.
      </Modal.Message>
    </DeleteModal>
  )
}
import React, { useEffect, useState } from 'react';
import { MainLayout, Triptych } from '@app/components';
import { ShopList, ShopCard, ShopDeleteModal, ShopCreate, ShopUpdate } from '@app/features/Shop';
import { useResourceScreenHelpers, useToast } from '@app/hooks';
import { shopApi } from '@app/store';
import { IShop } from '@tickeat/common';
import { SettingHeader } from '@app/features/SettingHeader';
import { useModal } from '@app/hooks/useModal';
import { ShopHubriseConnectModal, ShopHubriseConnectModalId } from '@app/features/Shop/ShopHubriseConnectModal';
import { useAppDispatch } from '@app/store/hooks';
import { rootApi } from '@app/store/api';
import { ShopHubriseDisconnectModal, ShopHubriseDisconnectModalId } from '@app/features/Shop/ShopHubriseDisconnectModal';
import { useRoute } from '@react-navigation/native';

declare const window: Window;

const AUTO_CLOSE_QUERY_PARAM = 'autoClose';
const CLOSE_TAB_MESSAGE_TYPE = 'close_tab';

export const ShopScreen = () => {
  const route = useRoute()
  const toast = useToast();

  const dispatch = useAppDispatch();

  const modal = useModal(ShopHubriseConnectModalId);
  const modalDisconnect = useModal(ShopHubriseDisconnectModalId);

  const [openedTabWindow, setOpenedTabWindow] = useState<Window | null>(null);

  const [readOne, { isFetching }] = shopApi.useLazyReadOneShopsQuery();
  const [deleteOne, { isLoading }] = shopApi.useDeleteOneShopsMutation();
  const [connectToHubrise, { isLoading: isConnectingToHubrise }] = shopApi.useConnectToHubriseMutation();
  const [disconnectHubrise, { isLoading: isDisconnectingHubrise }] = shopApi.useDisconnectHubriseMutation();

  const { triptych, modal: deleteModal, onDelete, onUpdate, mode, selectedItem, item: shop } = useResourceScreenHelpers<IShop>();

  const handleAutoClose = async () => {
    const windowOpener = window.opener as Window;

    const shopId = route.params!['shopId'];

    const shopUpdated = await readOne({ filters: { _id: { $eq: shopId } } }).unwrap();

    windowOpener.focus();

    windowOpener.postMessage({ type: CLOSE_TAB_MESSAGE_TYPE, data: shopUpdated })
  }

  useEffect(() => {
    if (route.params?.hasOwnProperty(AUTO_CLOSE_QUERY_PARAM) && Boolean(window.opener)) {
      handleAutoClose();
    }
  }, [route]);

  useEffect(() => {
    const handle = (event: MessageEvent<{ type: string, data: IShop }>) => {
      if (event.data?.type === CLOSE_TAB_MESSAGE_TYPE) {
        openedTabWindow?.close();

        dispatch(rootApi.util.invalidateTags(['Shops']));

        toast.success('Votre restaurant est désormais connecté à Hubrise.')

        triptych.close({ data: event.data.data, mode: 'UPDATE' })
      }
    };

    window?.addEventListener("message", handle, false);

    return () => {
      window.removeEventListener("message", handle);
    }
  }, [openedTabWindow])

  const openCard = (item: Partial<IShop>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
    }), item)
  }

  const onDisconnectHubrise = async (shop: IShop) => {
    try {
      const updatedShop = await disconnectHubrise({ shopId: shop._id }).unwrap();

      triptych.close({ data: updatedShop, mode: 'UPDATE' })

      toast.success('Votre restaurant à bien été déconnecté de Hubrise.')
    } catch (error) {
      toast.error("Une erreur est survenue");
    }

    modalDisconnect.close();
  }
  const onConnectHubrise = async (shop: IShop) => {
    try {
      const response = await connectToHubrise({ shopId: shop._id }).unwrap();

      const openedTabWindow = window.open(response.authorizationUrl, '_blank');

      setOpenedTabWindow(openedTabWindow);

      openedTabWindow?.focus();
    } catch (error) {
      toast.error("Une erreur est survenue");
    }

    modal.close();
  }

  return (
    <MainLayout
      header={(
        <SettingHeader
          buttonTitle='Ajouter un restaurant'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <ShopHubriseConnectModal
        {...modal}
        isLoading={isConnectingToHubrise}
        onConnect={onConnectHubrise} />

      <ShopHubriseDisconnectModal
        {...modalDisconnect}
        isLoading={isDisconnectingHubrise}
        onDisconnect={onDisconnectHubrise} />

      <ShopDeleteModal
        {...deleteModal}
        onDelete={(shop) => onDelete(deleteOne(shop._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <ShopList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={deleteModal.open} />
        )}
        card={(
          <ShopCard
            shop={selectedItem}
            onDisconnectHubrise={(shop) => modalDisconnect.open(shop)}
            onConnectToHubrise={(shop) => modal.open(shop)}
            toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />
        )}
        form={(
          mode === 'CREATE'
            ? <ShopCreate />
            : <ShopUpdate
              shop={shop}
              isFetching={isFetching}
              onDelete={deleteModal.open} />
        )} />
    </MainLayout >
  );
};


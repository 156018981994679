import React from "react";

export const ACTIONS = {
  ADD_UPDATE_PORTAL: 'ADD_UPDATE_PORTAL',
  REMOVE_PORTAL: 'REMOVE_PORTAL',
  REGISTER_HOST: 'REGISTER_HOST',
  UNREGISTER_HOST: 'UNREGISTER_HOST',
} as const;

export type PortalState = Record<string, Array<{
  name: string;
  node: React.ReactNode;
}>>

type AddPortalPayload = {
  name: string;
  hostName: string;
  node: React.ReactNode
}

type RemovePortalPayload = {
  name: string;
  hostName: string;
}

type RegisterHostPayload = {
  hostName: string;
}

type UnregisterHostPayload = {
  hostName: string;
}

export type ACTION_TYPES =
  | { type: (typeof ACTIONS)['REGISTER_HOST']; payload: RegisterHostPayload }
  | { type: (typeof ACTIONS)['UNREGISTER_HOST']; payload: UnregisterHostPayload }
  | { type: (typeof ACTIONS)['ADD_UPDATE_PORTAL']; payload: AddPortalPayload }
  | { type: (typeof ACTIONS)['REMOVE_PORTAL']; payload: RemovePortalPayload };

const addPortal = (state: PortalState, payload: AddPortalPayload): PortalState => {
  const { name, node, hostName } = payload;

  if (!(hostName in state)) {
    return registerHost(state, payload);
  }

  const index = state[hostName].findIndex(portalHost => portalHost.name === name);

  if (index !== -1) {
    state[hostName][index].node = payload.node;
  } else {
    state[hostName].push({ name, node });
  }

  return state;
}

const removePortal = (state: PortalState, payload: RemovePortalPayload): PortalState => {
  const { name, hostName } = payload;

  const index = state[hostName].findIndex(portalHost => portalHost.name === name);

  if (index !== -1) {
    state[hostName].splice(index, 1);
  }

  return state;
}

const registerHost = (state: PortalState, payload: RegisterHostPayload): PortalState => {
  const { hostName } = payload;

  if (!(hostName in state)) {
    state[hostName] = [];
  }

  return state;
}

const unregisterHost = (state: PortalState, payload: UnregisterHostPayload): PortalState => {
  const { hostName } = payload;

  delete state[hostName];

  return state;
}

export function portalReducer(state: PortalState, action: ACTION_TYPES): PortalState {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.ADD_UPDATE_PORTAL:
      return addPortal(newState, payload);
    case ACTIONS.REMOVE_PORTAL:
      return removePortal(newState, payload);
    case ACTIONS.REGISTER_HOST:
      return registerHost(newState, payload);
    case ACTIONS.UNREGISTER_HOST:
      return unregisterHost(newState, payload);
  }
}

import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IOption } from "@tickeat/common";

interface OptionDeleteModalProps extends ModalInstance<IOption>, Omit<DeleteModalProps<IOption>, 'children'> {
  onDelete: (product: Partial<IOption>) => void;
}

export const OptionDeleteModal = (props: OptionDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer une option.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible. Vous perdrez toutes les données liées à cette option. Elle sera supprimée des produits auquel elle est associée.
      </Modal.Message>
    </DeleteModal>
  )
}
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgArrowMinus = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50"  {...props}>
    <Path
      d="M7.813 25h34.374"
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SvgArrowMinus;


import React, { Fragment, memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { AdvertisementDTO, AdvertisementTypes } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { AdvertisementForm } from './AdvertisementForm';
import { AdvertisementFormValues, useAdvertisementFormSubmit } from './advertisement-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { AdvertisementCard } from './AdvertisementCard';
import { InputFileRef } from '@app/components/form/InputFile';
import { advertisementApi } from '@app/store/services/advertisement';

export const AdvertisementCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych();

  const imageRef = useRef<InputFileRef>(null);

  const onSubmit = useAdvertisementFormSubmit();

  const [create, { isLoading, error: apiErrors }] = advertisementApi.useCreateOneAdvertisementMutation();

  const formMethods = useForm<AdvertisementFormValues>({
    resolver: classValidatorResolver(AdvertisementDTO),
    defaultValues: {
      name: '',
      description: '',
      url: '',
      image: null,
      disabled: false,
      startDate: undefined,
      endDate: undefined,
      type: AdvertisementTypes.TAKEAWAY,
    },
  });

  const onCreate = async (formData: FormData) => {
    try {
      const advertisement = await create(formData).unwrap();

      triptych.close({ mode: 'UPDATE', data: advertisement });

      toast.success('Votre publicité a bien été ajoutée.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre publicité.')
    }
  }

  return (
    <Fragment>
      <AdvertisementCard
        formMethods={formMethods}
        toggle={() => triptych.toggle()}
        onOpenImagePicker={() => imageRef.current?.open()}
      />

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.Content>

          <Panel.FormContainer>

            <AdvertisementForm ref={imageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              loading={isLoading}
              title="Valider"
              onPress={() => onSubmit(formMethods, onCreate)}
              grow
              large />
          </Panel.Actions>
        </Panel.Content>

      </Form>
    </Fragment>
  );
});

import React from 'react';
import { BaseResourceListProps, SimpleList, SvgBin, SvgEdit } from '@app/components';
import { shopApi } from '@app/store';
import { IShop, isShopWithHubrise } from '@tickeat/common';
import { theme } from '@app/theme';
import { createColumnHelper } from '@tanstack/react-table';
import { useTable } from '@app/hooks/useTable';
import { SvgTransfer } from '@app/components/icons/SvgTransfer';

const columnHelper = createColumnHelper<IShop>();

interface ShopListProps extends BaseResourceListProps<IShop> {
}

export const ShopList = ({ onUpdate, onDelete, onRowPress, selectedRowItem }: ShopListProps) => {
  const columns = [
    columnHelper.accessor('publicName', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Nom</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.accessor('phone', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Téléphone</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.accessor('siret', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Siret</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <SimpleList.HeaderItem width="25%" align='center'>
          <SimpleList.HeaderItemLabel>Actions</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: ({ row, active }) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemActions>
            <SimpleList.ItemAction onPress={() => onUpdate(row.original)}>
              <SvgEdit color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
            <SimpleList.ItemAction onPress={() => onDelete(row.original)}>
              <SvgBin color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
          </SimpleList.ItemActions>
        </SimpleList.ItemCol >
      ),
    }),
  ];

  const { table, setSearch, isFetching } = useTable({
    columns,
    useQuery: ({ pagination, search }) => shopApi.useReadManyShopsQuery({
      pagination,
      filters: {
        publicName: {
          $contains: search
        }
      },
      sort: ['publicName:ASC'],
    })
  });

  return <SimpleList
    table={table}
    isFetching={isFetching}
    onSearch={setSearch}
    selectedRowItem={selectedRowItem}
    onRowPress={onRowPress} />;
};

import styled from '@2pulse/responsive';
import { normalize, withComponents } from '@app/helpers';
import { useDynamicSizes } from '@app/hooks/useDynamicSize';
import { col, row, worksansbaby, worksansoxford } from '@app/style';
import { WithTextProps, WithSizesProps, WithFlexProps } from '@app/types';
import { flexRender, Row, Table } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { ActivityIndicator, FlatListProps, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { BottomGradient, HeaderGradient, TopGradient } from '../decor/Gradients';
import { Pagination } from './Pagination';
import { Form } from '../form';
import { useForm } from 'react-hook-form';
import { isFunction } from '@app/helpers/kindOf';
import { useThrottle } from '@app/hooks';
import { SearchInput } from '../form/SearchInput';
import { theme } from '@app/theme';

const ListContainer = styled.View<{ height: number }>(({ props, theme }) => ({
  ...col,
  width: theme.sizes.layoutList,
  zIndex: 999,
  flexShrink: 0,
  height: props.height,
}));

const Header = styled.View(({ theme }) => ({
  ...row,
  alignItems: 'center',
  height: theme.sizes.listHeaderHeight,
  paddingLeft: 34 + theme.sizes.sidebarWidth,
  // backgroundColor: '#F8FCFD',
  zIndex: 1
}));

const HeaderFilters = styled.View<{ width: number }>(({ theme, props }) => ({
  position: 'absolute',
  zIndex: 9999999,
  left: 0,
  top: theme.sizes.listHeaderHeight,
  paddingLeft: theme.sizes.sidebarWidth,
  width: props.width,
  backgroundColor: '#0B2032',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 8,
  },
  shadowOpacity: 0.21,
  shadowRadius: 8,
  elevation: 13,
}));

const HeaderFiltersContainer = styled.ScrollView(({ theme, props }) => ({
  paddingHorizontal: normalize(34),
}));

const HeaderFilter = styled.Text<{ active?: boolean }>(({ theme, props }) => ({
  ...worksansbaby,
  fontSize: normalize(18),
  paddingVertical: normalize(34),
  fontWeight: props.active ? '600' : '400',
  color: props.active ? theme.colors.babypowder : '#CDCDCD',
}));

const HeaderTable = styled.View({
  ...row,
  width: '59.25%'
});

const HeaderItem = styled.TouchableOpacity<WithSizesProps & WithFlexProps>(({ props }) => ({
  width: props.width,
  flexShrink: 0,
  flexGrow: 0,
  ...row,
  alignItems: 'center',
  justifyContent: props.align,
}));

const HeaderItemLabel = styled.Text<WithTextProps & { active?: boolean }>(({ props }) => ({
  fontFamily: 'Work Sans',
  fontSize: normalize(18),
  fontWeight: props.active ? '700' : '400',
  color: props.active ? theme.colors.celadonblue : '#97999B',
  textAlign: props.align,
}));

const HeaderItemIcon = styled.View<{ active?: boolean }>(({ props }) => ({
  width: normalize(18),
  height: normalize(18),
  marginLeft: normalize(5),
  transform: props.active ? [{ rotate: '180deg' }] : undefined,
}));

const List = styled.FlatList({
  height: '100%',
  borderTopColor: '#DCDCDC',
  borderTopWidth: 1,
});

const Item = styled.Pressable<{ active?: boolean }>(({ theme, props }) => ({
  ...row,
  height: 80,
  alignItems: 'center',
  borderBottomColor: '#DCDCDC',
  backgroundColor: props.active ? theme.colors.celadonblue : 'rgba(0,0,0,0)',
  borderBottomWidth: 1,
  paddingLeft: 34 + theme.sizes.sidebarWidth,
  shadowColor: '#FFF',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 1,
  shadowRadius: 3,
  elevation: 5,
}));

const ItemCol = styled.View<WithSizesProps>(({ props }) => ({
  flexShrink: 0,
  flexGrow: 0,
  width: props.width,
}));

const ItemText = styled.Text<WithTextProps & { active?: boolean }>(({ props }) => ({
  fontSize: normalize(18),
  ...worksansoxford,
  color: props.active ? '#FFF' : worksansoxford.color,
  textAlign: props.align,
  fontWeight: props.bold ? '700' : '400',
  width: '100%',
}));

const ItemActions = styled.View<WithSizesProps>({
  ...row,
  width: '100%',
  justifyContent: 'center',
  columnGap: normalize(21),
});

const ItemAction = styled.TouchableOpacity<WithSizesProps>({
  width: normalize(28),
  height: normalize(28),
});

interface SimpleListProps<T> extends Omit<FlatListProps<T>, 'data' | 'keyExtractor' | 'renderItem'> {
  table: Table<T>;
  isFetching?: boolean;
  selectedRowItem?: Partial<T>;
  onSearch?: (search: string) => void;
  onRowPress?: (item: T) => void;
}

export interface BaseResourceListProps<TType> {
  onUpdate: (item: TType) => void;
  onDelete: (item: TType) => void;
  onRowPress?: (item: TType) => void;
  selectedRowItem?: Partial<TType>;
}

export const SimpleList = withComponents(<T,>({ table, onSearch, onRowPress, selectedRowItem, isFetching, ...props }: SimpleListProps<T>) => {
  const { containerHeight } = useDynamicSizes();

  const formMethods = useForm({
    defaultValues: {
      search: ''
    }
  });

  const search = formMethods.watch('search');

  const throttledValue = useThrottle(search, 250);

  useEffect(() => {
    if (isFunction(onSearch)) {
      table.setPageIndex(0);

      onSearch(throttledValue);
    }
  }, [onSearch, table, throttledValue])

  return (
    <ListContainer height={containerHeight}>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient} />
      </HeaderGradient>

      <Header>

        {table.getFlatHeaders().map(header => (
          <React.Fragment key={header.id}>
            {flexRender(header.column.columnDef.header, header.getContext())}

            {/* <SimpleList.HeaderItemIcon active>
              <SvgArrowDown color={theme.colors.celadonblue} />
            </SimpleList.HeaderItemIcon> */}
          </React.Fragment>
        ))}

        {/* <HeaderFilters width={fullScreen}>
          <HeaderFiltersContainer horizontal contentContainerStyle={{ columnGap: normalize(55) }}>
            <HeaderFilter>Toutes catégories</HeaderFilter>
            <HeaderFilter active>Pizzas</HeaderFilter>
            <HeaderFilter>Desserts</HeaderFilter>
            <HeaderFilter>Petits Plats</HeaderFilter>
            <HeaderFilter>Boissons</HeaderFilter>
          </HeaderFiltersContainer>
        </HeaderFilters> */}

      </Header>

      <TopGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0.0, 1]}
          colors={['rgba(0, 137, 200, 0.13)', 'rgba(0, 137, 200, 0)']}
          style={styles.linearGradient}
        />
      </TopGradient>

      <BottomGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0.0, 1]}
          colors={['rgba(0, 137, 200, 0)', 'rgba(0, 137, 200, 0.13)']}
          style={styles.linearGradient}
        />
      </BottomGradient>

      <Form formMethods={formMethods}>
        <SearchInput name='search' label='' placeholder='Recherche..' />
      </Form>

      {isFetching
        ? <View style={styles.loader}>
          <ActivityIndicator size='large' color={theme.colors.celadonblue} />
        </View>
        : <List
          onEndReachedThreshold={1}
          {...props}
          data={table.getRowModel().rows}
          keyExtractor={(item: Row<T>) => item.id}
          renderItem={({ item }: { item: Row<T> }) => {
            return <Item
              active={item.original['_id'] === selectedRowItem?.['_id']}
              onPress={() => onRowPress && onRowPress(item.original)}>
              {item.getVisibleCells().map(cell => (
                <React.Fragment key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, { ...cell.getContext(), active: item.original['_id'] === selectedRowItem?.['_id'] })}
                </React.Fragment>
              ))}
            </Item>
          }}
        />
      }

      <Pagination table={table} />
    </ListContainer>
  )
}, {
  Header,
  HeaderItem,
  HeaderItemLabel,
  HeaderTable,
  HeaderItemIcon,
  Item,
  ItemCol,
  ItemText,
  ItemActions,
  ItemAction,
});

const styles = StyleSheet.create({
  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    paddingLeft: 80,
    justifyContent: 'center',
    alignItems: 'center'
  },
  linearGradient: {
    flex: 1,
  },
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});
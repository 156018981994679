import { ApiErrorsProvider } from "@app/contexts";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form"
import { SkeletonForm } from "../skeletons/skeletons";
import { Fragment } from "react";

export interface FormProps<TFieldValues extends FieldValues> {
  isLoading?: boolean;
  formMethods: UseFormReturn<TFieldValues, any>;
  children: React.ReactNode;
  apiErrors?: FetchBaseQueryError | SerializedError;
  showError?: boolean;
  skeletonInputTotal?: number;
}

export const Form = <TFieldValues extends FieldValues,>({ formMethods, skeletonInputTotal, isLoading, showError, apiErrors, children }: FormProps<TFieldValues>) => {
  return (
    <Fragment>
      {isLoading
        ? <SkeletonForm inputTotal={skeletonInputTotal} />
        : <ApiErrorsProvider apiErrors={apiErrors as any} showError={showError}>
          <FormProvider {...formMethods}>
            {children}
          </FormProvider>
        </ApiErrorsProvider>}
    </Fragment>
  )
}
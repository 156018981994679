import { Type } from "class-transformer";
import { ArrayMinSize, ArrayNotEmpty, IsIn, IsMongoId, IsNotEmpty, IsOptional, ValidateIf, ValidateNested } from "class-validator";
import { IBase, ICatalog, IPack, IProductVariation, IPromotion, ITag, OrderMode, PromotionDiscountModes, PromotionTypes, isPromotionCode } from "../../interfaces";
import { TypeORMRelationId } from "../utils.dto";

export class PromotionDTO implements Omit<IPromotion, keyof IBase> {
  @IsNotEmpty({ message: 'Le nom de la promotion est requis' })
  name: string;

  @IsNotEmpty({ message: 'Le type de la promotion est requis' })
  @IsIn(Object.values(PromotionTypes), {
    message: 'Ce type de promotion n\'existe pas'
  })
  type: PromotionTypes;

  @ValidateIf(isPromotionCode)
  @IsNotEmpty({ message: 'Le mode de remise est requis' })
  @IsIn(Object.values(PromotionDiscountModes), {
    message: 'Ce mode de remise n\'existe pas'
  })
  discountMode?: PromotionDiscountModes;

  @ValidateIf(isPromotionCode)
  @IsNotEmpty({ message: 'La valeur de remise est requise' })
  discountValue?: number;

  @IsNotEmpty({ message: 'La description de la promotion est requise' })
  description: string;

  @ValidateIf(isPromotionCode)
  @IsNotEmpty({ message: 'Le code est requis' })
  code?: string;

  @IsOptional()
  imageUrl?: string;

  @IsOptional()
  disabled: boolean;

  @IsOptional()
  minPrice?: number;

  @IsOptional()
  uniqueByCustomer?: boolean;

  @IsOptional()
  maxUses?: number;

  @ArrayNotEmpty({ message: 'Le mode de livraison est requis' })
  @ArrayMinSize(1, { message: 'Au moins un mode de livraison est requis' })
  @IsIn(Object.values(OrderMode), {
    message: 'Ce mode de livraison n\'existe pas',
    each: true
  })
  orderModes: OrderMode[];

  @IsOptional()
  tags?: ITag[];

  // @ApiPropertyOptional({ description: 'Cette promotion applicable uniquement à la première commande du client' })
  // @IsOptional()
  // firstOrderOnly?: boolean;

  // @ApiPropertyOptional({ description: 'Packs requis dans le panier pour être éligible à la promotion' })
  // @ValidateIf((dto: PromotionDTO) => dto.type === PromotionTypes.LEAST_EXPENSIVE)
  // @IsOptional()
  // requiredPacks?: IPack[];

  // @ApiPropertyOptional({ description: 'Produits requis dans le panier pour être éligible à la promotion' })
  // @ValidateIf((dto: PromotionDTO) => dto.type === PromotionTypes.LEAST_EXPENSIVE)
  // @IsOptional()
  // requiredProductVariations?: IProductVariation[];

  @ValidateIf((dto: PromotionDTO) => dto.type === PromotionTypes.LEAST_EXPENSIVE)
  @ValidateIf((dto: PromotionDTO) => dto.discountableProductVariations?.length === 0)
  @IsNotEmpty({ message: 'Au moins un pack ou une déclinaison de produit est requis' })
  discountablePacks?: IPack[];

  @ValidateIf((dto: PromotionDTO) => dto.type === PromotionTypes.LEAST_EXPENSIVE)
  @ValidateIf((dto: PromotionDTO) => dto.discountablePacks?.length === 0)
  @IsNotEmpty({ message: 'Au moins un pack ou une déclinaison de produit est requis' })
  discountableProductVariations?: IProductVariation[];

  @ValidateIf((dto: PromotionDTO) => dto.type === PromotionTypes.LEAST_EXPENSIVE)
  @IsNotEmpty()
  neededCount?: number;

  @ValidateIf((dto: PromotionDTO) => dto.type === PromotionTypes.LEAST_EXPENSIVE)
  @IsNotEmpty()
  offeredCount?: number;

  @IsOptional()
  cumulative?: boolean;

  @IsOptional()
  startDate?: Date;

  @IsOptional()
  endDate?: Date;

  catalog?: ICatalog;
  firstOrderOnly?: boolean;
  requiredPacks?: IPack[];
  requiredProductVariations?: IProductVariation[];
  discountableProductVariationsIds?: string[];
  catalogShopToPromotions?: any[];
  customerOnly: boolean;

  customersIds?: string[];

  @ValidateNested()
  @Type(() => TypeORMRelationId)
  catalogs?: ICatalog[];

  @ValidateIf((dto: PromotionDTO) => !!dto.shopsIds?.length)
  @ArrayMinSize(1, {
    message: 'Au moins un magasin est requis'
  })
  @IsMongoId({ each: true, message: 'L\'identifiant est incorrect.' })
  shopsIds?: string[];

  usedCount?: number;

  usedCustomersIds?: string[];

  excludedTagsIds?: string[];

  isPackExcluded?: boolean;
}
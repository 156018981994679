import { SvgBin, TextInput, Wizard } from '@app/components';
import { Dropdown, DropdownDelete } from '@app/components/form/Dropdown';
import { PriceForm } from '@app/components/form/PriceForm';
import { AccountCodeSelect } from '@app/components/form/selects/features/AccountCodeSelect';
import { IngredientSelect } from '@app/components/form/selects/features/IngredientSelect';
import { VariationSelect } from '@app/components/form/selects/features/VariationSelect';
import { IProductVariation, IVariation } from '@tickeat/common';
import React, { Fragment } from 'react';
import { useWatch } from 'react-hook-form';
import LinearGradient from 'react-native-linear-gradient';

interface ProductVariationFormProps {
  index: number;
  skip?: boolean;
  isUnitary?: boolean;
  isWizard?: boolean;
  field: any;
  remove: (index: number) => void;
}

const ProductVariationKitchenName = ({ index }) => {
  return (
    <TextInput
      name={`productVariations.${index}.kitchenName`}
      label="Nom cusine"
      autoCapitalize="none" />
  )
}

const ProductVariationDescription = ({ index }) => {
  return (
    <TextInput
      name={`productVariations.${index}.description`}
      label="Description de la déclinaison"
      autoCapitalize="none" />
  )
}

const ProductVariationVariation = ({ index }) => {
  return <VariationSelect name={`productVariations.${index}.variation`} />
}

const ProductVariationAccountCode = ({ index }) => {
  return <AccountCodeSelect name={`productVariations.${index}.accountCode`} />
}

const ProductVariationIngredient = ({ index }) => {
  return (
    <IngredientSelect
      name={`productVariations.${index}.productVariationToIngredients`}
      transform={{
        value: (item) => ({ ingredient: item, quantity: 1 }),
        accessor: (item) => (item.ingredient?._id),
        display: (item) => (item?.ingredient?.name)
      }}
      isMultiple />
  )
}

const ProductVariationPrices = ({ index }) => {
  return (
    <PriceForm
      label='Tarifs'
      names={{
        price: `productVariations.${index}.price`,
        tax: `productVariations.${index}.tax`,
        takeAwayPrice: `productVariations.${index}.takeAwayPrice`,
        takeAwayTax: `productVariations.${index}.takeAwayTax`,
        deliveryPrice: `productVariations.${index}.deliveryPrice`,
        deliveryTax: `productVariations.${index}.deliveryTax`,
        onSpotPrice: `productVariations.${index}.onSpotPrice`,
        onSpotTax: `productVariations.${index}.onSpotTax`,
      }} />
  )
}

export const ProductVariationForm = ({ index, isWizard = false, field, remove, isUnitary }: ProductVariationFormProps) => {
  if (!isUnitary && !isWizard) {
    return <ProductVariationDeclinableForm index={index} field={field} remove={remove} />
  }

  if (!isUnitary && isWizard) {
    return <ProductVariationDeclinableWizardForm index={index} remove={remove} />
  }

  return (
    <Fragment>
      <Wizard.Step id="productVariation-name" title='Nom cusine' helpText="Déclinaison">
        <ProductVariationKitchenName index={index} />
      </Wizard.Step>

      <Wizard.Step id="productVariation-description" title='Description de la déclinaison' helpText="Déclinaison">
        <ProductVariationDescription index={index} />
      </Wizard.Step>

      <Wizard.Step id="productVariation-accountCode" title='Code comptable' helpText="Code comptable">
        <ProductVariationAccountCode index={index} />
      </Wizard.Step>

      <Wizard.Step id="productVariation-productVariationToIngredients" title='Ingrédients' helpText="Ingrédients">
        <ProductVariationIngredient index={index} />
      </Wizard.Step>

      <Wizard.Step id="productVariation-prices" title='Prix' helpText="Prix">
        <ProductVariationPrices index={index} />
      </Wizard.Step>
    </Fragment>
  )
}
interface ProductVariationDeclinableWizardForm extends Pick<ProductVariationFormProps, 'index' | 'remove'> { }

const ProductVariationDeclinableWizardForm = ({ index, remove }: ProductVariationDeclinableWizardForm) => {
  return (
    <Fragment>

      <Wizard.Step
        id={`productVariations-${index}`}
        title={(
          <DropdownDelete onPress={() => remove(index)}>
            <SvgBin color="#fff" />
            <LinearGradient
              style={{ flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}
              start={{ x: 0, y: 0.75 }}
              end={{ x: 1, y: 0.25 }}
              locations={[0, .7]}
              colors={['rgba(2,24,43,0.13)', 'rgba(2,24,43,0)']} >
            </LinearGradient>
          </DropdownDelete>
        )}
        helpText="Informations de la déclinaison">

        <ProductVariationKitchenName index={index} />

        <ProductVariationDescription index={index} />

        <ProductVariationVariation index={index} />

        <ProductVariationAccountCode index={index} />

        <ProductVariationIngredient index={index} />

        <ProductVariationPrices index={index} />

      </Wizard.Step>

    </Fragment>
  )
}

interface ProductVariationDeclinableFormProps extends Pick<ProductVariationFormProps, 'index' | 'field' | 'remove'> { }

const ProductVariationDeclinableForm = ({ index, field, remove }: ProductVariationDeclinableFormProps) => {
  const variation: IVariation = useWatch({
    name: `productVariations.${index}.variation`
  });

  return (
    <Dropdown
      label={(field as unknown as IProductVariation)?.variation?.name ?? variation?.name ?? `Déclinaison ${index + 1}`}
      onDelete={() => remove(index)}>

      <ProductVariationKitchenName index={index} />

      <ProductVariationDescription index={index} />

      <ProductVariationVariation index={index} />

      <ProductVariationAccountCode index={index} />

      <ProductVariationIngredient index={index} />

      <ProductVariationPrices index={index} />
    </Dropdown>
  )
}

import React from 'react';
import { MainLayoutHeader, MainLayoutHeaderProps } from '@app/components';

export const PackHeader = ({ onPress, buttonTitle }: Pick<MainLayoutHeaderProps, 'buttonTitle' | 'onPress'>) => {
  return (
    <MainLayoutHeader
      tabs={[
        { label: 'Menus', screenName: 'PackScreen' },
        { label: 'Etapes', screenName: 'StepScreen' },
      ]}
      onPress={onPress}
      buttonTitle={buttonTitle} />
  );
};

import { TextInput } from '@app/components';
import { PriceInput } from '@app/components/form/PriceInput';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

export const IngredientForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      {/* <TextInput
        name="unit"
        label="Unité"
        autoCapitalize="none" />

      <TextInput
        name="bundle"
        label="Lot"
        autoCapitalize="none" />

      <PriceInput
        name="price"
        placeholder="Prix"
        autoCapitalize="none" /> */}

    </StyledForms.Form>
  );
};

import { TextInput } from '@app/components';
import { NumberSelector } from '@app/components/form/NumberSelector';
import { Toggle } from '@app/components/form/Toggle';
import { ProductSelect } from '@app/components/form/selects/features/ProductSelect';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

export const OptionForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <Toggle
        name='disabled'
        options={[
          { title: "Actif", value: false },
          { title: "Inactif", value: true },
        ]} />

      <Toggle
        name='mandatory'
        options={[
          { title: "Obligatoire", value: true },
          { title: "Facultative", value: false },
        ]} />

      <ProductSelect name='products' isMultiple />

      <NumberSelector name='maxChoiceCount' label='Nombre max' />

      <NumberSelector name='position' label='Position' />

      {/* <NumberSelector name='freeQuantity' label='Quantité de produit offert' /> */}

    </StyledForms.Form>
  );
};

import { combineReducers, configureStore, PreloadedState, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { authSlice } from './services/auth';
import { rootApi, rootMiddleware } from './api';
import { modalSlice } from './slices/modal.slice';
import { triptychSlice } from './slices/triptych.slice';
import { toasterSlice } from './slices/toaster.slice';
import { setupListeners } from '@reduxjs/toolkit/query/react';

declare const module: any;

const reducer = {
  [rootApi.reducerPath]: rootApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [triptychSlice.name]: triptychSlice.reducer,
  [toasterSlice.name]: toasterSlice.reducer,
};

const reducerCombined = combineReducers(reducer);

export type RootState = StateFromReducersMapObject<typeof reducer>;

export function configureAppStore(preloadedState?: PreloadedState<RootState>) {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([rootApi.middleware, rootMiddleware.middleware]),
  });

  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(reducerCombined);
    });
  }

  setupListeners(store.dispatch);

  return store;
}

type Store = ReturnType<typeof configureAppStore>;
export type AppDispatch = Store['dispatch'];

import { TextInput } from '@app/components';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

export const AccountCodeForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

    </StyledForms.Form>
  );
};

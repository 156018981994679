import React, { Fragment } from 'react';
import styled from '@2pulse/responsive';
import { extractFormError, normalize, withComponents } from '@app/helpers';
import { row, worksansoxford } from '@app/style';
import { SvgBin } from '../../icons/SvgBin';
import SvgArrowDown from '../../icons/SvgArrowDown';
import { useFormContext } from 'react-hook-form';
import { WithSizesProps, WithTextProps } from '@app/types';
import { ColumnDef, flexRender, getCoreRowModel, Row, Table, useReactTable } from '@tanstack/react-table';
import { FlatList, FlatListProps, Modal, View } from 'react-native';
import { IBase } from '@tickeat/common';
import { Button } from '../buttons/Button';
import { SvgMenuSearch } from '@app/components/icons';
import { isDefined, isFunction } from '@app/helpers/kindOf';
import { StyledInputs } from '@app/components/styled/form/Inputs';
import { StyledForms } from '@app/components/styled/form/Form';

const ListHeader = styled.View({
  ...row,
  paddingBottom: normalize(21),
  paddingRight: normalize(34),
});

const ListHeaderItem = styled.Text<WithSizesProps & WithTextProps>(({ theme, props }) => ({
  fontFamily: 'Work Sans',
  flexShrink: 0,
  flexGrow: 0,
  width: props.width,
  color: theme.colors.babypowder,
  opacity: 0.8,
  fontSize: normalize(18),
  textAlign: props.align,
  paddingLeft: normalize(34),
}));

const ListItem = styled.TouchableOpacity<{ isActive?: boolean, compact?: boolean }>(({ theme, props }) => ({
  ...row,
  alignItems: 'center',
  height: props.compact ? 55 : 80,
  marginBottom: 2,
  paddingRight: normalize(34),
  backgroundColor: props.isActive ? theme.colors.celadonblue : theme.colors.translucid,
}));

const ListItemView = styled.View<{ isActive?: boolean, compact?: boolean }>(({ theme, props }) => ({
  ...row,
  alignItems: 'center',
  height: props.compact ? 55 : 80,
  marginBottom: 2,
  paddingRight: normalize(34),
  backgroundColor: props.isActive ? theme.colors.celadonblue : theme.colors.translucid,
}));

const ListItemCol = styled.View<WithSizesProps>(({ props }) => ({
  flexShrink: 0,
  flexGrow: 0,
  width: props.width,
  paddingLeft: normalize(34),
}));

const ListBody = styled.View({
  width: "100%",
  height: '100%',
  // paddingHorizontal: '21%',
});

const ListSearch = styled.View({
  width: "100%",
  height: normalize(58),
  flexDirection: 'row',
  alignItems: 'center',
  gap: 13,
  marginBottom: normalize(13),
  paddingBottom: normalize(13),
});
const ListSearchIcon = styled.View({
  height: 34,
});

const ListItemText = styled.Text<WithTextProps>(({ theme, props }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(16),
  textAlign: props.align,
  fontWeight: props.bold ? '700' : '400',
}));

const SelectContainer = styled.View<{ small?: boolean }>(({ props }) => ({
  ...row,
  height: props.small ? 58 : 76,
}));

const SelectTrigger = styled.TouchableOpacity<{ small?: boolean, center?: boolean, inside?: boolean, deletable?: boolean, bordered?: boolean }>(({ theme, props }) => ({
  ...row,
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  flexGrow: 1,
  borderRadius: props.inside ? 0 : 5,
  borderTopRightRadius: props.deletable ? 0 : 5,
  borderBottomRightRadius: props.deletable ? 0 : 5,
  backgroundColor: props.small ? theme.colors.oxfordblue : 'rgba(240, 249 , 252, .04)',
  paddingLeft: props.center ? 0 : props.small ? normalize(21) : normalize(34),
  paddingRight: props.center ? 0 : props.small ? normalize(21) : normalize(21),
  alignItems: 'center',
  justifyContent: props.center ? 'center' : 'space-between',
  borderWidth: props.bordered ? normalize(2) : 0,
  borderColor: "#00BEE6",
  borderRightWidth: props.deletable ? 0 : normalize(2),
  borderRightColor: props.bordered ? "#00BEE6" : "transparent",
}));

const SelectLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  fontSize: normalize(21),
  fontWeight: '500',
  color: theme.colors.babypowder,
}));

const SelectToggle = styled.View({
  width: normalize(34),
  height: normalize(34),
});

const SelectDelete = styled.TouchableOpacity<{ inside?: boolean, bordered?: boolean }>(({ theme, props }) => ({
  width: normalize(21 + 34 + 21),
  paddingHorizontal: normalize(21),
  height: '100%',
  borderTopRightRadius: props.inside ? 0 : 5,
  borderBottomRightRadius: props.inside ? 0 : 5,
  backgroundColor: 'rgba(240, 249 , 252, .04)',
  borderWidth: props.bordered ? normalize(2) : 0,
  borderColor: "#00BEE6",
  borderLeftWidth: normalize(1),
  borderLeftColor: theme.colors.oxfordblue,
}));

type SelectOptionProps = {
  isOpen: boolean;
};

export const SelectOptionsContainer = styled.View<SelectOptionProps>(({ props }) => ({
  display: props.isOpen ? 'flex' : 'none',
  zIndex: 999999,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  paddingVertical: '13%',
  backgroundColor: 'rgba(2,24,43,1)',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SelectOptionsTitle = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(55),
  fontWeight: '800',
  paddingBottom: normalize(55),
}));

export const SelectOptionsSubTitle = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(21),
  fontWeight: '400',
  alignSelf: 'flex-start',
  paddingBottom: normalize(21),
  // textAlign: 'center',
}));

export const SelectOptionsMessage = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.babypowder,
  fontSize: normalize(18),
  fontWeight: '600',
  textAlign: 'center',
  lineHeight: normalize(28),
}));

export const SelectOptions = styled.ScrollView({
  display: 'flex',
  width: '55%',
  maxHeight: '55%',
  height: 'auto',
  flexGrow: 0,
  overflow: 'hidden'
});

export const SelectOptionsCols = styled.View({
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '100%',
  // height: '100%',
  width: '100%',
  // backgroundColor: 'rgba(255,255,255,0.2)',
  justifyContent: 'center',
  paddingHorizontal: '13%',
  overflow: 'hidden',
  paddingBottom: normalize(34),
});

export const SelectOptionsResults = styled.View({
  flexGrow: 1,
  alignItems: 'center',
  paddingRight: '8%',
});

export const SelectOptionsSelection = styled.View({
  flexGrow: 1,
  maxWidth: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SelectOptionsSelectionList = styled.ScrollView({
  // flexGrow: 1,
  width: '100%',
  gap: normalize(2),
});

export const SelectOption = styled.TouchableOpacity(({ theme }) => ({
  fontFamily: 'Work Sans',
  backgroundColor: theme.colors.babypowder,
  borderRadius: normalize(5),
  marginVertical: normalize(13),
  height: normalize(60),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SelectOptionLabel = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  color: theme.colors.oxfordblue,
  fontSize: normalize(18),
  fontWeight: '600',
}));

export const SelectOptionsClose = styled.TouchableOpacity({});

export const SelectOptionsCloseLabel = styled.Text({
  marginTop: normalize(34),
  height: 67,
});

export interface BaseSelectProps<T> extends Omit<FlatListProps<T>, 'data' | 'keyExtractor' | 'renderItem'> {
  name: string;
  table: Table<T>;
  label?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formattedLabel: string;
  onReset?: () => void,
  onSelect: (item: T) => void;
  isActive: (item: T) => boolean;
  transform?: {
    value: (item: T) => any; // Transform the value for field.onChange
    accessor: (item: any) => any; // How to access to the field value
    display?: (item: any) => any; // How to display the "name" of the field
  }
  onRemove?: (item: any) => void;
  onSearch?: (search: string) => void;
  render: (item: T, index: number) => any
  center?: boolean;
  inside?: boolean;
  bordered?: boolean;
  small?: boolean;
  values: any[];
  selectedColumns?: ColumnDef<any, any>[];
}

export const BaseSelect = withComponents(<T extends IBase,>({
  name,
  isOpen,
  setIsOpen,
  isActive,
  onSelect,
  onSearch,
  onReset,
  render,
  formattedLabel,
  transform,
  label,
  table,
  values,
  selectedColumns,
  onRemove = (item: T) => { },
  ...props
}: BaseSelectProps<T>) => {
  const { formState } = useFormContext();

  const error = extractFormError(formState.errors, name);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  }

  const onClose = () => {
    setIsOpen(false);
  }

  const tableSelected = useReactTable({
    data: values,
    columns: selectedColumns || [],
    getCoreRowModel: getCoreRowModel(),
  });

  // TODO: Update all selects
  const isLegacy = !isDefined(selectedColumns);

  return (
    <SelectContainer {...props} >

      {error && (
        <StyledForms.Error>
          <StyledForms.ErrorLabel>
            {error.message}
          </StyledForms.ErrorLabel>
        </StyledForms.Error>
      )}

      <SelectTrigger onPress={toggle} {...props}>
        <SelectLabel>{formattedLabel}</SelectLabel>
        <SelectToggle>
          <SvgArrowDown color="#fff" />
        </SelectToggle>
      </SelectTrigger>

      <Modal visible={isOpen} >

        <SelectOptionsContainer isOpen={isOpen}>

          <SelectOptionsTitle>{label}</SelectOptionsTitle>
          <SelectOptionsCols>
            <SelectOptionsResults>
              <ListBody>

                {isFunction(onSearch) && (
                  <ListSearch>
                    <ListSearchIcon><SvgMenuSearch strokePath='#FFF' /></ListSearchIcon>
                    <StyledInputs.Holder>
                      <StyledInputs.Input
                        size='small'
                        placeholder="Cherchez dans la liste..."
                        onChangeText={onSearch}
                        placeholderTextColor="white"
                        autoCapitalize="none" />
                    </StyledInputs.Holder>
                  </ListSearch>
                )}

                <ListHeader>
                  {table.getFlatHeaders().map(header => (
                    <React.Fragment key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </React.Fragment>
                  ))}
                </ListHeader>

                <FlatList
                  {...props as any}
                  onEndReachedThreshold={1}
                  data={table.getRowModel().rows}
                  keyExtractor={(item: Row<T>) => item.id}
                  renderItem={({ item }: { item: Row<T> }) => {
                    return <ListItem isActive={isActive(item.original)} onPress={() => onSelect(item.original)}>
                      {item.getVisibleCells().map(cell => (
                        <React.Fragment key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </React.Fragment>
                      ))}
                    </ListItem>
                  }}
                />

              </ListBody>
            </SelectOptionsResults>

            <SelectOptionsSelection>
              <SelectOptionsSubTitle>Votre sélection</SelectOptionsSubTitle>

              {!values.length && <SelectOptionsMessage>
                Vous n'avez pas encore choisi d'éléments dans la liste de gauche.
              </SelectOptionsMessage>}

              {isLegacy
                ? (
                  <SelectOptionsSelectionList>
                    {values.map((item: T & { id: string }, index) => render(item, index))}
                  </SelectOptionsSelectionList>
                ) : (
                  <Fragment>
                    {!!values.length && (
                      <View style={{ width: '100%' }}>
                        <ListHeader>
                          {tableSelected.getFlatHeaders().map(header => (
                            <React.Fragment key={header.id}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </React.Fragment>
                          ))}
                        </ListHeader>
                      </View>
                    )}

                    <SelectOptionsSelectionList>

                      <FlatList
                        {...props as any}
                        onEndReachedThreshold={1}
                        data={tableSelected.getRowModel().rows}
                        keyExtractor={(item: Row<T>) => item.id}
                        renderItem={({ item }: { item: Row<T> }) => (
                          <ListItemView isActive compact={false}>
                            <Button onPress={() => onRemove(item.original)} title='X' style={{ backgroundColor: 'transparent' }} />

                            {item.getVisibleCells().map(cell => (
                              <React.Fragment key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </React.Fragment>
                            ))}
                          </ListItemView>
                        )}
                      />

                    </SelectOptionsSelectionList>
                  </Fragment>
                )}

            </SelectOptionsSelection>

          </SelectOptionsCols>

          <SelectOptionsClose>
            <SelectOptionsCloseLabel>
              <Button title="Fermer" grow large bordered onPress={onClose} />
            </SelectOptionsCloseLabel>
          </SelectOptionsClose>

        </SelectOptionsContainer>
      </Modal>
      {isFunction(onReset) && (
        <SelectDelete onPress={onReset} {...props}>
          <SvgBin color="#fff" />
        </SelectDelete>
      )}
    </SelectContainer >
  );
}, {
  ListBody,
  ListHeader,
  ListHeaderItem,
  ListItem,
  ListItemCol,
  ListItemText
});

import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IVariation } from "@tickeat/common";

interface VariationDeleteModalProps extends ModalInstance<IVariation>, Omit<DeleteModalProps<IVariation>, 'children'> {
  onDelete: (product: Partial<IVariation>) => void;
}

export const VariationDeleteModal = (props: VariationDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer une variation.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible.
      </Modal.Message>
    </DeleteModal>
  )
}
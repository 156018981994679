import { BaseSelect, Select, SelectProps } from '@app/components';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Allergens, getAllergenFR } from '@tickeat/common';
import React from 'react';

const columnHelper = createColumnHelper<Allergens>();

// TODO: Remplacer cette méthode par l'input attendu ( un objet ) via transform

// React Hook form oblige les fieldArrays à être des objets et non des string afin
// d'ajouter une propriété "id" sur chaque value. Sans ça, la value devient un objet destructuré + le champ id.
// Ici, je join la string destructuré sans l'id.
const destructuredReactHookFormValueToString = (item: any): any => {
  const arr = Object.values(item);

  arr.splice(-1);

  return arr.join('');
}

export const AllergenSelect = ({ label = 'Allergènes', ...props }: Omit<SelectProps<any>, 'table'>) => {
  const columns = [
    columnHelper.display({
      id: 'allergen',
      header: () => (
        <BaseSelect.ListHeaderItem width="100%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => {
        return <BaseSelect.ListItemCol width="100%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {getAllergenFR(info.row.original)}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      },
    }),
  ];

  const table = useReactTable({
    data: Object.values(Allergens) as Allergens[],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Select
      label={label}
      table={table}
      transform={{
        accessor: (item) => {
          if (typeof item === 'string') {
            return item;
          }

          return destructuredReactHookFormValueToString(item);
        },
        value: (item) => item,
        display: (item) => getAllergenFR(destructuredReactHookFormValueToString(item))
      }}
      {...props} />
  );
};

import { TextInputProps as RNTextInputProps } from 'react-native';
import { memo, useEffect, useState } from "react";
import { StyledInputs, StyledTextInputProps } from "../styled/form/Inputs";
import { StyledForms } from "../styled/form/Form";
import { useInput } from "@app/hooks/useInput";

export interface TimeSlotInputProps extends RNTextInputProps, StyledTextInputProps {
    name: string;
    showError?: boolean;
}

const timeSlotRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9] - ([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const TimeSlotInput = memo(({ name, showError = true, ...props }: TimeSlotInputProps) => {
    const { field, error } = useInput(name);

    const [value, setValue] = useState<string | null>(field.value || null);

    //   useEffect(() => {
    //     setValue(field.value || null);
    //   }, [field.value]);

    //   const onChangeText = (text: string) => {
    //     if (text.match(timeSlotRegex)) {
    //       setValue(text);
    //       field.onChange(text);
    //     }
    //   }

    return (
        <StyledInputs.Holder>
            {(!!error && showError) && (
                <StyledForms.Error>
                    <StyledForms.ErrorLabel>
                        {error}
                    </StyledForms.ErrorLabel>
                </StyledForms.Error>
            )}

            <StyledInputs.Input
                // onChangeText={onChangeText}
                onBlur={field.onBlur}
                value={value as string}
                keyboardType='default'
                editable={false}
                // ref={field.ref}
                {...props} />
        </StyledInputs.Holder>
    );
});
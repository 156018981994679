import { Transform, Type } from "class-transformer";
import { ArrayMinSize, IsArray, IsBoolean, IsInt, IsNotEmpty, IsOptional, Min, ValidateNested } from "class-validator";
import { IAccountCode, IBase, IProductVariation, IProductVariationToIngredient, ITax, IVariation } from "../../interfaces";
import { TypeORMRelationId } from "../utils.dto";
import { AccountCodeDTO } from "./account-code.dto";
import { ProductVariationToIngredientDTO } from "./product-variation-to-ingredient.dto";

export class ProductVariationDTO implements Omit<IProductVariation, keyof IBase | 'product' | 'productId' | 'imageUrl' | 'accountCodeId' | 'variationId' | 'taxId' | 'deliveryTaxId' | 'takeAwayTaxId' | 'onSpotTaxId'> {
  @IsOptional()
  name: string;

  @IsOptional()
  kitchenName: string;

  @IsOptional()
  description: string;

  @IsNotEmpty({ message: 'Le prix de base est requis' })
  @Transform((value) => typeof value === 'string' ? +value : value)
  @Min(0, { message: 'Le prix de base doit être supérieur ou égal à 0' })
  price: number;

  @IsNotEmpty({ message: 'La taxe de base est requise' })
  @Type(() => TypeORMRelationId)
  tax: ITax;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  deliveryPrice: number;

  @IsOptional()
  @Type(() => TypeORMRelationId)
  deliveryTax: ITax;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  takeAwayPrice: number;

  @IsOptional()
  @Type(() => TypeORMRelationId)
  takeAwayTax: ITax;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  onSpotPrice: number;

  @IsOptional()
  @Type(() => TypeORMRelationId)
  onSpotTax: ITax;

  @IsOptional()
  @Transform(value => +value)
  @IsInt({ message: 'Le nombre de point offert doit être un nombre entier' })
  @Min(0, { message: 'Le nombre de point offert doit être positif' })
  loyaltyPointOffered: number;

  @IsOptional()
  @Transform(value => !!value)
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsNotEmpty({ message: 'La taille est requise' })
  @Type(() => TypeORMRelationId)
  variation: IVariation;

  @IsOptional()
  @IsArray({ message: 'Au moins un ingrédient est requis' })
  @Type(() => ProductVariationToIngredientDTO)
  @ValidateNested({
    each: true,
    message: 'L\'ingrédient n\'est pas correct'
  })
  productVariationToIngredients: IProductVariationToIngredient[];

  @IsNotEmpty({ message: 'Le code est requis.' })
  @Type(() => AccountCodeDTO)
  @ValidateNested({
    message: 'Le code comptable est invalide'
  })
  accountCode: IAccountCode;

}

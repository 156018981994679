import { Dimensions, PixelRatio } from 'react-native';

export function normalize(size: number) {

  const {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
  } = Dimensions.get('window');

  const newSize = size * 1;

  if (SCREEN_WIDTH >= 1366 && SCREEN_HEIGHT >= 1024) {

    return Math.round(PixelRatio.roundToNearestPixel(newSize))

  } else {

    return Math.round(PixelRatio.roundToNearestPixel(newSize)) / 1.13

  }

}
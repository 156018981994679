import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { PackList, PackHeader, PackDeleteModal } from '@app/features/Pack';
import { PackCreate } from '@app/features/Pack/PackCreate';
import { PackUpdate } from '@app/features/Pack/PackUpdate';
import { useResourceScreenHelpers } from '@app/hooks';
import { packApi } from '@app/store';
import { IPack } from '@tickeat/common';

export const PackScreen = () => {
  const [readOne, { isFetching }] = packApi.useLazyReadOnePacksQuery();
  const [deleteOne, { isLoading }] = packApi.useDeleteOnePacksMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: pack } = useResourceScreenHelpers<IPack>();

  const openCard = (item: Partial<IPack>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
      populate: [
        'accountCode',
        'tax',
        'tags',
        'deliveryTax',
        'takeAwayTax',
        'onSpotTax',
        'catalogToPacks',
        'catalogToPacks.catalog',
        'packToSteps',
        'packToSteps.step',
        'packToProductVariations',
        'packToProductVariations.productVariation',
      ]
    }), item);
  }

  return (
    <MainLayout
      header={(
        <PackHeader
          buttonTitle='Ajouter un menu'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <PackDeleteModal
        {...modal}
        onDelete={(pack) => onDelete(deleteOne(pack._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <PackList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        form={(
          mode === 'CREATE'
            ? <PackCreate />
            : <PackUpdate
              pack={pack ?? selectedItem}
              toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.close()}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


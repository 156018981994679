import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { col } from '@app/style';
import { RootStackParamList } from '@app/types';
import { useNavigation, useNavigationState } from '@react-navigation/native';

type MenuItemProps = {
  label: string;
  Icon: (...args: any[]) => JSX.Element;
  routeName?: keyof RootStackParamList;
  activeOnRouteNames?: (keyof RootStackParamList)[];
};

export const MenuItem = ({ label, Icon, routeName, activeOnRouteNames = [] }: MenuItemProps) => {
  const navigation = useNavigation();
  const screenName = useNavigationState((state) => state?.routes[state.index]?.name) as keyof RootStackParamList;

  const isActive = (!!screenName && screenName === routeName || activeOnRouteNames.includes(screenName));

  const iconStyle = { strokePath: isActive ? '#0089C8' : '#FFF' };

  const goTo = () => {
    if (routeName) {
      navigation.navigate(routeName);
    }
  }

  return (
    <MenuItemHolder onPress={goTo}>
      <MenuIcon isActive={isActive}>
        <Icon {...iconStyle} />
      </MenuIcon>
      <MenuLabel>{label}</MenuLabel>
    </MenuItemHolder>
  );
};

export const Menu = styled.View({});

export const MenuItemHolder = styled.TouchableOpacity(({ theme }) => ({
  ...col,
  justifyContent: 'center',
  alignItems: 'center',
  paddingVertical: normalize(27),
}));

export const MenuIcon = styled.View<{ isActive?: boolean }>(({ props }) => ({
  height: props.isActive ? normalize(51) : normalize(34),
  width: props.isActive ? normalize(51) : normalize(34),
}));

export const MenuLabel = styled.Text(({ theme }) => ({
  display: 'none',
}));

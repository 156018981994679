import styled from '@2pulse/responsive';
import { col, row, worksansbaby } from '@app/style';
import { normalize, withComponents } from '@app/helpers';
import { theme } from '@app/theme';

const Container = styled.View(({ theme }) => ({
  ...row,
  width: '100%',
  height: '100%',
  flexShrink: 1,
  columnGap: normalize(21),
  paddingBottom: normalize(34),
}));

const ProductList = styled.View(({ theme }) => ({
  width: '40%',
  flexGrow: 0,
  flexShrink: 0,
}));

const List = styled.ScrollView(({ theme }) => ({
  marginTop: normalize(34),
}));

const ListItem = styled.Pressable(({ theme }) => ({
  ...row,
  width: '100%',
  height: normalize(62),
  paddingHorizontal: normalize(21),
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.translucid,
  borderRadius: normalize(5),
}));

const ListItemName = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontSize: normalize(16),
  fontWeight: '500',
}));

const ListItemCategory = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontSize: normalize(14),
  paddingRight: normalize(34),
}));

const ListItemIcon = styled.View(({ theme }) => ({
  width: normalize(28),
  height: normalize(28),
}));

const SelectedItem = styled.View(({ theme }) => ({
}));

const SelectedItemTrigger = styled.Pressable<{ isOpen?: boolean }>(({ theme, props }) => ({
  ...row,
  width: '100%',
  height: normalize(62),
  paddingHorizontal: normalize(21),
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: props.isOpen ? theme.colors.celadonblue : theme.colors.translucid,
  borderTopLeftRadius: normalize(5),
  borderTopRightRadius: normalize(5),
  borderBottomLeftRadius: props.isOpen ? 0 : normalize(5),
  borderBottomRightRadius: props.isOpen ? 0 : normalize(5),
}));

const SelectedItemIcon = styled.Pressable(({ theme }) => ({
  width: normalize(28),
  height: normalize(28),
}));

const SelectedItemName = styled.Text<{ isOpen?: boolean }>(({ theme, props }) => ({
  ...worksansbaby,
  fontSize: normalize(16),
  fontWeight: props.isOpen ? '700' : '500',
  paddingLeft: normalize(21),
}));

const SelectedItemCategory = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontSize: normalize(14),
  textAlign: 'right',
}));

const SelectedItemDivider = styled.View(({ theme }) => ({
  width: 1,
  backgroundColor: theme.colors.oxfordblue,
  marginHorizontal: 13,
  height: normalize(62),
}));

const SelectedItemDropdown = styled.View<{ isOpen?: boolean }>(({ theme, props }) => ({
  height: props.isOpen ? 'auto' : 0,
  borderColor: theme.colors.celadonblue,
  borderWidth: props.isOpen ? normalize(2) : 0,
  borderTopWidth: 0,
  borderBottomLeftRadius: normalize(5),
  borderBottomRightRadius: normalize(5),
  rowGap: 1,
}));

const DropdownHeader = styled.View(({ theme }) => ({
  ...row,
  paddingVertical: normalize(5),
  paddingLeft: normalize(21),
  paddingRight: normalize(66),
  backgroundColor: '#02304C',
}));

const DropdownHeaderName = styled.Text(({ theme }) => ({
  fontSize: normalize(12),
  ...worksansbaby,
  fontWeight: '300',
  opacity: .8,
  flexGrow: 1,
}));

const DropdownHeaderItem = styled.Text(({ theme }) => ({
  fontSize: normalize(12),
  ...worksansbaby,
  fontWeight: '300',
  opacity: .8,
  width: normalize(65),
  marginHorizontal: normalize(5),
  flexShrink: 0,
  textAlign: 'center',
}));

const DropdownItem = styled.View(({ theme }) => ({
  ...row,
  alignItems: 'center',
  height: normalize(62),
  paddingLeft: normalize(21),
  backgroundColor: theme.colors.translucid,
}));

const DropdownItemName = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontSize: normalize(16),
  fontWeight: '500',
  width: '33%',
  overflow: 'hidden',
}));

const DropdownItemPrices = styled.View(({ theme }) => ({
  ...row,
  paddingRight: normalize(9),
}));

const DropdownItemPrice = styled.View<{ isChanged?: boolean }>(({ theme, props }) => ({
  width: normalize(65),
  marginHorizontal: normalize(5),
  flexShrink: 0,
  flexGrow: 0,
  opacity: props.isChanged ? 1 : 0.6,
  borderBottomColor: props.isChanged ? theme.colors.gold : 'transparent',
  borderBottomWidth: 2,
  alignItems:'center',
  alignContent:'center',
  justifyContent: 'center',
}));

const DropdownItemPriceLabel = styled.Text(({ theme, props }) => ({
  ...worksansbaby,
  fontSize: normalize(16),
  fontWeight: '500',
  textAlign: 'center',
  paddingBottom: 2,
}));

const DropdownItemAction = styled.TouchableOpacity(({ theme }) => ({
  width: normalize(55),
  flexGrow:1,
  alignItems: "center",
  justifyContent:'center'
}));

const Divider = styled.View(({ theme }) => ({
  ...col,
  width: 144,
  flexGrow: 1,
  alignItems: 'center',
}));

const DividerHeader = styled.View(({ theme }) => ({
  height: 40,
  width: 40,
}));

const Categories = styled.ScrollView(({ theme }) => ({
  ...col,
  rowGap: normalize(13),
  marginTop: normalize(34),
}));

const Actions = styled.View(({ props }) => ({
  ...row,
  justifyContent: 'space-between',
  height: '10%',
}));

export const CardComposer = withComponents(styled.View({
  width: '100%',
  height: '100%',
  paddingLeft: theme.sizes.sidebarWidth + normalize(55),
  paddingRight: normalize(55),
}), {
  Container,
  ProductList,
  Divider,
  List,
  ListItem,
  ListItemName,
  ListItemCategory,
  ListItemIcon,
  SelectedItem,
  SelectedItemTrigger,
  SelectedItemName,
  SelectedItemCategory,
  SelectedItemIcon,
  SelectedItemDivider,
  SelectedItemDropdown,
  DropdownHeader,
  DropdownHeaderName,
  DropdownHeaderItem,
  DropdownItem,
  DropdownItemName,
  DropdownItemPrices,
  DropdownItemPrice,
  DropdownItemPriceLabel,
  DropdownItemAction,
  DividerHeader,
  Categories,
  Actions,
})
import { rootApi, rootMiddleware } from '../api';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { ILogin, ILoginResponse, IRefreshTokenResponse } from '@app/types';
import { RootState } from '../store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { IUser } from '@tickeat/common';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILogin>({
      query: (body) => ({
        url: 'auth',
        method: 'POST',
        body,
      }),
    }),
    me: builder.query<IUser, void>({
      query: () => 'auth/me',
    }),
  }),
});

type State = {
  accessToken: string | null;
  refreshToken: string | null;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
  } as State,
  reducers: {
    setInitialState(state, action: PayloadAction<State>) {
      state.accessToken = action.payload?.accessToken ?? null;
      state.refreshToken = action.payload?.refreshToken ?? null;
    },
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
    },
    setRefreshToken(state, action: PayloadAction<ILoginResponse>) {
      state.refreshToken = action.payload.refreshToken;
    },
    setAccessToken(state, action: PayloadAction<ILoginResponse | IRefreshTokenResponse>) {
      state.accessToken = action.payload.accessToken;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      authSlice.caseReducers.setAccessToken(state, action);
      authSlice.caseReducers.setRefreshToken(state, action);
    });
  },
});

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectIsAuthenticated = (state: RootState) => !!state.auth.accessToken;

rootMiddleware.startListening({
  matcher: authSlice.actions.logout.match,
  effect: async (_, api) => {
    api.dispatch(rootApi.util.resetApiState());

    await AsyncStorage.removeItem(ACCESS_TOKEN);
    await AsyncStorage.removeItem(REFRESH_TOKEN);
  },
});

rootMiddleware.startListening({
  matcher: isAnyOf(
    authApi.endpoints.login.matchFulfilled,
    authSlice.actions.setAccessToken.match,
    authSlice.actions.setRefreshToken.match,
  ),
  effect: async (action) => {
    const { accessToken, refreshToken } = action.payload;

    if (accessToken) {
      await AsyncStorage.setItem(ACCESS_TOKEN, accessToken);
    }

    if (refreshToken) {
      await AsyncStorage.setItem(REFRESH_TOKEN, refreshToken);
    }
  },
});

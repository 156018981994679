import { IStep } from "./step.interface";
import { IProductVariation } from "./product-variation.interface";

export interface IStepToProductVariation {
  position: number;

  price: number;

  hasOptions?: boolean;

  productVariation: IProductVariation;

  productVariationId?: string;

  step?: IStep;
}
import { ViewStyle } from 'react-native';
import styled from '@2pulse/responsive';
import { col, row } from '@app/style';

type FlexOptions = {
  justify?: ViewStyle['justifyContent'];
  align?: ViewStyle['alignItems'];
  wrap?: boolean
};

export const Row = styled.View<FlexOptions>(({ props }) => ({
  ...row,
  justifyContent: props.justify,
  alignItems: props.align,
  flexWrap: props.wrap ? 'wrap' : 'nowrap',
}));

export const Col = styled.View<FlexOptions>(({ props }) => ({
  ...col,
  justifyContent: props.justify,
  alignItems: props.align,
  flexWrap: props.wrap ? 'wrap' : 'nowrap',
}));

import { IsNotEmpty, IsNumber } from "class-validator";
import { IBase, ITax } from "../../interfaces";

export class TaxDTO implements Omit<ITax, keyof IBase> {
  @IsNotEmpty({ message: 'Le nom de la taxe est requis' })
  name: string;

  @IsNotEmpty({ message: 'Le taux de la taxe est requis' })
  rate: number;

  @IsNotEmpty({ message: 'Le code comptable TVA est requis' })
  @IsNumber({}, { message: 'Le code comptable TVA n\'est pas au bon format' })
  codeVAT: number;

  @IsNotEmpty({ message: 'Le nom comptable TVA est requis' })
  nameVAT: string;

  @IsNotEmpty({ message: 'Le code comptable CA HT est requis' })
  @IsNumber({}, { message: 'Le code comptable CA HT n\'est pas au bon format' })
  salesCodeHT: number;

  @IsNotEmpty({ message: 'Le nom comptable du CA HT est requis' })
  salesNameHT: string;
}

import React from "react";

export const ACTIONS = {
  ADD_STEP: 'ADD_STEP',
  UPDATE_STEP: 'UPDATE_STEP',
  REMOVE_STEP: 'REMOVE_STEP',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  GO_TO_STEP: 'GO_TO_STEP',
} as const;

export type WizardState = {
  currentStep: number;
  steps: Array<{
    id: string;
    nodes: React.ReactNode[];
    title: string | React.ReactNode;
    helpText: string;
  }>
};

export type AddStepPayload = {
  id: string;
  title: string | React.ReactNode;
  helpText: string;
  nodes: React.ReactNode[];
}

export type UpdateStepPayload = {
  id: string;
  title: string | React.ReactNode;
  helpText: string;
}

export type RemoveStepPayload = {
  id: string;
}

export type GoToStepPayload = {
  step: number;
}

export type ACTION_TYPES =
  | { type: (typeof ACTIONS)['ADD_STEP']; payload: AddStepPayload }
  | { type: (typeof ACTIONS)['UPDATE_STEP']; payload: UpdateStepPayload }
  | { type: (typeof ACTIONS)['REMOVE_STEP']; payload: RemoveStepPayload }
  | { type: (typeof ACTIONS)['NEXT_STEP']; payload: null }
  | { type: (typeof ACTIONS)['PREVIOUS_STEP']; payload: null }
  | { type: (typeof ACTIONS)['GO_TO_STEP']; payload: GoToStepPayload }

const addStep = (state: WizardState, payload: AddStepPayload): WizardState => {
  const { id, nodes, title, helpText } = payload;

  const existingStepIndex = state.steps.findIndex(step => step?.id === id);

  if (existingStepIndex !== -1) {
    return updateStep(state, payload);
  }

  state.steps.push({ id, nodes, title, helpText });

  return state;
}

const updateStep = (state: WizardState, payload: UpdateStepPayload) => {
  const { id, ...rest } = payload;

  const existingStepIndex = state.steps.findIndex(step => step.id === id);

  const existingStep = state.steps[existingStepIndex];

  state.steps[existingStepIndex] = {
    ...existingStep,
    ...rest
  }

  return state;
}

const removeStep = (state: WizardState, payload: RemoveStepPayload) => {
  const { id } = payload;

  const existingStepIndex = state.steps.findIndex(step => step.id === id);

  state.steps.splice(existingStepIndex, 1);

  return state;
}

const nextStep = (state: WizardState) => {
  if (state.currentStep < state.steps.length) {
    state.currentStep += 1;
  }

  return state;
}

const previousStep = (state: WizardState) => {
  if (state.currentStep > 1) {
    state.currentStep -= 1;
  }

  return state;
}

const goToStep = (state: WizardState, payload: GoToStepPayload) => {
  state.currentStep = payload.step;

  return state;
}

export function wizardReducer(state: WizardState, action: ACTION_TYPES): WizardState {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.ADD_STEP:
      return addStep(newState, payload);
    case ACTIONS.NEXT_STEP:
      return nextStep(newState);
    case ACTIONS.PREVIOUS_STEP:
      return previousStep(newState);
    case ACTIONS.GO_TO_STEP:
      return goToStep(newState, payload);
    case ACTIONS.UPDATE_STEP:
      return updateStep(newState, payload);
    case ACTIONS.REMOVE_STEP:
      return removeStep(newState, payload);
    default:
      return state;
  }
}

import { ViewStyle, TextStyle } from 'react-native';
import { theme } from './theme';

// GENERIQUE
export const row: ViewStyle = {
  display: 'flex',
  flexDirection: 'row',
};

export const col: ViewStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const worksansoxford: TextStyle = {
  fontFamily: 'Work Sans',
  letterSpacing: -0.1,
  color: theme.colors.oxfordblue,
};

export const worksansbaby: TextStyle = {
  fontFamily: 'Work Sans',
  letterSpacing: -0.1,
  color: theme.colors.babypowder,
};

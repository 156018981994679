import { TextInput } from '@app/components';
import { NumberSelector } from '@app/components/form/NumberSelector';
import { ProductSelect } from '@app/components/form/selects/features/ProductSelect';
import { TagSelect } from '@app/components/form/selects/features/TagSelect';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

export const TagForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <TextInput
        name="description"
        label="Description"
        autoCapitalize="none" />

      <TagSelect label='Catégorie parente' name='parent' />

      <NumberSelector name='position' label='Position' />

      <ProductSelect name='products' isMultiple />

    </StyledForms.Form>
  );
};

import React from 'react';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';
export const SvgMenuSearch = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 50 50" width={'100%'} height={'100%'} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h50v50H0z" />
      <Circle
        stroke={strokePath}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
        cx={22.656}
        cy={22.656}
        r={16.406}
      />
      <Path
        stroke={strokePath}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m34.258 34.258 9.492 9.492"
      />
    </G>
  </Svg>
);

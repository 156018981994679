import React from "react";
import { createContext, memo } from "react";
import { WizardState, wizardReducer } from "./wizard.reducer";

type WizardProviderProps = {
  children: React.ReactNode;
}

export const WizardStateContext = createContext<WizardState>({} as any);
export const WizardDispatchContext = createContext<any>({
  dispatch: () => { }
});

WizardStateContext.displayName = 'WizardStateContext';
WizardDispatchContext.displayName = 'WizardDispatchContext';

export const WizardProvider = memo(({ children }: WizardProviderProps) => {
  const [state, dispatch] = React.useReducer(wizardReducer, {
    currentStep: 1,
    steps: []
  });

  return (
    <WizardStateContext.Provider value={state}>
      <WizardDispatchContext.Provider value={{ dispatch }}>
        {children}
      </WizardDispatchContext.Provider>
    </WizardStateContext.Provider>
  );
});
import { Button, Card, CardButtonActionMode, HeaderGradient, MiniCard, Row } from '@app/components';
import React, { Fragment, memo } from 'react';
import { theme } from '@app/theme';
import { useDynamicSizes } from '@app/hooks';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet, View } from 'react-native';
import { IShop, isShopWithHubrise } from '@tickeat/common';
import { useAppSelector } from '@app/store/hooks';
import { triptychSelectors } from '@app/store';
import SvgInfo from '@app/components/icons/SvgInfo';
import { SlantTitle } from '@app/components/typography';
import { Text } from 'react-native';

type ShopCardProps = {
  shop?: Partial<IShop>;
  toggle: (mode: CardButtonActionMode) => void;
  onConnectToHubrise: (shop: Partial<IShop>) => void;
  onDisconnectHubrise: (shop: Partial<IShop>) => void;
};

const formatCardData = (partialShop: Partial<IShop>) => {
  return {
    name: partialShop?.publicName || 'Nouveau restaurant'
  }
}

export const ShopCard = memo(({ shop, toggle, onConnectToHubrise, onDisconnectHubrise }: ShopCardProps) => {
  const { subContainerHeight } = useDynamicSizes();

  const partialShop = useAppSelector<Partial<IShop>>(triptychSelectors.selectData);
  const mode = useAppSelector(triptychSelectors.selectMode);
  const cardData = useAppSelector<Partial<IShop>>(triptychSelectors.selectCardData);

  const { name } = formatCardData(cardData ?? partialShop);

  return (
    <Card>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient}
        />
      </HeaderGradient>

      <Card.TopHeader>
        <Card.TopHeaderLabel>Fiche restaurant</Card.TopHeaderLabel>
      </Card.TopHeader>

      <Card.Container height={subContainerHeight}>
        <Card.Background source={require('@app/assets/img/cardbackground.png')} />

        <Card.Content>
          <Card.Header>
            {/* En-tête */}
            <Row justify="space-between" align="center">
              <Card.HeaderTitles>
                <Card.Title numberOfLines={2}>{name}</Card.Title>
                {/* <Card.Subtitle>#MAE1</Card.Subtitle> */}
              </Card.HeaderTitles>

              <Card.ButtonAction onPress={toggle} />
            </Row>
          </Card.Header>
        </Card.Content>

        <MiniCard>
          <MiniCard.Tabs>
            <MiniCard.TabsHolder>
              <MiniCard.Tab Icon={SvgInfo} isActive />
            </MiniCard.TabsHolder>
          </MiniCard.Tabs>
          <MiniCard.Container>
            <MiniCard.Content>
              <SlantTitle label="Informations Hubrise" color={theme.colors.gold} />

              <MiniCard.Description>
                {(!!shop && isShopWithHubrise(shop)) && (
                  <View style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                    <View>
                      <Text style={{ fontWeight: '900' }}>
                        Point de vente: <Text style={{ fontWeight: 'normal' }}>{shop?.externalApiConfig?.hubrise?.locationName} - {shop?.externalApiConfig?.hubrise?.locationId}</Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={{ fontWeight: '900' }}>
                        Catalogue: <Text style={{ fontWeight: 'normal' }}>{shop?.externalApiConfig?.hubrise?.catalogName} - {shop?.externalApiConfig?.hubrise?.catalogId}</Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={{ fontWeight: '900' }}>
                        Liste client: <Text style={{ fontWeight: 'normal' }}>{shop?.externalApiConfig?.hubrise?.customerListName} - {shop?.externalApiConfig?.hubrise?.customerListId}</Text>
                      </Text>
                    </View>

                    <Button
                      title="Voir sur Hubrise"
                      onPress={() => window.open(`https://manager.hubrise.com/settings?location_id=${shop?.externalApiConfig?.hubrise?.locationId}`, '_blank')} />

                    <Button
                      title="Déconnecter"
                      bordered
                      important
                      onPress={() => onDisconnectHubrise(shop)} />
                  </View>
                )}

                {(!!shop && mode === 'UPDATE' && !isShopWithHubrise(shop)) && (
                  <Fragment>
                    <Button
                      title="Se connecter à Hubrise"
                      onPress={() => onConnectToHubrise(shop)} />
                  </Fragment>
                )}
              </MiniCard.Description>
            </MiniCard.Content>
          </MiniCard.Container>
        </MiniCard>
      </Card.Container>
    </Card>
  );
});


const styles = StyleSheet.create({
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});

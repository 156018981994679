import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
export const SvgCross = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G
      fillRule="nonzero"
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <Path d="M39 11 11 39M39 39 11 11" />
    </G>
  </Svg>
);


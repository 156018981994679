import React from 'react';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';
export const SvgSettingsShow = ({ strokePath, ...props }: SvgProps) => (
  <Svg width='100%' height='100%' viewBox="0 0 50 50" {...props}>
    <G id="icon/settings--show" stroke="none" opacity={0.55} stroke-width="1" fill="none" fill-rule="evenodd">
      <Path d="M25,10.9375 C9.375,10.9375 3.125,25 3.125,25 C3.125,25 9.375,39.0625 25,39.0625 C40.625,39.0625 46.875,25 46.875,25 C46.875,25 40.625,10.9375 25,10.9375 Z"
        stroke="#FDFFFC"
        stroke-width="4.125"
        stroke-linecap="round"
        stroke-linejoin="round" />

      <Circle stroke="#FDFFFC"
        stroke-width="4.125"
        stroke-linecap="round"
        stroke-linejoin="round"
        cx="25"
        cy="25"
        r="7.8125" />
    </G>
  </Svg>
);

import { Card, CardButtonActionMode, HeaderGradient, MiniCard, Row } from '@app/components';
import React, { memo } from 'react';
import { theme } from '@app/theme';
import { useDynamicSizes } from '@app/hooks';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet } from 'react-native';
import { IStep } from '@tickeat/common';
import { useAppSelector } from '@app/store/hooks';
import { triptychSelectors } from '@app/store';

type StepCardProps = {
  toggle: (mode: CardButtonActionMode) => void;
};

const formatCardData = (partialStep: Partial<IStep>) => {
  return {
    name: partialStep?.name || 'Nouvelle étape'
  }
}

export const StepCard = memo(({ toggle }: StepCardProps) => {
  const { subContainerHeight } = useDynamicSizes();

  const partialStep = useAppSelector<Partial<IStep>>(triptychSelectors.selectData);
  const cardData = useAppSelector<Partial<IStep>>(triptychSelectors.selectCardData);

  const { name } = formatCardData(cardData ?? partialStep)

  return (
    <Card>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient}
        />
      </HeaderGradient>

      <Card.TopHeader>
        <Card.TopHeaderLabel>Fiche étape</Card.TopHeaderLabel>
      </Card.TopHeader>

      <Card.Container height={subContainerHeight}>
        <Card.Background source={require('@app/assets/img/cardbackground.png')} />

        <Card.Content>
          <Card.Header>
            <Row justify="space-between" align="center">
              <Card.HeaderTitles>
                <Card.Title numberOfLines={2}>{name}</Card.Title>
              </Card.HeaderTitles>

              <Card.ButtonAction onPress={toggle} />
            </Row>

          </Card.Header>
        </Card.Content>

        <MiniCard>
          <MiniCard.Container />
        </MiniCard>

      </Card.Container>
    </Card>
  );
});

const styles = StyleSheet.create({
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});

import React, { createElement, useCallback, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import { useInput } from '@app/hooks/useInput';
import { StyledInputs } from '../styled/form/Inputs';
import { StyledForms } from '../styled/form/Form';
import { DateTime } from 'luxon';

interface CustomDateTimePickerProps {
  name: string;
  label: string;
  mode?: 'date' | 'time' | 'datetime' | 'datetime-local';
}

export const DateHourPicker: React.FC<CustomDateTimePickerProps> = ({ name, label, mode = 'time', ...props }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { field, error, formState } = useInput(name);
  const [blur, setBlur] = useState(true);

  const onChange = (selectedDate) => {
    const DateTime = selectedDate.target.value;
    if (DateTime) {
      field.onChange(DateTime);
    }
  };

  const onBlur = () => {
    setBlur(true);
  }

  const onFocus = () => {
    setBlur(false);
  }

  const InputDateTime = useCallback(() => {
    return createElement('input', {
      type: mode,
      ref,
      style: { display: 'none' },
      onChange
    });
  }, [onChange]);

  const value = field.value ? DateTime.fromISO(field.value).setLocale('fr').toFormat('HH:mm') : '';

  const open = () => {
    if (ref.current) {
      try {
        ref.current.value = value;
        ref.current.showPicker();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <StyledInputs.Holder>
      <StyledInputs.Label isFilled={!!field.value} isDirty={formState.isDirty} isBlurred={!formState.touchedFields}>
        {label}
      </StyledInputs.Label>

      {!!error && (
        <StyledForms.Error>
          <StyledForms.ErrorLabel>
            {error}
          </StyledForms.ErrorLabel>
        </StyledForms.Error>
      )}
      <InputDateTime />

      <Pressable onPress={open}>
        <View pointerEvents="none">
          <StyledInputs.Input
            onChangeText={field.onChange}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            ref={field.ref}
            isBlurred={blur}
            {...props}
          />
        </View>
      </Pressable>
    </StyledInputs.Holder>
  );
};
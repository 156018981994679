import React, { useState } from 'react';
import { TextInputProps as RNTextInputProps } from 'react-native';
import { isDefined } from '@app/helpers/kindOf';
import { StyledInputs, StyledTextInputProps } from '../styled/form/Inputs';
import { StyledForms } from '../styled/form/Form';
import { useInput } from '@app/hooks/useInput';
import { SvgMenuSearch } from '../icons';

export interface SearchInputProps extends RNTextInputProps, StyledTextInputProps {
  name: string;
  label: string;
}

export const SearchInput = ({ label, name, ...props }: SearchInputProps) => {
  const { field, error, formState } = useInput(name);

  const [blur, setBlur] = useState(true);

  const onBlur = () => {
    setBlur(true);
  }

  const onFocus = () => {
    setBlur(false);
  }

  return (
    <StyledInputs.Holder>

      <StyledInputs.Search
        onChangeText={field.onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={field.value}
        ref={field.ref}
        isBlurred={blur}
        {...props}
      />

      <StyledInputs.IconHolder>
        <SvgMenuSearch strokePath={blur ? '#999' : '#FFF'} />
      </StyledInputs.IconHolder>

    </StyledInputs.Holder>
  );
};

export interface IBase {
  _id: string;

  createdAt: Date;
  updatedAt: Date;
}

interface IImageFormatConfig {
  key: string;
  url: string;
}

interface IImageFormat {
  jpg: IImageFormatConfig;
  webp: IImageFormatConfig;
}

export interface IImageBySizes {
  xxlarge: IImageFormat;
  xlarge: IImageFormat;
  large: IImageFormat;
  medium: IImageFormat;
  small: IImageFormat;
  xsmall: IImageFormat;
}

export const IMAGE_SIZES = ['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall'] as const;

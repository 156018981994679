import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgArrowDown = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <Path
      d="M41 17 25.5 33 10 17"
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SvgArrowDown;


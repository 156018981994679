import { useMemo, useState } from 'react';
import { IPaginatedData, QueryManyBuilder } from '@tickeat/common';
import { useReactTable, getCoreRowModel, PaginationState, ColumnDef } from '@tanstack/react-table';
import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from '@app/store/api';

interface Props<TData> {
  columns: ColumnDef<TData, any>[];
  useQuery: (queryParams: Pick<QueryManyBuilder<TData>, 'pagination'> & { search: string }) => TypedUseQueryHookResult<IPaginatedData<TData>, QueryManyBuilder<TData>, typeof baseQueryWithReauth>
}

export const useTable = <TData,>({ columns, useQuery }: Props<TData>) => {
  const [search, setSearch] = useState('');

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 32,
  });

  const pagination = useMemo<PaginationState>(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const { data, isFetching } = useQuery({
    search,
    pagination: {
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize
    }
  });

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: Math.ceil((data?.metadata?.total ?? 1) / pageSize),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return {
    isFetching,
    table,
    data,
    setSearch
  }
}
import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
const SvgArrowPlus = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <Path d="M8 25.05h34M25.05 8v34" />
    </G>
  </Svg>
);
export default SvgArrowPlus;


import React, { useState } from 'react';
import { TextInputProps as RNTextAreaProps } from 'react-native';
import { isDefined } from '@app/helpers/kindOf';
import { StyledInputs, StyledTextInputProps } from '../styled/form/Inputs';
import { StyledForms } from '../styled/form/Form';
import { useInput } from '@app/hooks/useInput';

export interface TextAreaProps extends RNTextAreaProps, StyledTextInputProps {
  name: string;
  label: string;
}

export const TextArea = ({ label, name, ...props }: TextAreaProps) => {
  const { field, error, formState } = useInput(name);

  const [blur, setBlur] = useState(true);

  const onBlur = () => {
    setBlur(true);
  }

  const onFocus = () => {
    setBlur(false);
  }

  const isFilled = isDefined(field.value);

  return (
    <StyledInputs.Holder>
      <StyledInputs.Label isFilled={isFilled} isDirty={formState.isDirty} isBlurred={blur}>
        {label}
      </StyledInputs.Label>

      {!!error && (
        <StyledForms.Error>
          <StyledForms.ErrorLabel>
            {error}
          </StyledForms.ErrorLabel>
        </StyledForms.Error>
      )}

      <StyledInputs.Input
        onChangeText={field.onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={field.value}
        ref={field.ref}
        isBlurred={blur}
        multiline={true}
        size="large"
        {...props}
      />
    </StyledInputs.Holder>
  );
};

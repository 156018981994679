import { IPaginatedData } from "@tickeat/common";
import { isFunction } from "./kindOf";

export const extractData = <T>(paginatedData: IPaginatedData<T> | undefined, sort?: ((a: T, b: T) => number)): T[] => {
  const data = (paginatedData?.data ?? []);

  if (isFunction(sort)) {
    return [...data].sort(sort);
  }

  return data;
}
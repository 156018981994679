import React from 'react';
import { Button } from '../form';
import { Modal } from './Modal';
import { useAppSelector } from '@app/store/hooks';
import { modalsSelectors } from '@app/store';

export interface DeleteModalProps<T> {
  id: string;
  close: () => void;
  onDelete: (data: T) => void;
  children: React.ReactNode;
  isLoading?: boolean;
}

export const DeleteModal = <T extends any = any,>({ id, onDelete, close, children, isLoading }: DeleteModalProps<T>) => {
  const modal = useAppSelector(state => modalsSelectors.selectById(state, id));

  return (
    <Modal isOpen={!!modal?.isOpen}>
      <Modal.Title>Attention !</Modal.Title>

      {children}

      <Modal.Action>
        <Button title='Annuler' onPress={close} large />
        <Button title='Supprimer' onPress={() => onDelete(modal?.data)} large bordered loading={isLoading} />
      </Modal.Action>
    </Modal>
  );
}
import { IBase, IProduct, IProductVariation, slugify, UNITARY_VARIATION_NAME } from "@tickeat/common";
import { useGetUnitaryVariation } from "@app/hooks";
import { DeepPartial, UseFormReturn } from "react-hook-form";

export type ProductFormValues = DeepPartial<IProduct> & {
  isDeclinable: boolean;
  image: any;
}

export const defaultProductVariationsFormValues: Partial<IProductVariation> = {
  kitchenName: '',
  description: '',
  productVariationToIngredients: [],
  variation: undefined,
  accountCode: undefined,
  price: undefined,
  tax: undefined,
  takeAwayPrice: undefined,
  takeAwayTax: undefined,
  deliveryPrice: undefined,
  deliveryTax: undefined,
  onSpotPrice: undefined,
  onSpotTax: undefined
}

export const useProductFormSubmit = () => {
  const unitaryVariation = useGetUnitaryVariation();

  return async (formMethods: UseFormReturn<any, any>, submitCb: (payload: FormData) => void) => {
    const values = formMethods.getValues();

    if (!values.isDeclinable) {
      formMethods.setValue('productVariations.0.variation', unitaryVariation)
    }

    const payload = transformProductFormValuesToPayload(values);

    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value as string);
    }

    const isValid = await formMethods.trigger();

    if (isValid) {
      submitCb(formData);
    }
  }
}

export const transformProductFormValuesToPayload = (values: Omit<Partial<IProduct>, keyof IBase>): Partial<IProduct> => {
  const { catalogToProducts, ...data } = values;

  return {
    ...data,
    slug: slugify(data.name!),
    productVariations: data.productVariations?.map(productVariation => {
      const name = productVariation.variation?.name === UNITARY_VARIATION_NAME
        ? data.name!
        : `${data.name} - ${productVariation?.variation?.name}`;

      return {
        ...productVariation,
        name,
      }
    })
  }
}

export const transformToUpdatableFormProduct = (product?: Partial<IProduct>): ProductFormValues => {
  return {
    name: product?.name,
    image: null,
    allergens: product?.allergens || [],
    description: product?.description || '',
    isAlcohol: product?.isAlcohol,
    disabled: product?.disabled,
    options: product?.options,
    tags: product?.tags,
    catalogToProducts: product?.catalogToProducts ?? [],
    isDeclinable: (product?.productVariations?.length || 0) > 1,
    productVariations: product?.productVariations?.map(productVariation => ({
      _id: productVariation._id,
      name: productVariation.name || '',
      variation: productVariation.variation,
      kitchenName: productVariation.kitchenName || '',
      description: productVariation.description || '',
      accountCode: productVariation.accountCode,
      productVariationToIngredients: productVariation.productVariationToIngredients,
      price: productVariation.price,
      deliveryPrice: productVariation.deliveryPrice,
      takeAwayPrice: productVariation.takeAwayPrice,
      onSpotPrice: productVariation.onSpotPrice,
      tax: productVariation.tax,
      deliveryTax: productVariation.deliveryTax,
      takeAwayTax: productVariation.takeAwayTax,
      onSpotTax: productVariation.onSpotTax,
    }))
  }
}

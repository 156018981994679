import { Transform } from "class-transformer";
import { IsBoolean, IsNotEmpty, IsOptional } from "class-validator";

export class CatalogToPackDTO {
  @IsNotEmpty({ message: 'L\'identifiant du pack est requis' })
  pack: {
    _id: string
  };

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  price: number;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  deliveryPrice: number;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  takeAwayPrice: number;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  onSpotPrice: number;

  @IsOptional()
  loyaltyPointOffered: number;

  @IsOptional()
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;
}

import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
export const SvgArrowRight = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <Path
      d="m17 10 16 15.5L17 41"
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(3,0)"
    />
  </Svg>
);
import React, { memo, useEffect } from 'react';
import { usePortal } from './usePortal';
import { usePortalState } from './usePortalState';

type PortalHostProps = {
  name: string;
};

export const PortalHost = memo(({ name }: PortalHostProps) => {
  const state = usePortalState(name);
  const { registerHost, unregisterHost } = usePortal(name);

  useEffect(() => {
    registerHost();

    return () => {
      unregisterHost();
    }
  }, []);

  return <>{state.map(({ node }) => node)}</>;
});

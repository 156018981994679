import "reflect-metadata";
import { RNsponsiveProvider } from '@2pulse/responsive';
import React from 'react';
import { enableScreens } from 'react-native-screens';
import { Provider } from 'react-redux';
import { Portal } from '@tickeat/ui';
import { Router } from './Router';
import { configureAppStore } from './store/store';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { StatusBar } from 'react-native';
import { useDynamicTheme } from "./hooks";

export const store = configureAppStore();

enableScreens(true);

const Theme = (props: any) => {
  const theme = useDynamicTheme();

  return (
    <RNsponsiveProvider theme={theme}>
      {props.children}
    </RNsponsiveProvider>
  )
}

const App = () => {
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <Theme>
        <Provider store={store}>
          <Portal.Provider>
            <StatusBar hidden />

            <Router />
          </Portal.Provider>
        </Provider>
      </Theme>
    </GestureHandlerRootView >
  );
};

export default App;

import { PayloadAction, createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { RootState } from '../store';

export type IModal<T = any> = {
  id: string;
  isOpen: boolean;
  data?: T;
}

const modalsAdapter = createEntityAdapter<IModal>();

export const modalSlice = createSlice({
  name: 'modals',
  initialState: modalsAdapter.getInitialState(),
  reducers: {
    registerModal(state, action: PayloadAction<Pick<IModal, 'id'>>) {
      modalsAdapter.addOne(state, {
        ...action.payload,
        isOpen: false,
      })
    },
    unregisterModal: modalsAdapter.removeOne,
    openModal: modalsAdapter.updateOne
  },
});

export const { registerModal, unregisterModal, openModal } = modalSlice.actions;

export const modalsSelectors = modalsAdapter.getSelectors((state: RootState) => state.modals);

import { Transform, Type } from "class-transformer";
import { ArrayMinSize, IsArray, IsBoolean, IsEnum, IsIn, IsInt, IsNotEmpty, IsOptional, IsString, Min, ValidateIf, ValidateNested } from "class-validator";
import { AccountCodeDTO } from "./account-code.dto";
import { AvailabilityTypeEnum, IAccountCode, IBase, IPack, IPackToProductVariation, IPackToStep, ISchedule, ITag, ITax, OrderMode } from "../../interfaces";
import { TypeORMRelationId } from "../utils.dto";
import { IsValidSlot } from "../../validators";

export class PackDTO implements Omit<IPack, keyof IBase | 'slug' | 'packToStepsIds' | 'catalogToPacks' | 'imageUrl' | 'tagsIds'> {
  @IsNotEmpty({ message: 'Le nom est requis' })
  name: string;

  @Type(() => Object)
  image: any;

  @IsNotEmpty({ message: 'La description est requise' })
  description: string;

  @IsNotEmpty({ message: 'Le pack est actif ?' })
  @Transform(val => !!val)
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsOptional()
  @IsIn(Object.values(OrderMode), {
    message: 'Ce mode de livraison n\'existe pas',
    each: true
  })
  orderModes: OrderMode[];

  @IsNotEmpty({ message: 'Le prix est requis' })
  @Transform((value) => typeof value === 'string' ? +value : value)
  @Min(0, { message: 'Le prix de base doit être supérieur ou égal à 0' })
  price: number;

  @IsNotEmpty({ message: 'La TVA est requise' })
  @Type(() => TypeORMRelationId)
  tax: ITax;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  deliveryPrice: number;

  @IsOptional()
  @Type(() => TypeORMRelationId)
  deliveryTax: ITax;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  takeAwayPrice: number;

  @IsOptional()
  @Type(() => TypeORMRelationId)
  takeAwayTax: ITax;

  @IsOptional()
  @Transform((value) => typeof value === 'string' ? +value : value)
  onSpotPrice: number;

  @IsOptional()
  @Type(() => TypeORMRelationId)
  onSpotTax: ITax;

  @IsOptional()
  @Transform(val => +val)
  @IsInt({ message: 'Le nombre de point offert doit être un nombre entier' })
  @Min(0, { message: 'Le nombre de point offert doit être positif' })
  loyaltyPointOffered: number;

  @IsArray({ message: 'Au moins une catégorie est requise' })
  @ArrayMinSize(1, { message: 'Au moins une catégorie est requise' })
  tags: ITag[];

  @IsArray({ message: 'Au moins une déclinaison de produit est requise' })
  // @ArrayMinSize(1, { message: 'Au moins une déclinaison de produit est requise' })
  packToProductVariations: IPackToProductVariation[];

  @IsArray({ message: 'Au moins une rubrique menu est requise' })
  @ArrayMinSize(1, { message: 'Au moins une rubrique menu est requise' })
  packToSteps: IPackToStep[];

  @IsNotEmpty({ message: 'Le code comptable associé au menu est requis.' })
  @Type(() => AccountCodeDTO)
  @ValidateNested({
    message: 'Le code comptable est invalide'
  })
  accountCode: IAccountCode;

  @IsEnum(AvailabilityTypeEnum, { message: 'Le type de disponibilité est invalide' })
  availabilityType: AvailabilityTypeEnum;

  @IsOptional()
  @ValidateNested()
  @Type(() => ScheduleDTO)
  availabilitySchedule?: ISchedule;
}

export class ScheduleDTO implements ISchedule {
  @ValidateIf(({ monday }) => !!monday?.length)
  @IsValidSlot({ message: 'Les créneaux de lundi sont malformés.' })
  monday: number[][] = [];

  @ValidateIf(({ tuesday }) => !!tuesday?.length)
  @IsValidSlot({ message: 'Les créneaux de mardi sont malformés.' })
  tuesday: number[][] = [];

  @ValidateIf(({ wednesday }) => !!wednesday?.length)
  @IsValidSlot({ message: 'Les créneaux de mercredi sont malformés.' })
  wednesday: number[][] = [];

  @ValidateIf(({ thursday }) => !!thursday?.length)
  @IsValidSlot({ message: 'Les créneaux de jeudi sont malformés.' })
  thursday: number[][] = [];

  @ValidateIf(({ friday }) => !!friday?.length)
  @IsValidSlot({ message: 'Les créneaux de vendredi sont malformés.' })
  friday: number[][] = [];

  @ValidateIf(({ saturday }) => !!saturday?.length)
  @IsValidSlot({ message: 'Les créneaux de samedi sont malformés.' })
  saturday: number[][] = [];

  @ValidateIf(({ sunday }) => !!sunday?.length)
  @IsValidSlot({ message: 'Les créneaux de dimanche sont malformés.' })
  sunday: number[][] = [];
}
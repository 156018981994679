import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IProduct } from "@tickeat/common";

interface ProductDeleteModalProps extends ModalInstance<IProduct>, Omit<DeleteModalProps<IProduct>, 'children'> {
  onDelete: (product: Partial<IProduct>) => void;
}

export const ProductDeleteModal = (props: ProductDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer un produit.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible. Vous perdrez toutes les données liées à ce produit. Il sera supprimé des menus, promotions ou étapes auquel il est associé.
      </Modal.Message>
    </DeleteModal>
  )
}
import styled from '@2pulse/responsive';
import { col, row, worksansbaby } from '@app/style';
import { normalize, withComponents } from '@app/helpers';
import { theme } from '@app/theme';

const Body = styled.View<{ height: number }>(({ props }) => ({
  height: props.height,
  ...col,
  paddingVertical: normalize(34),
  rowGap: normalize(13),
}));

const PlatformRow = styled.View<{ height: string }>(({ props }) => ({
  ...row,
  width: '100%',
  minHeight: 133,
  overflow: 'hidden',
  alignItems: 'center',
}));

const Item = styled.View<{ width: number, leftDistance: number }>(({ theme, props }) => ({
  position: 'absolute',
  left: theme.sizes.sidebarWidth + props.leftDistance,
  backgroundColor: theme.colors.oxfordblue,
  borderRadius: 8,
  padding: normalize(34),
  width: props.width,
  minWidth: 0,
}));

const ItemHeader = styled.View(({ theme }) => ({
  ...row,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: normalize(21),
}));

const ItemTitle = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontWeight: '700',
  fontSize: normalize(31),
}));

const ItemSubtitle = styled.Text(({ theme }) => ({
  ...worksansbaby,
  textTransform: 'uppercase',
  fontWeight: '500',
  fontSize: normalize(21),
}));

const ItemText = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontWeight: '500',
  fontSize: normalize(21),
}));

const Details = styled.View(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.oxfordblue,
}));

const DetailsHeader = styled.View(({ theme }) => ({
  ...row,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.sizes.sidebarWidth + normalize(55),
  paddingRight: normalize(55),
  height: theme.sizes.catalogOverlapHeight,
}));

const DetailsTitle = styled.Text(({ theme }) => ({
  ...worksansbaby,
  color: theme.colors.babypowder,
  fontSize: normalize(31),
  fontWeight: '700',
}));

const DetailsKey = styled.Text(({ theme }) => ({
  ...worksansbaby,
  color: theme.colors.celadonblue,
  fontWeight: '700',
  paddingHorizontal: normalize(34),
  fontSize: normalize(18),
}));

const DetailsValue = styled.Text(({ theme }) => ({
  ...worksansbaby,
  fontSize: normalize(18),
  paddingHorizontal: normalize(34),
}));

const DetailsBody = styled.View<{ height?: number | string }>(({ theme, props }) => ({
  // height: props.height,
  flexGrow: 1,
  maxHeight: '86vh'
}));

const Scroller = styled.ScrollView<{ height: number }>(({ props }) => ({
}));

const Background = styled.View<{ height: number }>(({ props }) => ({
  position: 'absolute',
  top: theme.sizes.listHeaderHeight,
  right: 0,
  left: theme.sizes.sidebarWidth,
  height: '100%',
  zIndex: -1,
}));

const BackgroundRepeater = styled.View(({ props }) => ({
  ...row,
  width: '100%',
  height: '100%',
}));

const BackgroundRepeaterItem = styled.View<{ width: number }>(({ props }) => ({
  width: props.width,
  height: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const Calendar = withComponents(styled.View({}), {
  Scroller,
  Background,
  BackgroundRepeater,
  BackgroundRepeaterItem,
  Body,
  Item,
  ItemHeader,
  ItemTitle,
  ItemSubtitle,
  ItemText,
  Details,
  DetailsHeader,
  DetailsTitle,
  DetailsKey,
  DetailsValue,
  DetailsBody,
  PlatformRow,
})
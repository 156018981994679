import { FormProps } from "../form";

export interface WizardStepProps {
  id: string;
  title: string | React.ReactNode;
  helpText: string;
  children: React.ReactNode | React.ReactNode[];
}

export interface WizardProviderProps {
  children: React.ReactNode;
};

export interface WizardProps extends FormProps<any> {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  children: React.ReactNode;
}

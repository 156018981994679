import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { TagList, TagCard, TagDeleteModal, TagCreate, TagUpdate } from '@app/features/Tag';
import { useResourceScreenHelpers } from '@app/hooks';
import { tagApi } from '@app/store';
import { ITag } from '@tickeat/common';
import { SettingHeader } from '@app/features/SettingHeader';

export const TagScreen = () => {
  const [readOne, { isFetching }] = tagApi.useLazyReadOneTagQuery();
  const [deleteOne, { isLoading }] = tagApi.useDeleteOneTagMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: tag } = useResourceScreenHelpers<ITag>();

  const openCard = (item: Partial<ITag>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
      populate: [
        'parent',
        'childrens',
        'products',
      ]
    }), item)
  }

  return (
    <MainLayout
      header={(
        <SettingHeader
          buttonTitle='Ajouter une catégorie'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <TagDeleteModal
        {...modal}
        onDelete={(tag) => onDelete(deleteOne(tag._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <TagList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={(
          <TagCard toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />
        )}
        form={(
          mode === 'CREATE'
            ? <TagCreate />
            : <TagUpdate
              tag={tag}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


import { Transform, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, IsPositive } from "class-validator";
import { IIngredient } from "../../interfaces";
import { TypeORMRelationId } from "../utils.dto";

export class ProductVariationToIngredientDTO {
  @Type(() => TypeORMRelationId)
  ingredient: IIngredient;

  @IsOptional()
  // @IsNotEmpty({ message: 'La quantité de l\'ingrédient est requise' })
  // @Transform(val => +val)
  // @IsPositive({ message: 'La quantité doit être positive' })
  quantity: number;
}
import { IStep } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi } from '../api';

export const stepApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<IStep>(builder, 'steps', 'Steps');

    return {
      createOneStep: endpoints.createOne(),
      readManyStep: endpoints.readMany(),
      readOneStep: endpoints.readOne(),
      updateOneStep: endpoints.updateOne(),
      deleteOneStep: endpoints.deleteOne(),
    }
  },
});

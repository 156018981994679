import { ICatalog, ICatalogToPack, IPaginatedData, QueryManyBuilder } from "@tickeat/common";
import { crudEndpointsFactory } from "../crud-endpoints.factory";
import { ICatalogToProduct } from "@tickeat/common/src/interfaces/catalog/catalog-to-product.interface";
import { createSocketFactory } from "../socket";
import { providesList } from "../api";
import { ICatalogToPromotion } from "@tickeat/common/src/interfaces/catalog/catalog-to-promotion.interface";

const getSocket = createSocketFactory();

export const catalogToPromotionApi = crudEndpointsFactory<ICatalogToPromotion>()('catalog-to-promotions', 'CatalogToPromotions');

export const catalogToProductApi = crudEndpointsFactory<ICatalogToProduct>()('catalog-to-products', 'CatalogToProducts');

export const catalogToPackApi = crudEndpointsFactory<ICatalogToPack>()('catalog-to-packs', 'CatalogToPacks');

export const catalogApi = crudEndpointsFactory<ICatalog>()('catalogs', 'Catalogs')
  .injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
      readManyCatalogs: builder.query<IPaginatedData<ICatalog>, QueryManyBuilder<ICatalog>>({
        providesTags: (result) => providesList(result?.data, 'Catalogs'),
        query: (params) => ({
          url: 'catalogs',
          params,
          method: 'GET',
        }),
        async onCacheEntryAdded(
          params,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
        ) {

          const socket = await getSocket();

          const listener = (payload) => {
            console.log("listener", payload)

            updateCachedData((draft) => {
            });
          }

          try {

            await cacheDataLoaded;

            if (!socket.hasListeners('TEST')) {
              socket.on('TEST', listener);
            }

          } catch (err) {
            console.error(err);
          }

          await cacheEntryRemoved;

          socket.removeListener('TEST', listener);
        },
      }),
      readOneCatalogWithRelations: builder.query<ICatalog, string>({
        queryFn: async (productId, { dispatch }) => {
          const catalog = await dispatch(catalogApi.endpoints.readOneCatalogs.initiate({
            filters: { _id: { $eq: productId } },
            populate: [
              'catalogToShops',
              'catalogToTags',
              'catalogToTags.tag',
            ]
          }, { forceRefetch: true })).unwrap();

          const paginatedCatalogToPromotions = await dispatch(catalogToPromotionApi.endpoints.readManyCatalogToPromotions.initiate({
            filters: { catalogId: { $eq: catalog._id } },
            populate: [
              'promotion',
              'promotion.tags'
            ]
          }, { forceRefetch: true })).unwrap();

          const paginatedCatalogToProducts = await dispatch(catalogToProductApi.endpoints.readManyCatalogToProducts.initiate({
            filters: { catalogId: { $eq: catalog._id } },
            populate: [
              'product',
              'product.productVariations',
              'product.tags',
              'catalogProductToProductVariations',
              'catalogProductToProductVariations.productVariation',
            ]
          }, { forceRefetch: true })).unwrap();

          const paginatedCatalogToPacks = await dispatch(catalogToPackApi.endpoints.readManyCatalogToPacks.initiate({
            filters: { catalogId: { $eq: catalog._id } },
            populate: [
              'pack',
              'pack.tags'
            ]
          }, { forceRefetch: true })).unwrap();

          const catalogToPromotions = paginatedCatalogToPromotions?.data ?? [];
          const catalogToPacks = paginatedCatalogToPacks?.data ?? [];
          const catalogToProducts = paginatedCatalogToProducts?.data ?? [];

          const data: ICatalog = {
            ...catalog,
            catalogToPromotions,
            catalogToPacks,
            catalogToProducts
          }

          return { data };
        },
      }),
      sendToHubrise: builder.mutation<any, { catalogId: string }>({
        query: (body) => ({
          url: 'catalogs/hubrise',
          method: 'POST',
          body,
        }),
      }),
    }),
  });

import { useController, useFormContext } from "react-hook-form";
import { useError } from "@app/contexts";

export const useInput = (name: string) => {
  const error = useError(name);

  const { control, ...props } = useFormContext();

  const {
    field,
  } = useController({
    name,
    control,
  });

  return {
    field,
    control,
    error,
    ...props
  }
}
import React from 'react';
import { MainLayoutHeader, MainLayoutHeaderProps } from '@app/components';

export const ProductHeader = ({ onPress, buttonTitle }: Pick<MainLayoutHeaderProps, 'buttonTitle' | 'onPress'>) => {
  return (
    <MainLayoutHeader
      tabs={[
        { label: 'Produits', screenName: 'ProductScreen' },
        { label: 'Variations', screenName: 'VariationScreen' },
        { label: 'Ingrédients', screenName: 'IngredientScreen' },
        { label: 'Options', screenName: 'OptionScreen' },
        { label: 'Codes comptables', screenName: 'AccountCodeScreen' },
      ]}
      onPress={onPress}
      buttonTitle={buttonTitle} />
  );
};

import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { PaymentMethodList, PaymentMethodCard, PaymentMethodDeleteModal, PaymentMethodCreate, PaymentMethodUpdate } from '@app/features/PaymentMethod';
import { useResourceScreenHelpers } from '@app/hooks';
import { paymentMethodApi } from '@app/store';
import { IPaymentMethod } from '@tickeat/common';
import { SettingHeader } from '@app/features/SettingHeader';

export const PaymentMethodScreen = () => {
  const [readOne, { isFetching }] = paymentMethodApi.useLazyReadOnePaymentMethodsQuery();
  const [deleteOne, { isLoading }] = paymentMethodApi.useDeleteOnePaymentMethodsMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: paymentMethod } = useResourceScreenHelpers<IPaymentMethod>();

  const openCard = (item: Partial<IPaymentMethod>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
    }), item);
  }

  return (
    <MainLayout
      header={(
        <SettingHeader
          buttonTitle='Ajouter un moyen de paiement'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <PaymentMethodDeleteModal
        {...modal}
        onDelete={(paymentMethod) => onDelete(deleteOne(paymentMethod._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <PaymentMethodList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={(
          <PaymentMethodCard
            paymentMethod={paymentMethod ?? selectedItem}
            toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />
        )}
        form={(
          mode === 'CREATE'
            ? <PaymentMethodCreate />
            : <PaymentMethodUpdate
              paymentMethod={paymentMethod}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout >
  );
};


import React from 'react';
import styled from '@2pulse/responsive';
import { normalize, withComponents } from '@app/helpers';
import { row } from '@app/style';

const Container = styled.View(({ theme }) => ({
  width: '100%',
  borderTopWidth: 1,
  flexGrow: 1,
  // paddingVertical: 34,
  borderColor: theme.colors.celadonblue,
}));

const Content = styled.ScrollView({
  height: '100%',
  backgroundColor: '#0B2032',
  paddingTop: 21,
});
const Description = styled.Text(({ theme }) => ({
  fontFamily: 'Work Sans',
  paddingHorizontal: normalize(34),
  paddingVertical: normalize(13),
  fontSize: normalize(18),
  fontWeight: '600',
  color: theme.colors.babypowder,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column'
}));

const Tabs = styled.View(({ theme }) => ({
  height: 55,
  zIndex: 9,
  marginBottom: -1,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  backgroundColor: '#0B2032',
  borderWidth: 1,
  borderBottomWidth: 0,
  borderColor: theme.colors.celadonblue,
}));

const TabsHolder = styled.View({
  ...row,
  paddingHorizontal: 13,
  justifyContent: 'center',
});

type MiniCardTabProps = {
  Icon: any;
  isActive?: boolean;
};

const Tab = ({ Icon, isActive }: MiniCardTabProps) => {
  const iconStyle = { strokePath: isActive ? '#E8BA21' : '#5D6C77' };

  return (
    <MiniCardTabHolder>
      <MiniCardIcon>
        <Icon {...iconStyle} />
      </MiniCardIcon>
    </MiniCardTabHolder>
  );
};

const MiniCardTabHolder = styled.TouchableOpacity({
  paddingTop: 13,
  paddingBottom: 5,
  paddingHorizontal: 13,
});

const MiniCardIcon = styled.View({
  height: 32,
  width: 32,
});

export const MiniCard = withComponents(
  styled.View({
    alignItems: 'center',
    flexGrow: 1,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 8,
    //   height: 0,
    // },
    // shadowOpacity: 0.21,
    // shadowRadius: 8,
    // elevation: 13,
  }),
  {
    Container,
    Content,
    Description,
    Tabs,
    TabsHolder,
    MiniCardTabHolder,
    MiniCardIcon,
    Tab,
  },
);

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { variationApi } from '@app/store';
import { VariationDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { VariationForm } from './VariationForm';
import { VariationFormValues } from './variation-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const VariationCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = variationApi.useCreateOneVariationMutation();

  const formMethods = useForm<VariationFormValues>({
    resolver: classValidatorResolver(VariationDTO),
    defaultValues: {
      name: '',
      position: 1
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: VariationFormValues) => {
    try {
      const variation = await create(formValues).unwrap();

      triptych.close({ mode: 'UPDATE', data: variation });

      toast.success('Votre compte comptable a bien été ajoutée.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre compte comptable.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <VariationForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

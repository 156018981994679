import styled from '@2pulse/responsive';
import { col, row } from '@app/style';

export const MainContainer = styled.View({
  ...col,
  // marginTop: -692,
});

export const Container = styled.View({
  ...col,
  overflow: 'hidden',
});

type SubContainerProps = {
  height?: number;
}

export const SubContainer = styled.View<SubContainerProps>(({ props }) => ({
  ...row,
  width: '100%',
  justifyContent: 'flex-start'
}));

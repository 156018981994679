import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { ProductHeader } from '@app/features';
import { ProductList, ProductDeleteModal, ProductCreate, ProductUpdate } from '@app/features/Product';
import { useResourceScreenHelpers } from '@app/hooks';
import { productApi } from '@app/store';
import { IProduct } from '@tickeat/common';

export const ProductScreen = () => {
  const [readOne, { isFetching }] = productApi.useLazyReadOneProductQuery();
  const [deleteOne, { isLoading }] = productApi.useDeleteOneProductsMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: product } = useResourceScreenHelpers<IProduct>();

  const openCard = (item: Partial<IProduct>) => {
    onUpdate(readOne(item._id!), item)
  }

  return (
    <MainLayout
      header={(
        <ProductHeader
          buttonTitle='Ajouter un produit'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <ProductDeleteModal
        {...modal}
        onDelete={(product) => onDelete(deleteOne(product._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <ProductList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        form={(
          mode === 'CREATE'
            ? <ProductCreate />
            : <ProductUpdate
              product={product ?? selectedItem}
              toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.close()}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FieldError, FieldErrors } from "react-hook-form";

export const extractApiGenericError = (error: FetchBaseQueryError | SerializedError | undefined) => {
  return (error as any)?.data?.message ?? null;
}

export const isFetchBaseQueryError = (error: FetchBaseQueryError | SerializedError): error is FetchBaseQueryError => {
  return 'data' in error;
}

export const extractFormError = (fieldErrors: FieldErrors<any>, name: string): FieldError => {
  return name
    .split('.')
    .reduce((prev: Record<string, any>, curr: string) => prev?.[curr], fieldErrors) as FieldError;
}

export const extractFormOrApiErrorMessage = (fieldErrors: FieldErrors<any>, apiError: Record<string, string>, name: string) => {
  const formErrors = extractFormError(fieldErrors, name);

  const resolvePath = (obj: any, path: string) => {
    return path.split('.').reduce((prev, curr) => (prev as any)?.[curr], obj) as string;
  }

  const apiErrorMessage = resolvePath(apiError?.data, name);

  return apiErrorMessage ?? formErrors?.message ?? null;
}
import { Exclude, Transform, Type } from "class-transformer";
import { ArrayMinSize, IsArray, IsBoolean, IsIn, IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { Allergens, IBase, IOption, IProduct, IProductVariation, ITag } from "../../interfaces";
import { ProductVariationDTO } from "./product-variation.dto";
import { ICatalogToProduct } from "../../interfaces/catalog/catalog-to-product.interface";

export class ProductDTO implements Partial<IProduct> {
  @IsNotEmpty({ message: 'Le nom du produit est requis' })
  name: string;

  @Type(() => Object)
  image: any;

  @IsOptional()
  description: string;

  @IsOptional()
  @Transform((value) => !!value)
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  disabled: boolean;

  @IsOptional()
  @Transform((value) => !!value)
  @IsBoolean({ message: 'Le type envoyé n\'est pas correct' })
  isAlcohol: boolean;

  @IsArray({ message: 'Au moins une déclinaison est requise' })
  @ArrayMinSize(1, { message: 'Au moins une déclinaison est requise' })
  @Type(() => ProductVariationDTO)
  @ValidateNested({
    each: true,
  })
  productVariations: IProductVariation[];

  @IsOptional()
  catalogToProducts?: ICatalogToProduct[] = [];

  @IsOptional()
  options: IOption[] = [];

  @IsArray({ message: 'Au moins une catégorie est requise' })
  @ArrayMinSize(1, { message: 'Au moins une catégorie est requise' })
  tags: ITag[];

  @IsOptional()
  @IsArray({ message: 'Au moins un allérgene est requis' })
  @IsIn(Object.values(Allergens), {
    message: 'Cet allergène n\'existe pas',
    each: true
  })
  allergens: Allergens[]
}

export enum OrderMode {
  DELIVERY = 'DELIVERY',
  TAKEAWAY = 'TAKEAWAY',
  ONSPOT = 'ONSPOT',
}

export function getOrderModeFR(orderMode: OrderMode) {
  switch (orderMode) {
    case OrderMode.DELIVERY:
      return 'Livraison';
    case OrderMode.TAKEAWAY:
      return 'Emporter';
    case OrderMode.ONSPOT:
      return 'Sur place';
    default:
      return '';
  }
}
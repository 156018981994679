import { BaseSelect } from '@app/components';
import { optionApi } from '@app/store';
import { createColumnHelper } from '@tanstack/react-table';
import { IOption } from '@tickeat/common';
import React from 'react';
import { ResourceSelect, ResourceSelectParentProps } from '../ResourceSelect';

const columnHelper = createColumnHelper<IOption>();

export const OptionSelect = ({ label = 'Options', ...props }: ResourceSelectParentProps<IOption>) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
    columnHelper.accessor('mandatory', {
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Obligatoire
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue() ? 'Oui' : 'Non'}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
  ];

  return (
    <ResourceSelect
      label={label}
      columns={columns}
      useQuery={optionApi.useReadManyOptionQuery}
      {...props}
    />
  );
};

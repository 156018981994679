import * as React from 'react';
import Svg, { SvgProps, Defs, RadialGradient, Stop, Path, G, Use } from 'react-native-svg';
const SvgWizardbackground = (props: SvgProps) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 1366 1024"
    preserveAspectRatio='none'
    {...props}>
    <Defs>
      <RadialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="57.739%"
        gradientTransform="matrix(0 1 -.74963 0 .875 0)"
        id="wizardbackground_svg__b">
        <Stop stopOpacity={0} offset="0%" />
        <Stop stopOpacity={0.5} offset="100%" />
      </RadialGradient>
      <Path id="wizardbackground_svg__a" d="M0 0h1366v1024H0z" />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Use fill="#02182B" xlinkHref="#wizardbackground_svg__a" />
      <Use
        fillOpacity={0.75}
        fill="url(#wizardbackground_svg__b)"
        xlinkHref="#wizardbackground_svg__a"
      />
    </G>
  </Svg>
);
export default SvgWizardbackground;


import { IAdvertisement } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import {
  rootApi,
  rootMiddleware,
} from '../api';
import { isAnyOf } from '@reduxjs/toolkit';

const url = 'advertisements';
const tagType = 'Advertisements';

export const advertisementApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<IAdvertisement>(builder, url, tagType);

    return {
      createOneAdvertisement: builder.mutation<IAdvertisement, FormData>({
        invalidatesTags: [{ type: tagType, id: 'LIST' }],
        query: (body) => ({
          url,
          method: 'POST',
          body,
        }),
      }),
      updateOneAdvertisement: builder.mutation<
        Partial<IAdvertisement>,
        { _id: string; formData: FormData }
      >({
        invalidatesTags: (_, __, arg) => [{ type: tagType, id: arg._id }],
        query: ({ _id, formData }) => ({
          url: `${url}/${_id}`,
          method: 'PUT',
          body: formData,
        }),
      }),
      readManyAdvertisements: endpoints.readMany(),
      readOneAdvertisement: endpoints.readOne(),
      deleteOneAdvertisement: endpoints.deleteOne(),
    };
  },
});

rootMiddleware.startListening({
  matcher: isAnyOf(
    advertisementApi.endpoints.createOneAdvertisement.matchFulfilled,
    advertisementApi.endpoints.deleteOneAdvertisement.matchFulfilled,
    advertisementApi.endpoints.updateOneAdvertisement.matchFulfilled,
  ),
  effect: (_, listenerApi) => {
    listenerApi.dispatch(advertisementApi.util.invalidateTags(['Advertisements']));
  }
})

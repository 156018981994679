import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store';

export type TriptychSliceState = {
  isOpen: boolean;
  mode: 'CREATE' | 'UPDATE';
  data: any;
  cardData: any;
}

const initialState = {
  isOpen: false,
  mode: 'CREATE',
  data: {},
  cardData: {},
} as TriptychSliceState;

export const triptychSlice = createSlice({
  name: 'triptych',
  initialState,
  reducers: {
    open: <T>(state: TriptychSliceState, action: PayloadAction<{ mode?: TriptychSliceState['mode'], data?: T } | undefined>) => {
      state.cardData = action.payload?.data || {}; // OK?
      state.data = action.payload?.data || {};
      state.mode = action.payload?.mode || 'CREATE';
      state.isOpen = true;
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
    close: <T>(state: TriptychSliceState, action: PayloadAction<{ mode?: TriptychSliceState['mode'], data?: T } | undefined>) => {
      state.isOpen = false;
      state.cardData = action.payload?.data || state.cardData;
      state.data = action.payload?.data || state.data;
      state.mode = action.payload?.mode || state.mode;
    },
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    // See : https://github.com/react-hook-form/react-hook-form/discussions/3715#discussioncomment-2151458
    setData: (state, action) => {
      state.data = JSON.parse(JSON.stringify(action.payload))
    },
    setCardData: (state, action) => {
      state.cardData = JSON.parse(JSON.stringify(action.payload))
    }
  },
});

const selectIsOpen = (state: RootState) => state.triptych.isOpen;
const selectData = <T>(state: RootState) => state.triptych.data as T;
const selectCardData = <T>(state: RootState) => state.triptych.cardData as T;
const selectMode = (state: RootState) => state.triptych.mode;

export const triptychSelectors = {
  selectIsOpen,
  selectData,
  selectCardData,
  selectMode
}

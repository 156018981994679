import { IsNotEmpty, IsOptional } from "class-validator";
import { IBase, IProduct, ITag } from "../../interfaces";
import { ICatalogToTag } from "../../interfaces/catalog/catalog-to-tag.interface";

export class TagDTO implements Omit<ITag, keyof IBase | 'slug'> {
  @IsNotEmpty({ message: 'Le nom est requis' })
  name: string;

  @IsOptional()
  parent: ITag;

  @IsOptional()
  color: string;

  @IsOptional()
  products: IProduct[];

  @IsOptional()
  description: string;

  @IsOptional()
  imageUrl: string;

  position: number;

  parentTagId: string;
  catalogToTags: ICatalogToTag[];
  childrens: ITag[];
}

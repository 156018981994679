import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { StepList, StepCard, StepDeleteModal } from '@app/features/Step';
import { StepCreate } from '@app/features/Step/StepCreate';
import { StepUpdate } from '@app/features/Step/StepUpdate';
import { useResourceScreenHelpers } from '@app/hooks';
import { stepApi } from '@app/store';
import { PackHeader } from '@app/features/Pack';
import { IStep } from '@tickeat/common';

export const StepScreen = () => {
  const [readOne, { isFetching }] = stepApi.useLazyReadOneStepQuery();
  const [deleteOne, { isLoading }] = stepApi.useDeleteOneStepMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: step } = useResourceScreenHelpers<IStep>();

  const openCard = (item: Partial<IStep>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item?._id } },
      populate: [
        'stepToProductVariations',
        'stepToProductVariations.productVariation'
      ]
    }), item)
  }

  return (
    <MainLayout
      header={(
        <PackHeader
          buttonTitle='Ajouter une étape'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <StepDeleteModal
        {...modal}
        onDelete={(step) => onDelete(deleteOne(step._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <StepList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={<StepCard toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />}
        form={(
          mode === 'CREATE'
            ? <StepCreate />
            : <StepUpdate
              step={step}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


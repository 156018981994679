import { useCallback, useEffect, useState } from 'react';
import { useToast, useTriptych } from '@app/hooks';
import { useAppSelector } from '@app/store/hooks';
import { triptychSelectors } from '@app/store';
import { useModal } from '@app/hooks/useModal';
import { nanoid } from '@reduxjs/toolkit';
import { MutationActionCreatorResult, QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { TagTypes } from '@app/store/api';
import { useFocusEffect } from '@react-navigation/native';

type CustomQueryActionCreatorResult<TType> = QueryActionCreatorResult<QueryDefinition<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, TagTypes, TType, "api">>
type CustomMutationActionCreatorResult<TType> = MutationActionCreatorResult<MutationDefinition<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, TagTypes, TType, "api">>;

const modalId = nanoid();

export const useResourceScreenHelpers = <TItem,>() => {
  const toast = useToast();
  const triptych = useTriptych();
  const modal = useModal(modalId);

  const [item, setItem] = useState<TItem | undefined>(undefined);

  const mode = useAppSelector(triptychSelectors.selectMode);
  const selectedItem = useAppSelector<Partial<TItem>>(triptychSelectors.selectData);

  const onUpdate = useCallback(async (queryActionCreator: CustomQueryActionCreatorResult<TItem>, updateData: Partial<TItem>) => {
    triptych.open({ mode: 'UPDATE', data: updateData });

    const updatedItem = await queryActionCreator.unwrap();

    setItem(updatedItem);
  }, []);

  const onDelete = useCallback(async (mutationActionCreator: CustomMutationActionCreatorResult<TItem>) => {
    try {
      await mutationActionCreator.unwrap();

      modal.close();
      triptych.close();

      toast.success('La suppression a été effectuée avec succès.');
    } catch {
      toast.error('Une erreur est survenue lors de la suppression.');
    }
  }, []);

  useFocusEffect(useCallback(() => {
    if ((selectedItem as any)?._id !== (item as any)?._id) {
      setItem(undefined);
    }
  }, [mode, selectedItem, item]));

  useFocusEffect(useCallback(() => {
    return () => {
      triptych.close()
    }
  }, [triptych.close]));

  return {
    mode,
    selectedItem,
    triptych,
    modal,
    item,
    onUpdate,
    onDelete
  }
}
import { BaseSelect } from '@app/components';
import { accountCodeApi } from '@app/store/services/account-code';
import { createColumnHelper } from '@tanstack/react-table';
import { IAccountCode } from '@tickeat/common';
import React from 'react';
import { ResourceSelect, ResourceSelectParentProps } from '../ResourceSelect';

const columnHelper = createColumnHelper<IAccountCode>();

export const AccountCodeSelect = ({ label = 'Codes comptables', ...props }: ResourceSelectParentProps<IAccountCode>) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
    columnHelper.accessor('code', {
      header: () => (
        <BaseSelect.ListHeaderItem width="50%">
          Code
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="50%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
  ];

  return (
    <ResourceSelect
      label={label}
      columns={columns}
      useQuery={accountCodeApi.useReadManyAccountCodesQuery}
      {...props} />
  );
};

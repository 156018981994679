import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
export const SvgMenuProducts = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 50 50" width={'100%'} height={'100%'} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h50v50H0z" />
      <Path
        d="M9.531 18.75a1.563 1.563 0 0 1-1.523-1.895C9.258 10.84 16.387 6.25 25 6.25c8.613 0 15.742 4.59 16.992 10.605a1.563 1.563 0 0 1-1.523 1.895H9.53ZM40.625 32.813v3.124a6.25 6.25 0 0 1-6.25 6.25h-18.75a6.25 6.25 0 0 1-6.25-6.25v-3.124"
        stroke={strokePath}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        stroke={strokePath}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.469 34.375 7.812-3.125 7.813 3.125 7.812-3.125 7.813 3.125 7.812-3.125M4.688 25h40.625"
      />
    </G>
  </Svg>
);

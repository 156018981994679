import { IBase } from "../base.interface";
import { ICatalogShopToCatalogPack } from "./catalog-shop-to-catalog-pack.interface";
import { ICatalog } from "./catalog.interface";
import { IPack } from "./pack.interface";

export interface ICatalogToPack extends IBase {
  disabled: boolean;

  price: number;

  catalogShopToCatalogPacks: ICatalogShopToCatalogPack[];

  deliveryPrice: number;

  takeAwayPrice: number;

  onSpotPrice: number;

  loyaltyPointOffered: number;

  catalogId: string;

  packId: string;

  catalog: ICatalog;

  pack: IPack;
}
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { shopApi } from '@app/store';
import { ShopDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { ShopForm } from './ShopForm';
import { ShopFormValues, transformShopFormValuesToPayload } from './shop-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const ShopCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = shopApi.useCreateOneShopsMutation();

  const formMethods = useForm<ShopFormValues>({
    resolver: classValidatorResolver(ShopDTO),
    defaultValues: {
      publicName: '',
      internalName: '',
      description: '',
      address: '',
      phone: '',
      email: '',
      disabled: false,
      location: {
        type: "Point",
        coordinates: [
          0.6792692,
          47.4073735
        ]
      },
      isDeliveryOpen: false,
      isTakeawayOpen: false,
      socialMediaLinks: {
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
      }
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['publicName']);

  const onCreate = async (formValues: ShopFormValues) => {
    try {
      const shop = await create(transformShopFormValuesToPayload(formValues)).unwrap();

      triptych.close({ mode: 'UPDATE', data: shop });

      toast.success('Votre restaurant a bien été ajouté.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre restaurant.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <ShopForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

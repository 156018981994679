import { StyledButtons } from '@app/components/styled/form/Buttons';
import { theme } from '@app/theme';
import { ActivityIndicator, TouchableOpacityProps } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

type ButtonProps = {
  title: string;
  loading?: boolean;
  inverted?: boolean;
  grow?: boolean;
  large?: boolean;
  bordered?: boolean,
  square?: boolean,
  important?: boolean,
  inactive?: boolean,
  smallText?: boolean,
  small?: boolean,
  active?: boolean,
} & TouchableOpacityProps;

export const Button = ({ title, loading, inactive, square, smallText, inverted, large, grow, bordered, important, ...props }: ButtonProps) => {
  return (
    <StyledButtons.BaseButton disabled={loading || props.disabled} grow={grow} square={square} inverted={inverted} large={large} bordered={bordered} important={important} {...props}>
      {(!bordered && !inactive && !inverted) &&
        <LinearGradient
          style={{ flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}
          start={{ x: .5, y: 0 }}
          end={{ x: 0.5, y: 1.0 }}
          locations={[0, .7]}
          colors={['#008FCB', '#00B4E0']} >
        </LinearGradient>
      }

      {inactive &&
        <LinearGradient
          style={{ flex: 1, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, }}
          start={{ x: .5, y: 0 }}
          end={{ x: 0.5, y: 1.0 }}
          locations={[0, .7]}
          colors={['#0C2133', '#0C2133']} >
        </LinearGradient>
      }

      {loading
        ? <ActivityIndicator color={!inverted ? '#fff' : theme.colors.celadonblue} />
        : <StyledButtons.Label large={large} inverted={inverted} important={important} smallText={smallText}>
          {title}
        </StyledButtons.Label>}

    </StyledButtons.BaseButton>
  )
}
export const PaginationButton = ({ title, active, small, ...props }: ButtonProps) => {
  return (
    <StyledButtons.Container inactive={props.disabled} active={active} small={small} {...props}>

      <StyledButtons.NewLabel small={small}>
        {title}
      </StyledButtons.NewLabel>

    </StyledButtons.Container>
  )
}
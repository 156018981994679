import { Primitive, Paths, DeepPartial } from './utils.interface';

export interface IPaginatedData<T> {
  data: T[];

  metadata: {
    total: number;
  };
}

export type ConditionalOperators = ['$or' | '$and'];

export const OPERATORS = [
  '$contains',
  '$starts',
  '$ends',
  '$eq',
  '$ne',
  '$in',
  '$notIn',
  '$gt',
  '$gte',
  '$lt',
  '$lte'
] as const;

export type QueryOperators = typeof OPERATORS;

type Equal<T> = T;
type GreaterThan = number | Date;

type Operators<T> = {
  [K in QueryOperators[number]]: K extends '$eq' | '$ne'
  ? Equal<T>
  : K extends '$gt' | '$gte' | '$lt' | '$lte'
  ? GreaterThan
  : K extends '$in' | '$notIn'
  ? Array<T>
  : T;
};

type Populate<T> = (Paths<T, 5, Date> | string)[];

export type CondtionalFilters<T> = {
  [K in ConditionalOperators[number]]: DeepPartial<Filters<T>>;
};

export type SortDirection = 'ASC' | 'DESC';

type Fields<T> = Paths<T, 4>[];

type Sort<T> = keyof T extends string ? `${keyof T}:${SortDirection}`[] : never;

type Pagination = {
  take?: number;
  skip?: number;
};

export type Filters<T> = {
  [K in keyof T]: T[K] extends Primitive | Date
  ? Operators<T[K]>
  : T[K] extends Array<infer U>
  ? Array<Filters<U>>
  : T[K] extends object
  ? Filters<T[K]>
  : never;
};

export type QueryManyBuilder<T> = {
  filters?: DeepPartial<Filters<T>> | DeepPartial<CondtionalFilters<T>>[];
  populate?: Populate<T> | '*';
  sort?: Sort<T>;
  fields?: Fields<T> | '*';
  pagination?: Pagination;
};

export type QueryOneBuilder<T> = Pick<QueryManyBuilder<T>, 'filters' | 'populate' | 'fields'>;

import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { IStep } from "@tickeat/common";

interface StepDeleteModalProps extends ModalInstance<IStep>, Omit<DeleteModalProps<IStep>, 'children'> {
  onDelete: (product: Partial<IStep>) => void;
}

export const StepDeleteModal = (props: StepDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer une étape.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible. Vous perdrez toutes les données liées à cette étape. Elle sera supprimée des menus auquel elle est associée.
      </Modal.Message>
    </DeleteModal>
  )
}
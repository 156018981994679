import { TextInput } from '@app/components';
import { Toggle } from '@app/components/form/Toggle';
import { PaymentTypeSelect } from '@app/components/form/selects/features/PaymentTypeSelect';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

export const PaymentMethodForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="name"
        label="Nom"
        autoCapitalize="none" />

      <Toggle
        name='disabled'
        options={[
          { title: "Actif", value: false },
          { title: "Inactif", value: true },
        ]} />

      <PaymentTypeSelect name="specialType" />

    </StyledForms.Form>
  );
};

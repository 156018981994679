import { IBase } from "../base.interface";
// import { IIngredientShop } from "./ingredient-shop.interface";

export interface IIngredient extends IBase {
  name: string;

  unit: string;

  price: number;

  bundle: string;

  supplier: string;

  // ingredientShops: IIngredientShop[];
}
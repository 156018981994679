import React, { memo } from 'react';
import { ACTION_TYPES, portalReducer, PortalState } from './portal.reducer';

// https://beta.reactjs.org/learn/scaling-up-with-reducer-and-context#step-1-create-the-context
type PortalStateProps = {
  state: PortalState;
};

type PortalDispatchProps = {
  dispatch: React.Dispatch<ACTION_TYPES>;
};

type PortalProviderProps = {
  children: React.ReactNode;
};

const INITIAL_STATE: PortalState = {};

export const PortalStateContext = React.createContext<PortalStateProps>({
  state: INITIAL_STATE,
});

export const PortalDispatchContext = React.createContext<PortalDispatchProps>({
  dispatch: () => { },
});

PortalStateContext.displayName = 'PortalStateContext';
PortalDispatchContext.displayName = 'PortalDispatchContext';

export const PortalProvider = memo(({ children }: PortalProviderProps) => {
  const [state, dispatch] = React.useReducer(portalReducer, {});

  return (
    <PortalStateContext.Provider value={{ state }}>
      <PortalDispatchContext.Provider value={{ dispatch }}>
        {children}
      </PortalDispatchContext.Provider>
    </PortalStateContext.Provider>
  );
});

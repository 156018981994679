import 'setimmediate';
import App from './src/App';
import './web/normalize.css';
import { AppRegistry } from 'react-native';
import { name as appName } from './app.json';
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';

enableExperimentalWebImplementation(true);

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

if (module.hot) {
  module.hot.accept();
}

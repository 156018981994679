import React, { Fragment } from "react"
import { useAppSelector } from "@app/store/hooks"
import { toastersSelectors } from "@app/store/slices/toaster.slice"
import { Toast } from "./Toaster";

export const ToasterContainer = () => {
  const toasts = useAppSelector(toastersSelectors.selectAll);

  return (
    <Fragment>
      {toasts.map(toast => <Toast key={toast.id} toast={toast} />)}
    </Fragment>
  )

}
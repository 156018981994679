import React from 'react';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';
export const SvgMenuSettings = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 50 50" width={'100%'} height={'100%'} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h50v50H0z" />
      <Circle
        stroke={strokePath || props.color}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
        cx={25}
        cy={25}
        r={9.375}
      />
      <Path
        d="M35.879 12.715c.495.456.963.924 1.406 1.406l5.332.762a20.156 20.156 0 0 1 1.992 4.805l-3.242 4.316s.059 1.328 0 1.992l3.242 4.317a19.96 19.96 0 0 1-1.992 4.804l-5.332.762s-.918.957-1.406 1.406l-.762 5.332a20.156 20.156 0 0 1-4.804 1.992l-4.317-3.242c-.663.059-1.33.059-1.992 0l-4.317 3.242a19.96 19.96 0 0 1-4.804-1.992l-.762-5.332c-.482-.456-.95-.924-1.406-1.406l-5.332-.762a20.156 20.156 0 0 1-1.992-4.804l3.242-4.317s-.059-1.328 0-1.992L5.39 19.687a19.96 19.96 0 0 1 1.992-4.804l5.332-.762c.456-.482.924-.95 1.406-1.406l.762-5.332a20.156 20.156 0 0 1 4.805-1.992l4.316 3.242c.663-.059 1.33-.059 1.992 0l4.317-3.242a19.96 19.96 0 0 1 4.804 1.992l.762 5.332Z"
        stroke={strokePath || props.color}
        strokeWidth={4.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

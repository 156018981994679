import React, { Fragment, memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { packApi } from '@app/store';
import { AvailabilityTypeEnum, ISchedule, OrderMode, PackDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { PackForm } from './PackForm';
import { PackFormValues, usePackFormSubmit } from './pack-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { PackCard } from './PackCard';
import { InputFileRef } from '@app/components/form/InputFile';

export const PackCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const cardImageRef = useRef<InputFileRef>();

  const onSubmit = usePackFormSubmit();

  const [create, { isLoading, error: apiErrors }] = packApi.useCreateOnePacksMutation();

  const formMethods = useForm<PackFormValues>({
    resolver: classValidatorResolver(PackDTO),
    defaultValues: {
      name: '',
      description: '',
      tags: [],
      accountCode: undefined,
      disabled: false,
      price: undefined,
      tax: undefined,
      takeAwayPrice: undefined,
      takeAwayTax: undefined,
      deliveryPrice: undefined,
      deliveryTax: undefined,
      onSpotPrice: undefined,
      onSpotTax: undefined,
      orderModes: Object.values(OrderMode),
      packToProductVariations: [],
      packToSteps: [],
      availabilityType: AvailabilityTypeEnum.always,
      availabilitySchedule: {} as ISchedule,
    },
  });

  const onCreate = async (formData: FormData) => {
    try {
      const pack = await create(formData as any).unwrap();

      triptych.close({ mode: 'UPDATE', data: pack });

      toast.success('Votre menu a bien été ajouté.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre menu.')
    }
  }

  return (
    <Fragment>
      <PackCard
        formMethods={formMethods}
        toggle={() => triptych.toggle()}
        onOpenImagePicker={() => cardImageRef.current?.open()} />

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.Content>

          <Panel.FormContainer>

            <PackForm ref={cardImageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              loading={isLoading}
              title="Valider"
              onPress={() => onSubmit(formMethods, onCreate)}
              grow
              large />
          </Panel.Actions>
        </Panel.Content>

      </Form>
    </Fragment>
  );
});

import React, { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { productApi } from '@app/store';
import { IProduct, ProductDTO } from '@tickeat/common';
import { Button, CardButtonActionMode, Form, Panel } from '@app/components';
import { ProductForm } from './ProductForm';
import { ProductFormValues, useProductFormSubmit, transformToUpdatableFormProduct } from './product-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { InputFileRef } from '@app/components/form/InputFile';
import { ProductCard } from './ProductCard';

interface ProductUpdateProps {
  product?: Partial<IProduct>;
  isFetching: boolean;
  toggle: (mode: CardButtonActionMode) => void;
  onDelete: (product: Partial<IProduct>) => void;
}

export const ProductUpdate = ({ product, isFetching, toggle, onDelete }: ProductUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych();

  const cardImageRef = useRef<InputFileRef>();

  const onSubmit = useProductFormSubmit();

  const [update, { isLoading, error: apiErrors }] = productApi.useUpdateOneProductsMutation();

  const formMethods = useForm<ProductFormValues>({
    resolver: classValidatorResolver(ProductDTO),
    defaultValues: transformToUpdatableFormProduct(product)
  });

  const onUpdate = async (formData: FormData) => {
    try {
      const updatedProduct = await update({ _id: product?._id!, formData }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedProduct });

      toast.success('Votre produit a bien été modifié.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre produit.')
    }
  }

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormProduct(product));
  }, [product, formMethods.reset]);

  return (
    <Fragment>
      <ProductCard
        product={product}
        formMethods={formMethods}
        toggle={toggle}
        onOpenImagePicker={() => cardImageRef.current?.open()} />

      <Panel.Content>

        <Form formMethods={formMethods} apiErrors={apiErrors} isLoading={isFetching}>

          <Panel.FormContainer>

            <Panel.Header>
              <Button
                title="Supprimer"
                disabled={!product}
                bordered
                important
                onPress={() => onDelete(product!)} />
            </Panel.Header>

            <ProductForm ref={cardImageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              loading={isLoading}
              disabled={isFetching}
              onPress={() => onSubmit(formMethods, onUpdate)}
              title="Valider"
              grow
              large />
          </Panel.Actions>
        </Form>
      </Panel.Content>
    </Fragment>
  );
};

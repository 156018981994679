import { IBase } from "./base.interface";

export const getPaymentTypeFR = (paymentType: PaymentType) => {
  switch (paymentType) {
    case PaymentType.CBL:
      return 'Paiement en ligne';
    case PaymentType.CB:
      return 'Carte bancaire';
    case PaymentType.ESPECES:
      return 'Espèces';
    case PaymentType.TR:
      return 'Ticket restaurant';
    case PaymentType.TRD:
      return 'Carte restaurant';
    case PaymentType.CHEQUE:
      return 'Chèque';
    case PaymentType.HOLIDAY_CHEQUE:
      return 'Chèque vacances';
    case PaymentType.PLATFORM:
      return 'Plateforme';
    case PaymentType.BILL:
      return 'Facture';
    case PaymentType.OTHER:
      return 'Autre';
  }
}

export enum PaymentType {
  CBL = 'cbl',
  CB = 'cb',
  ESPECES = 'especes',
  TR = 'tr',    // Ticket restaurant
  TRD = 'trd', // Carte ticket restaurant
  CHEQUE = 'cheque',
  HOLIDAY_CHEQUE = 'holiday_cheque',
  PLATFORM = 'platform',
  BILL = 'bill',
  OTHER = 'other',
}

export interface IPaymentMethod extends IBase {
  name: string;
  specialType: PaymentType;
  return: string;
  negativeReturn: boolean;
  openCashRegister: boolean;
  authorizeCount: boolean;
  disabled: boolean;
  position: number;
  accountingCode: number;
  apiKey: string;
}

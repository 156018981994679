import React from 'react';
import { BaseResourceListProps, SimpleList, SvgBin, SvgEdit } from '@app/components';
import { variationApi } from '@app/store';
import { IVariation, UNITARY_VARIATION_NAME } from '@tickeat/common';
import { theme } from '@app/theme';
import { createColumnHelper } from '@tanstack/react-table';
import { useTable } from '@app/hooks/useTable';

const columnHelper = createColumnHelper<IVariation>();

export const VariationList = ({ onUpdate, onDelete, onRowPress, selectedRowItem }: BaseResourceListProps<IVariation>) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <SimpleList.HeaderItem width="50%">
          <SimpleList.HeaderItemLabel>Nom</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="50%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.accessor('position', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Position</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <SimpleList.HeaderItem width="25%" align='center'>
          <SimpleList.HeaderItemLabel>Actions</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: ({ row, active }) => (
        <SimpleList.ItemCol width="25%">
          {row.original.name !== UNITARY_VARIATION_NAME && (
            <SimpleList.ItemActions>
              <SimpleList.ItemAction onPress={() => onUpdate(row.original)}>
                <SvgEdit color={active ? '#FFF' : theme.colors.celadonblue} />
              </SimpleList.ItemAction>
              <SimpleList.ItemAction onPress={() => onDelete(row.original)}>
                <SvgBin color={active ? '#FFF' : theme.colors.celadonblue} />
              </SimpleList.ItemAction>
            </SimpleList.ItemActions>
          )}
        </SimpleList.ItemCol >
      ),
    }),
  ];

  const { table, setSearch, isFetching } = useTable({
    columns,
    useQuery: ({ pagination, search }) => variationApi.useReadManyVariationQuery({
      pagination,
      filters: {
        name: {
          $contains: search
        }
      },
      sort: ['name:ASC']
    })
  });

  return <SimpleList
    table={table}
    isFetching={isFetching}
    onSearch={setSearch}
    selectedRowItem={selectedRowItem}
    onRowPress={onRowPress} />;
};

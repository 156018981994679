import { makeTheme } from '@2pulse/responsive';
import { normalize } from './helpers/normalize';
import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window')

export const getDynamicSizes = (width: number, height: number) => {
  const headerHeight = normalize(120);
  const listHeaderHeight = normalize(55);
  const sidebarWidth = normalize(85);
  const catalogOverlapHeight = normalize(160);

  const layoutCard = ((37 * width) / 100);
  const layoutForm = ((63 * width) / 100) - sidebarWidth
  const layoutList = ((63 * width) / 100);

  return {
    headerHeight,
    listHeaderHeight,
    sidebarWidth,
    catalogOverlapHeight,
    layoutCard,
    layoutForm,
    layoutList,
  }
}

export const theme = makeTheme({
  colors: {
    babypowder: '#FDFFFC',
    oxfordblue: '#02182B',
    celadonblue: '#0089C8',
    satanGray: '#666',
    crimson: '#DC0000',
    gold: '#E8BA21',
    translucid: 'rgba(253,255,252,0.055)',
  },
  mediaQueries: {
    tablet: {
      minWidth: 1280,
    },
  },
  sizes: getDynamicSizes(width, height)
});

type MyTheme = typeof theme;

declare module '@2pulse/responsive' {
  interface RNsponsiveCustomTheme extends MyTheme { }
}

import { BaseSelect, Select, SelectProps } from '@app/components';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { PaymentType, getPaymentTypeFR } from '@tickeat/common';
import React from 'react';

const columnHelper = createColumnHelper<PaymentType>();

export const PaymentTypeSelect = ({ label = 'Type de paiement', ...props }: Omit<SelectProps<any>, 'table'>) => {
  const columns = [
    columnHelper.display({
      id: 'payment-type',
      header: () => (
        <BaseSelect.ListHeaderItem width="100%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => {
        return <BaseSelect.ListItemCol width="100%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {getPaymentTypeFR(info.row.original)}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      },
    }),
  ];

  const table = useReactTable({
    data: Object.values(PaymentType) as PaymentType[],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Select
      label={label}
      table={table}
      transform={{
        accessor: (item) => item,
        value: (item) => item,
        display: getPaymentTypeFR
      }}
      {...props} />
  );
};

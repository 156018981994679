import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { stepApi } from '@app/store';
import { IStep, StepDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { StepForm } from './StepForm';
import { StepFormValues, transformStepFormValuesToPayload } from './step-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormStep = (step?: Partial<IStep>): StepFormValues => {
  return {
    name: step?.name || '',
    disabled: step?.disabled,
    mandatory: step?.mandatory,
    maxChoiceCount: step?.maxChoiceCount,
    stepToProductVariations: step?.stepToProductVariations?.map(stepToProductVariation => ({
      ...stepToProductVariation,
    }))
  }
}

interface StepUpdateProps {
  step?: IStep;
  isFetching?: boolean;
  onDelete: (product: Partial<IStep>) => void;
}

export const StepUpdate = ({ step, isFetching, onDelete }: StepUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych();

  const [update, { isLoading, error: apiErrors }] = stepApi.useUpdateOneStepMutation();

  const formMethods = useForm<StepFormValues>({
    resolver: classValidatorResolver(StepDTO),
    defaultValues: transformToUpdatableFormStep(step),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onUpdate = async (formValues: Partial<IStep>) => {
    try {
      const updatedStep = await update(transformStepFormValuesToPayload({ _id: step?._id, ...formValues })).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedStep });

      toast.success('Votre étape a bien été modifiée.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre étape.')
    }
  }

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormStep(step));
  }, [step, formMethods.reset]);

  return (
    <Panel.Content>

      <Form formMethods={formMethods} isLoading={isFetching} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              bordered
              important
              onPress={() => onDelete(step!)} />
          </Panel.Header>

          <StepForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

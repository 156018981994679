import { BaseSelect } from '@app/components';
import { ingredientApi } from '@app/store';
import { createColumnHelper } from '@tanstack/react-table';
import { IIngredient } from '@tickeat/common';
import React from 'react';
import { ResourceSelect, ResourceSelectParentProps } from '../ResourceSelect';

const columnHelper = createColumnHelper<IIngredient>();

export const IngredientSelect = ({ label = 'Ingrédients', ...props }: ResourceSelectParentProps<IIngredient>) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <BaseSelect.ListHeaderItem width="100%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="100%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
  ];

  return (
    <ResourceSelect
      label={label}
      columns={columns}
      useQuery={ingredientApi.useReadManyIngredientQuery}
      {...props} />
  );
};

import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { optionApi } from '@app/store';
import { OptionDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { OptionForm } from './OptionForm';
import { OptionFormValues } from './option-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const OptionCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = optionApi.useCreateOneOptionMutation();

  const formMethods = useForm<OptionFormValues>({
    resolver: classValidatorResolver(OptionDTO),
    defaultValues: {
      name: '',
      disabled: false,
      mandatory: false,
      freeQuantity: 0,
      maxChoiceCount: 0,
      products: [],
      position: undefined
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: OptionFormValues) => {
    try {
      const option = await create(formValues).unwrap();

      triptych.close({ mode: 'UPDATE', data: option });

      toast.success('Votre option a bien été ajoutée.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre option.')
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <OptionForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

import { ITag } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi } from '../api';

export const tagApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<ITag>(builder, 'tags', 'Tags');

    return {
      createOneTag: endpoints.createOne(),
      readManyTag: endpoints.readMany(),
      readOneTag: endpoints.readOne(),
      updateOneTag: endpoints.updateOne(),
      deleteOneTag: endpoints.deleteOne(),
    }
  },
});

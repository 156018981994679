import { IStep } from "./step.interface";
import { IPack } from "./pack.interface";

export interface IPackToStep {
  position: number;

  step: IStep;

  stepId: string;

  pack: IPack;

  packId: string;
}
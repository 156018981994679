import { Socket, io } from "socket.io-client";
import { getEnv } from '@app/environment';
import { WsResponse } from "@tickeat/common";
import { store } from "@app/App";
import { RootState } from "./store";

const ACCESS_TOKEN = 'access_token';

export const createSocketFactory = () => {
  let _socket: Socket;

  return (): Socket => {
    if (!_socket) {
      const { accessToken } = (store.getState() as RootState).auth;

      const API_URL = getEnv('API_URL');

      _socket = io(API_URL, {
        auth: {
          [ACCESS_TOKEN]: accessToken
        },
        transports: ['websocket', 'polling'],
        withCredentials: true,
      });
    }

    if (_socket.disconnected) {
      _socket.connect();
    }

    return _socket;
  }
}

export const socketEmitAsPromise = (socket: Socket) => {
  return <TData = any>(message: string, data: TData): Promise<any> => {
    return new Promise((resolve, reject) => {
      socket.emit(message, data, (response: WsResponse<TData>) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(response);
        }
      });
    })
  }
}

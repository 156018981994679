import { isDefined } from '@app/helpers/kindOf';
import { IAdvertisement, IBase, IImageBySizes, IMAGE_SIZES, IShop } from '@tickeat/common';
import { UseFormReturn } from 'react-hook-form';

export interface AdvertisementFormValues extends Partial<IAdvertisement> {
  shopIds?: IShop[];
  image?: any;
}

export const useAdvertisementFormSubmit = () => {
  return async (formMethods: UseFormReturn<any, any>, submitCb: (payload: FormData) => void) => {
    const values = formMethods.getValues();
    const formData = new FormData();

    const payload: AdvertisementFormValues = {
      ...values,
      shopIds: values.shopIds?.map((shop: IShop) => (shop._id ? shop._id : shop)),
    };

    for (const [key, value] of Object.entries(payload) as any) {
      formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value as string);
    }

    const isValid = await formMethods.trigger();

    if (isValid) {
      try {
        submitCb(formData);
      } catch (error) {
        console.error('Erreur lors de la soumission:', error);
      }
    } else {
      console.error('Validation échouée');
    }
  };
};

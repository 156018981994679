import { ITax } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi } from '../api';

export const taxApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<ITax>(builder, 'taxes', 'Taxes');

    return {
      createOneTax: endpoints.createOne(),
      readManyTax: endpoints.readMany(),
      readOneTax: endpoints.readOne(),
      updateOneTax: endpoints.updateOne(),
      deleteOneTax: endpoints.deleteOne(),
    }
  },
});

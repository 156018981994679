import { IBase } from '@tickeat/common';
import React, { useState } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { BaseSelect, BaseSelectProps } from './BaseSelect';

export interface MultipleSelectProps<T> extends Omit<BaseSelectProps<T>, 'isOpen' | 'setIsOpen' | 'formattedLabel' | 'onSelect' | 'onRemove' | 'isActive'> { }

const baseTransform = {
  value: (item: any) => item,
  accessor: (item: any) => item?.['_id'],
  display: (item: any) => item?.['name'],
}

export const MultipleSelect = <T extends IBase,>({
  name,
  label,
  table,
  ...props
}: MultipleSelectProps<T>) => {
  const { control } = useFormContext();

  const transform = {
    ...baseTransform,
    ...props?.transform ?? {},
  }

  const [isOpen, setIsOpen] = useState(false);

  const { append, remove, fields } = useFieldArray<any, any, '_id'>({
    name,
    control
  });

  const formattedLabel = () => {
    const formValues = fields.map(field => field)
      .filter(Boolean)
      .map((value) => transform.display!(value));

    if (!formValues.length) {
      return label!;
    }

    let temp = label + ': ' + formValues.join(', ').substring(0, 33);

    temp = temp.length >= 33 ? temp + '...' : temp;

    return temp;
  }

  // TODO: Remove on select.
  // See: https://github.com/react-hook-form/react-hook-form/issues/1571#issuecomment-690882455
  // Le fait d'utiliser "sort" sur les field array cause un soucis au niveau des oprérations comme remove ..
  const onSelect = (item: T) => {
    const index = fields.findIndex(value => transform.accessor(value) === transform.accessor(transform.value(item)));

    if (index === -1) {
      const value = transform.value(item);

      append(value);
    }
  }

  const onRemove = (item: T) => {
    const index = fields.findIndex(value => transform.accessor(value) === transform.accessor(item));

    if (index !== -1) {
      remove(index);
    }
  }

  const isActive = (item) => {
    return fields.some((value) => transform.accessor(value) === transform.accessor(transform.value(item)));
  }

  return (
    <BaseSelect
      name={name}
      label={label}
      table={table}
      {...props}
      transform={transform}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      values={fields}
      onRemove={(item) => onRemove(item)}
      render={(item) => (
        <BaseSelect.ListItem key={(item as unknown as FieldArrayWithId<any>).id} compact isActive onPress={() => onRemove(item)}>
          <BaseSelect.ListItemCol width='100%'>
            <BaseSelect.ListItemText numberOfLines={1} bold>
              {transform.display!(item)}
            </BaseSelect.ListItemText>
          </BaseSelect.ListItemCol>
        </BaseSelect.ListItem>
      )}
      formattedLabel={formattedLabel()}
      onSelect={onSelect}
      isActive={isActive} />
  );
};

import * as React from 'react';
import { SvgProps, Svg, G, Path } from 'react-native-svg';
function SvgSignOut(props: SvgProps) {
  return (
    <Svg height={24} viewBox="0 0 18 18" width={24} {...props}>
      <G fill="#FFF" fillRule="nonzero">
        <Path d="M.075 8.6c.1-.1.1-.2.2-.3l3-3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L3.375 8h4.6c.6 0 1 .4 1 1s-.4 1-1 1h-4.6l1.3 1.3c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3l-3-3c-.1-.1-.2-.2-.2-.3-.1-.3-.1-.5 0-.8Z" />
        <Path d="M5.475 15.1c1.1.6 2.3.9 3.5.9 3.9 0 7-3.1 7-7s-3.1-7-7-7c-1.2 0-2.4.3-3.5.9-.5.3-1.1.1-1.4-.4-.3-.5-.1-1.1.4-1.4 1.4-.7 2.9-1.1 4.5-1.1 5 0 9 4 9 9s-4 9-9 9c-1.6 0-3.1-.4-4.5-1.2-.5-.3-.6-.9-.4-1.4.3-.4.9-.6 1.4-.3Z" />
      </G>
    </Svg>
  );
}
export default SvgSignOut;

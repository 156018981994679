import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { catalogApi, shopApi } from '@app/store';
import { ICatalog, CatalogDTO, IShop } from '@tickeat/common';
import { Button, CardButtonActionMode, Form, Panel } from '@app/components';
import { CatalogForm } from './CatalogForm';
import { CatalogFormValues, useCatalogFormSubmit } from './catalog-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { extractData } from '@app/helpers/utils';
import { InputFileRef } from '@app/components/form/InputFile';
import { CatalogCard } from './CatalogCard';
import { ICatalogProductToProductVariation } from '@tickeat/common/src/interfaces/catalog/catalog-product-to-product-variation.interface';
import { ICatalogToProduct } from '@tickeat/common/src/interfaces/catalog/catalog-to-product.interface';

// Les catalogProductToProductVariations sont créer au moment ou l’on met le produit dans le catalog en se basant sur les productVariations.
// Si on ajoute une productVariation après cette étape, cette derniere ne sera pas créer.
const transformToUpdatableFormCatalogToProduct = (catalogToProduct: ICatalogToProduct) => {
  const existingProductVariationsIds = catalogToProduct?.catalogProductToProductVariations
    .map(catalogProductToProductVariation => catalogProductToProductVariation.productVariation._id);

  const missingProductVariations = catalogToProduct.product.productVariations
    .filter(productVariation => !existingProductVariationsIds.includes(productVariation._id));

  if (!missingProductVariations.length) {
    return catalogToProduct;
  }

  const missingCatalogProductToProductVariations = missingProductVariations.map(productVariation => ({
    price: null,
    deliveryPrice: null,
    takeAwayPrice: null,
    onSpotPrice: null,
    disabled: false,
    productVariation
  })) as unknown as ICatalogProductToProductVariation[];

  const catalogProductToProductVariations = [
    ...catalogToProduct.catalogProductToProductVariations,
    ...missingCatalogProductToProductVariations
  ];

  return {
    ...catalogToProduct,
    catalogProductToProductVariations
  }
}

const transformToUpdatableFormCatalog = (catalog: Partial<ICatalog> | undefined, shops: IShop[] = []): CatalogFormValues => {
  return {
    name: catalog?.name,
    image: null,
    ticketInfos: catalog?.ticketInfos,
    catalogToShops: catalog?.catalogToShops?.map(catalogToShop => {
      const shop = shops.find(shop => shop._id === catalogToShop.shopId);

      return {
        ...catalogToShop,
        catalogShopId: catalogToShop._id,
        _id: catalogToShop.shopId,
        publicName: shop?.publicName || ''
      }
    }),
    catalogToPromotions: catalog?.catalogToPromotions,
    catalogToTags: catalog?.catalogToTags,
    catalogToPacks: catalog?.catalogToPacks,
    catalogToProducts: catalog?.catalogToProducts?.map(transformToUpdatableFormCatalogToProduct)
  }
}

interface CatalogUpdateProps {
  catalog?: Partial<ICatalog>;
  isFetching?: boolean;
  toggle: (mode: CardButtonActionMode) => void;
  onDelete: (product: Partial<ICatalog>) => void;
}

export const CatalogUpdate = ({ catalog, isFetching, toggle, onDelete }: CatalogUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych();

  const cardImageRef = useRef<InputFileRef>();

  const shopIds = useMemo(() => catalog?.catalogToShops?.map(catalogToShop => catalogToShop.shopId), [catalog]);

  const { data: paginatedShops } = shopApi.useReadManyShopsQuery({
    filters: {
      _id: {
        $in: shopIds
      }
    },
  }, { skip: !shopIds?.length });

  const shops = useMemo(() => extractData(paginatedShops), [paginatedShops]);

  const onSubmit = useCatalogFormSubmit();

  const [update, { isLoading, error: apiErrors }] = catalogApi.useUpdateOneCatalogsMutation();

  const formMethods = useForm<CatalogFormValues>({
    resolver: classValidatorResolver(CatalogDTO),
    defaultValues: transformToUpdatableFormCatalog(catalog, shops),
  });

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormCatalog(catalog, shops));
  }, [catalog, shops]);

  const onUpdate = async (formData: FormData) => {
    try {
      const updatedCatalog = await update({ _id: catalog?._id!, formData }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedCatalog });

      toast.success('Modification effectuée avec succès.')
    } catch (err) {
      toast.error('Une erreur est survenue lors de la modification.')
    }
  }

  return (
    <Fragment>

      <CatalogCard
        catalog={catalog}
        toggle={toggle}
        formMethods={formMethods}
        onOpenImagePicker={() => cardImageRef.current?.open()} />

      <Panel.Content>

        <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

          <Panel.FormContainer>

            <Panel.Header>
              <Button
                title="Supprimer"
                onPress={() => onDelete(catalog!)}
                bordered
                important />
            </Panel.Header>

            <CatalogForm ref={cardImageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              loading={isLoading}
              onPress={() => onSubmit(formMethods, onUpdate)}
              title="Valider"
              grow
              large />
          </Panel.Actions>

        </Form>

      </Panel.Content>
    </Fragment>
  );
};

import { normalize } from '@app/helpers';
import styled from '@2pulse/responsive';
import { col } from '@app/style';

const Error = styled.View(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: '100%',
  // marginTop: -3,
  paddingHorizontal: normalize(21),
  backgroundColor: theme.colors.crimson,
  borderBottomLeftRadius: 13,
  borderBottomRightRadius: 13,
}));

const ErrorLabel = styled.Text(({ theme }) => ({
  color: theme.colors.babypowder,
  fontWeight: '800',
  fontSize: normalize(15),
  fontStyle: 'italic',
  paddingTop: normalize(5),
  paddingBottom: normalize(8),
}));

const Form = styled.View({
  ...col,
  rowGap: normalize(42),
  paddingBottom: normalize(89),
  paddingTop: normalize(21),
  height: '100%',
  flex: 1,
})

export const StyledForms = {
  Form,
  ErrorLabel,
  Error
} 

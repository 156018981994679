import { CardComposer, Row, Switch } from "@app/components";
import SvgArrowDown from "@app/components/icons/SvgArrowDown";
import { SvgTransferBack } from "@app/components/icons/SvgTransferBack";
import { theme } from "@app/theme";
import { ICatalogToPack, IProductVariation, convertToEuros } from "@tickeat/common";
import { useState } from "react";
import { CatalogProductToProductVariationForm } from "./CatalogProductToProductVariationForm";
import { PriceInput } from "@app/components/form/PriceInput";
import { useFormContext } from "react-hook-form";

interface CatalogToCatalogPackProps {
  name: string;
  onRemove: () => void;
}

export const CatalogToCatalogPack = ({ name, onRemove }: CatalogToCatalogPackProps) => {
  const { watch } = useFormContext()

  const [isOpen, setIsOpen] = useState(false);

  const catalogToPack = watch(name);

  const tagsAsString = (catalogToPack?.pack?.tags || []).map(tag => tag.name).join(', ');

  const price = convertToEuros(catalogToPack?.price ?? catalogToPack.pack.price);
  const deliveryPrice = convertToEuros(catalogToPack?.deliveryPrice ?? catalogToPack.pack.deliveryPrice);
  const takeAwayPrice = convertToEuros(catalogToPack?.takeAwayPrice ?? catalogToPack.pack.takeAwayPrice);
  const onSpotPrice = convertToEuros(catalogToPack?.onSpotPrice ?? catalogToPack.pack.onSpotPrice);

  if (!isOpen) {
    return (
      <CardComposer.SelectedItem >

        <CardComposer.SelectedItemTrigger onPress={() => setIsOpen(true)}>

          <Row align='center'>
            <CardComposer.SelectedItemIcon onPress={() => onRemove()}>
              <SvgTransferBack color={theme.colors.babypowder} />
            </CardComposer.SelectedItemIcon>
            <CardComposer.SelectedItemName>{catalogToPack.pack.name}</CardComposer.SelectedItemName>
          </Row>

          <Row align='center'>
            <CardComposer.SelectedItemCategory>{tagsAsString}</CardComposer.SelectedItemCategory>
            <CardComposer.SelectedItemDivider />

            <CardComposer.SelectedItemIcon onPress={() => setIsOpen(true)}>
              <SvgArrowDown color={theme.colors.babypowder} />
            </CardComposer.SelectedItemIcon>
          </Row>

        </CardComposer.SelectedItemTrigger>

        <CardComposer.SelectedItemDropdown />

      </CardComposer.SelectedItem>
    )
  }

  return (
    <CardComposer.SelectedItem>

      <CardComposer.SelectedItemTrigger isOpen onPress={() => setIsOpen(false)}>

        <Row align='center'>
          <CardComposer.SelectedItemIcon onPress={() => onRemove()}>
            <SvgTransferBack color={theme.colors.babypowder} />
          </CardComposer.SelectedItemIcon>
          <CardComposer.SelectedItemName isOpen>{catalogToPack.pack.name}</CardComposer.SelectedItemName>
        </Row>

        <Row align='center'>
          <CardComposer.SelectedItemCategory>{tagsAsString}</CardComposer.SelectedItemCategory>
          <CardComposer.SelectedItemDivider />

          <CardComposer.SelectedItemIcon onPress={() => setIsOpen(false)}>
            <SvgArrowDown color={theme.colors.babypowder} />
          </CardComposer.SelectedItemIcon>
        </Row>

      </CardComposer.SelectedItemTrigger>

      <CardComposer.SelectedItemDropdown isOpen>

        <CardComposer.DropdownHeader>
          <CardComposer.DropdownHeaderName>Déclinaison</CardComposer.DropdownHeaderName>
          <CardComposer.DropdownHeaderItem>Base</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Livraison</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Emporter</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Sur place</CardComposer.DropdownHeaderItem>
          <CardComposer.DropdownHeaderItem>Actif</CardComposer.DropdownHeaderItem>
        </CardComposer.DropdownHeader>

        <CardComposer.DropdownItem>
          <CardComposer.DropdownItemName numberOfLines={1}>{catalogToPack.pack.name}</CardComposer.DropdownItemName>
          <CardComposer.DropdownItemPrices>
            <CardComposer.DropdownItemPrice isChanged={!!catalogToPack?.price}>
              <PriceInput nude center size='small' name={`${name}.price`} placeholder={price?.toString()} />
            </CardComposer.DropdownItemPrice>

            <CardComposer.DropdownItemPrice isChanged={!!catalogToPack?.deliveryPrice}>
              <PriceInput nude center size='small' name={`${name}.deliveryPrice`} placeholder={deliveryPrice?.toString()} />
            </CardComposer.DropdownItemPrice>

            <CardComposer.DropdownItemPrice isChanged={!!catalogToPack?.takeAwayPrice}>
              <PriceInput nude center size='small' name={`${name}.takeAwayPrice`} placeholder={takeAwayPrice?.toString()} />
            </CardComposer.DropdownItemPrice>

            <CardComposer.DropdownItemPrice isChanged={!!catalogToPack?.onSpotPrice}>
              <PriceInput nude center size='small' name={`${name}.onSpotPrice`} placeholder={onSpotPrice?.toString()} />
            </CardComposer.DropdownItemPrice>

            <CardComposer.DropdownItemAction>
              <Switch name={`${name}.disabled`} inversed />
            </CardComposer.DropdownItemAction>
          </CardComposer.DropdownItemPrices>
        </CardComposer.DropdownItem>

      </CardComposer.SelectedItemDropdown>

    </CardComposer.SelectedItem>
  )
}
import { IVariation } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi } from '../api';

export const variationApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<IVariation>(builder, 'variations', 'Variations');

    return {
      createOneVariation: endpoints.createOne(),
      readManyVariation: endpoints.readMany(),
      readOneVariation: endpoints.readOne(),
      updateOneVariation: endpoints.updateOne(),
      deleteOneVariation: endpoints.deleteOne(),
    }
  },
});

import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
const SvgStats = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 34 30" width="100%" height="100%" {...props}>
    <G fill="none" fillRule="evenodd" opacity={0.34}>
      <Path d="M-3-5h40v40H-3z" />
      <Path
        stroke="#FDFFFC"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M32 27.5H2v-25"
      />
      <Path
        stroke="#FDFFFC"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.5 5 17 17.5l-5-5-10 10"
      />
      <Path
        stroke="#FDFFFC"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.5 11.25V5h-6.25"
      />
    </G>
  </Svg>
);
export default SvgStats;


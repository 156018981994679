import { IsNotEmpty, IsOptional, Min } from "class-validator";
import { IBase, IIngredient } from "../../interfaces";

export class IngredientDTO implements Omit<IIngredient, keyof IBase | 'ingredientShops'>  {
  @IsNotEmpty({ message: 'Le nom de l\'ingrédient est requis' })
  name: string;

  @IsNotEmpty({ message: 'L\'unité est requise' })
  unit: string;

  @IsNotEmpty({ message: 'Le prix est requis' })
  @Min(0, { message: 'Le prix doit être supérieur ou égal à 0' })
  price: number;

  @IsNotEmpty({ message: 'Le lot est requis' })
  bundle: string;

  @IsOptional()
  supplier: string;
}

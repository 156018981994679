import styled from '@2pulse/responsive';
import { usePaginationRange } from '@app/hooks/usePaginationRange';
import { row } from '@app/style';
import { Table } from '@tanstack/react-table'
import React from "react"
import { PaginationButton } from '@app/components';

const PaginationContainer = styled.View(({ theme }) => ({
  ...row,
  overflow: 'hidden',
  justifyContent: 'space-between',
  gap: 8,
  paddingVertical: 16,
  paddingRight: 40,
  paddingLeft: 115,
}));

const PaginationSection = styled.View(({ theme }) => ({
  ...row,
  gap: 8,
}));

export const Pagination = <TData,>({ table }: { table: Table<TData> }) => {
  const currentPage = table.getState().pagination.pageIndex + 1;

  const paginationRange = usePaginationRange({
    total: table.getPageCount() * table.getState().pagination.pageSize,
    perPage: table.getState().pagination.pageSize,
    currentPage
  });

  const goToPage = (page: number | string, index: number) => {
    const { pageIndex } = table.getState().pagination;

    if (typeof page === 'number') {

      table.setPageIndex(page - 1);

    } else {

      const currentIndex = paginationRange.findIndex(pagination => pagination === pageIndex);

      if (currentIndex < index) {

        table.setPageIndex((paginationRange as number[])[index - 1]);

      } else {

        table.setPageIndex(((paginationRange as number[])[index + 1]) - 2);

      }

    }
  }

  return (
    <PaginationContainer>

      <PaginationSection>
        {/* <PaginationButton
          title='<<'
          onPress={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()} /> */}
        <PaginationButton
          title='Précedent'
          onPress={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        />
      </PaginationSection>

      <PaginationSection>
        {paginationRange.map((page, index) => (
          <PaginationButton
            small
            inactive={page !== currentPage}
            title={page.toString()}
            key={index}
            onPress={() => goToPage(page, index)}
          />
        ))}
      </PaginationSection>

      <PaginationSection>
        <PaginationButton
          title='Suivant'
          onPress={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        />
        {/* <PaginationButton
          title='>>'
          onPress={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        /> */}
      </PaginationSection>

    </PaginationContainer>
  )
}

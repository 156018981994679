import React, { } from 'react';
import { View } from 'react-native';
import { useFormContext } from 'react-hook-form';
import styled from '@2pulse/responsive';
import { Toggle } from '@app/components/form/Toggle';
import { DaysSelect, getDaysFR, Days } from '@app/components/form/selects/features/DaysSelect';
import { DateHourPicker } from '@app/components/form/DateTimeAndHourPicker';
import { Button, ButtonIcon, SvgBin } from '@app/components';
import { AvailabilityTypeEnum } from '@tickeat/common';
import { TimeSlotInput } from '@app/components/form/TimeSlotInput';
import { col, row, worksansoxford } from '@app/style';
import { normalize } from '@app/helpers';
import { isDefined } from '@app/helpers/kindOf';

const AvailabilitySection = styled.View({
  marginTop: 21,
  // paddingBottom: 20,
});

const TimeSlotPickerContainer = styled.View({
  ...row,
  alignItems: "center",
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: 13,
});

const TimeSlotsFormRow = styled.View(({ theme }) => ({
  ...row,
  overflow: 'hidden',
  alignItems: 'center',
  borderTopWidth: 1,
  borderTopColor: theme.colors.translucid,
  height: normalize(76)
}));

const Label = styled.Text({
  fontWeight: 'bold',
  marginBottom: 10,
  color: 'white'
});

const TimeSlotLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  // textAlign: 'center',
  fontWeight: '400',
  fontSize: normalize(21),
  color: theme.colors.babypowder,
  width: 120,
  flexShrink: 0
}));

const TimeSlotFormLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  fontWeight: '700',
  fontSize: normalize(21),
  paddingTop: normalize(34),
  paddingBottom: normalize(21),
  textAlign: 'center',
  color: theme.colors.babypowder,
}));

const TimeSlotPickerSection = styled.View({
  ...col,
  gap: 21,
  marginTop: 21,
});


const TimeSlotRow = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: 190,
  overflow: 'hidden',
});

const TimeSlotsRow = styled.ScrollView({});

const TimeSlotFormContainer = styled.View(({ theme }) => ({
  ...col,
  minHeight: 76,
  backgroundColor: 'rgba(240, 249 , 252, .04)',
  marginTop: 21
}));

const TimeSlotFormContent = styled.View(({ theme }) => ({
  padding: 20,
}));

const TimeSlotDelete = styled.View(({ theme }) => ({
  position: 'absolute',
  top: 1,
  right: 5
}));


const MenuAvailabilityForm = () => {
  const { watch, setValue, getValues } = useFormContext();

  const [availabilityType, availabilitySchedule = {}, selectedDay, startTime, endTime] = watch([
    "availabilityType",
    "availabilitySchedule",
    "selectedDay",
    "startTime",
    "endTime"
  ]);

  const convertTimeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  const convertMinutesToTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  const addTimeSlot = () => {
    if (selectedDay && startTime && endTime) {
      const startTimeInMinutes = convertTimeToMinutes(startTime);
      const endTimeInMinutes = convertTimeToMinutes(endTime);

      const currentTimes = getValues(`availabilitySchedule.${selectedDay}`) || [];
      setValue(`availabilitySchedule.${selectedDay}`, [...currentTimes, [startTimeInMinutes, endTimeInMinutes]]);
      setValue('startTime', '');
      setValue('endTime', '');
    }
  }

  const removeTimeSlot = (day: Days, index: number) => {
    const currentTimes = getValues(`availabilitySchedule.${day}`) || [];
    const updatedTimes = currentTimes.filter((_, i) => i !== index);
    setValue(`availabilitySchedule.${day}`, updatedTimes);
  }

  return (
    <View>
      <Toggle
        name="availabilityType"
        options={[
          { title: "Toujours disponible", value: AvailabilityTypeEnum.always },
          { title: "Jours spécifiques", value: AvailabilityTypeEnum.specificDays },
        ]}
      />

      {availabilityType === AvailabilityTypeEnum.specificDays && (
        <AvailabilitySection>
          <DaysSelect name='selectedDay' label='Choisir le jour' />

          {selectedDay && (
            <TimeSlotPickerSection>
              <TimeSlotPickerContainer>
                <DateHourPicker
                  name='startTime'
                  label="heure de début"
                  mode="time"
                />
                <DateHourPicker
                  name='endTime'
                  label="heure de fin"
                  mode="time"
                />
              </TimeSlotPickerContainer>
              <Button onPress={addTimeSlot} title="Ajouter cet horaire" />
            </TimeSlotPickerSection>
          )}

          <TimeSlotFormContainer>
            <TimeSlotFormLabel>Horaires de disponiblité</TimeSlotFormLabel>
            <TimeSlotFormContent>
              {Object.values(Days)
                .filter((day) => isDefined(availabilitySchedule) && availabilitySchedule[day] && availabilitySchedule[day].length > 0)
                .map((day) => (
                  <TimeSlotsFormRow>
                    <TimeSlotLabel>{getDaysFR(day)}</TimeSlotLabel>
                    <TimeSlotsRow
                      contentContainerStyle={{
                        flexDirection: 'row',
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 8
                      }}
                      showsHorizontalScrollIndicator={false}
                      horizontal
                    >
                      {availabilitySchedule[day]?.map((slot: [number, number], index: number) => (
                        <TimeSlotRow>
                          <TimeSlotInput
                            // center
                            size="small"
                            keyboardType='numeric'
                            showError={false}
                            name={''}
                            value={`${convertMinutesToTime(slot[0])} - ${convertMinutesToTime(slot[1])}`}
                          />
                          <TimeSlotDelete>
                            <ButtonIcon nude onPress={() => removeTimeSlot(day, index)} Icon={SvgBin} />
                          </TimeSlotDelete>
                        </TimeSlotRow>
                      ))}
                    </TimeSlotsRow>
                  </TimeSlotsFormRow>
                ))}
            </TimeSlotFormContent>
          </TimeSlotFormContainer>
        </AvailabilitySection>
      )}

      {availabilityType === AvailabilityTypeEnum.always && (
        <View>
          <Label>Le menu est disponible tout le temps</Label>
        </View>
      )}
    </View>
  );
};

export default MenuAvailabilityForm;
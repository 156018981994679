import { Type } from "class-transformer";
import { IsBoolean, IsEmail, IsNotEmpty, IsOptional, IsUrl, ValidateNested } from "class-validator";
import { IBase, IShop, ISocialMediaLinks } from "../interfaces";

export class ShopDTO implements Omit<IShop, keyof IBase  | 'deliveryZones' | 'slug' | 'printers'> {
  @IsNotEmpty({ message: 'Le nom interne est requis' })
  internalName: string;

  @IsNotEmpty({ message: 'Le numéro de siret est requis' })
  siret: string;

  @IsNotEmpty({ message: 'Le nom publique est requis' })
  publicName: string;

  @IsNotEmpty({ message: 'La description est requise' })
  description: string;

  @IsNotEmpty({ message: 'L\'adresse est requise' })
  address: string;

  @IsNotEmpty({ message: 'Le numéro de téléphone est requis' })
  phone: string;

  @IsOptional()
  contractVAD: string;

  @IsNotEmpty({ message: 'L\'email est requis' })
  @IsEmail(undefined, { message: 'Le format de l\'email n\'est pas bon' })
  email: string;

  @IsOptional({ message: 'Le site internet est requis' })
  @IsUrl({}, { message: 'Le lien du site internet est incorrect' })
  website: string;

  @IsOptional()
  disabled: boolean;

  @IsOptional()
  brands: string[];

  @IsNotEmpty({ message: 'Les coordonées GPS sont requises' })
  // @Type(() => LocationPointDTO)
  @ValidateNested()
  location: {
      coordinates: number[];
      type: 'Point';
  };

  // @Type(() => ScheduleDTO)
  @ValidateNested()
  takeAwaySchedule: any;

  // @Type(() => ScheduleDTO)
  @ValidateNested()
  deliverySchedule: any;

  @IsOptional()
  takeAwayTime: number;

  @IsOptional()
  deliveryTime: number;

  @IsOptional()
  defaultTakeAwayTime: number;

  @IsOptional()
  defaultDeliveryTime: number;

  @IsOptional()
  eshopMessage: string;

  @IsOptional()
  @IsBoolean({ message: 'La livraison doit être active ou non' })
  isDeliveryOpen: boolean;

  @IsOptional()
  @IsBoolean({ message: 'La livraison doit être active ou non' })
  isTakeawayOpen: boolean;

  @IsOptional()
  @ValidateNested()
  socialMediaLinks?: ISocialMediaLinks;

}
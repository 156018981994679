import React, { Fragment, memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { productApi } from '@app/store';
import { ProductDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { ProductForm } from './ProductForm';
import { defaultProductVariationsFormValues, ProductFormValues, useProductFormSubmit } from './product-form.helpers';
import { useToast, useTriptych } from '@app/hooks';
import { ProductCard } from './ProductCard';
import { InputFileRef } from '@app/components/form/InputFile';

export const ProductCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const cardImageRef = useRef<InputFileRef>();

  const onSubmit = useProductFormSubmit();

  const [create, { isLoading, error: apiErrors }] = productApi.useCreateOneProductsMutation();

  const formMethods = useForm<ProductFormValues>({
    resolver: classValidatorResolver(ProductDTO),
    defaultValues: {
      image: null,
      isAlcohol: false,
      name: '',
      description: '',
      disabled: false,
      options: [],
      tags: [],
      allergens: [],
      productVariations: [
        defaultProductVariationsFormValues
      ],
      isDeclinable: false
    },
  });

  const onCreate = async (formValues: FormData) => {
    try {
      const product = await create(formValues).unwrap();

      triptych.close({ mode: 'UPDATE', data: product });

      toast.success('Votre produit a bien été ajouté.')
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre produit.')
    }
  }

  return (
    <Fragment>
      <ProductCard
        formMethods={formMethods}
        toggle={() => triptych.toggle()}
        onOpenImagePicker={() => cardImageRef.current?.open()} />

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.Content>

          <Panel.FormContainer>

            <ProductForm ref={cardImageRef} />

          </Panel.FormContainer>

          <Panel.Actions>
            <Button
              title="Retour"
              grow
              large
              bordered
              onPress={() => triptych.close()} />

            <Button
              title="Valider"
              loading={isLoading}
              onPress={() => onSubmit(formMethods, onCreate)}
              grow
              large />
          </Panel.Actions>

        </Panel.Content>

      </Form>

    </Fragment>
  );
});

import { Platform } from 'react-native';
import Config from 'react-native-config';

const ENV = {
  API_URL: process.env.API_URL,
};

export const getEnv = (key: keyof typeof ENV): string => {
  if (Platform.OS === 'web') {
    return !!(window as any)?.__env ? (window as any).__env[key] : ENV[key];
  }

  return Config[key] as string;
};

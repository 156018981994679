import { CardComposer, Switch } from "@app/components"
import { PriceInput } from "@app/components/form/PriceInput"
import { IProductVariation, convertToEuros } from "@tickeat/common";
import { ICatalogProductToProductVariation } from "@tickeat/common/src/interfaces/catalog/catalog-product-to-product-variation.interface";
import { useFormContext } from "react-hook-form";

interface CatalogProductToProductVariationFormProps {
  name: string;
  productVariation: IProductVariation;
}

export const CatalogProductToProductVariationForm = ({ name, productVariation }: CatalogProductToProductVariationFormProps) => {
  const { watch } = useFormContext();

  const catalogProductToProductVariation: ICatalogProductToProductVariation = watch(name);

  const price = convertToEuros(catalogProductToProductVariation?.price ?? productVariation.price);
  const deliveryPrice = convertToEuros(catalogProductToProductVariation?.deliveryPrice ?? productVariation.deliveryPrice);
  const takeAwayPrice = convertToEuros(catalogProductToProductVariation?.takeAwayPrice ?? productVariation.takeAwayPrice);
  const onSpotPrice = convertToEuros(catalogProductToProductVariation?.onSpotPrice ?? productVariation.onSpotPrice);

  return (
    <CardComposer.DropdownItem key={productVariation._id}>
      <CardComposer.DropdownItemName numberOfLines={1}>{productVariation.name}</CardComposer.DropdownItemName>
      <CardComposer.DropdownItemPrices>
        <CardComposer.DropdownItemPrice isChanged={!!catalogProductToProductVariation?.price}>
          <PriceInput nude center size='small' name={`${name}.price`} placeholder={price?.toString()} />
        </CardComposer.DropdownItemPrice>

        <CardComposer.DropdownItemPrice isChanged={!!catalogProductToProductVariation?.deliveryPrice}>
          <PriceInput nude center size='small' name={`${name}.deliveryPrice`} placeholder={deliveryPrice?.toString()} />
        </CardComposer.DropdownItemPrice>

        <CardComposer.DropdownItemPrice isChanged={!!catalogProductToProductVariation?.takeAwayPrice}>
          <PriceInput nude center size='small' name={`${name}.takeAwayPrice`} placeholder={takeAwayPrice?.toString()} />
        </CardComposer.DropdownItemPrice>

        <CardComposer.DropdownItemPrice isChanged={!!catalogProductToProductVariation?.onSpotPrice}>
          <PriceInput nude center size='small' name={`${name}.onSpotPrice`} placeholder={onSpotPrice?.toString()} />
        </CardComposer.DropdownItemPrice>

        <CardComposer.DropdownItemAction>
          <Switch name={`${name}.disabled`} inversed />
        </CardComposer.DropdownItemAction>
      </CardComposer.DropdownItemPrices>
    </CardComposer.DropdownItem>
  )
}
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { variationApi } from '@app/store';
import { IVariation, VariationDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { VariationForm } from './VariationForm';
import { VariationFormValues } from './variation-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormVariation = (variation?: Partial<IVariation>): VariationFormValues => {
  return {
    name: variation?.name,
    position: variation?.position ?? 1
  }
}

interface VariationUpdateProps {
  variation?: IVariation;
  isFetching?: boolean;
  onDelete: (product: Partial<IVariation>) => void;
}

export const VariationUpdate = ({ variation, isFetching, onDelete }: VariationUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = variationApi.useUpdateOneVariationMutation();

  const formMethods = useForm<VariationFormValues>({
    resolver: classValidatorResolver(VariationDTO),
    defaultValues: transformToUpdatableFormVariation(variation),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormVariation(variation));
  }, [variation, formMethods.reset]);

  const onUpdate = async (formValues: VariationFormValues) => {
    try {
      const updatedVariation = await update({ _id: variation?._id, ...formValues }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedVariation });

      toast.success('Modification effectuée avec succès.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(variation!)}
              bordered
              important />
          </Panel.Header>

          <VariationForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

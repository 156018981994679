import { IBase } from "../base.interface";
import { IPack } from "./pack.interface";
import { IProductVariation } from "./product-variation.interface";

export interface IPackToProductVariation extends IBase {
  position: number;

  productVariation: IProductVariation;

  productVariationId: string;

  pack: IPack;

  packId: string;
}
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgCheck = (props: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50"  {...props}>
    <Path
      d="M42 13 19.333 37 8 25"
      stroke={props.color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SvgCheck;


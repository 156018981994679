import { Paths } from "../interfaces";

export const sortByAlhabetical = <T>(property: (Paths<T, 3> & string), sort: 'asc' | 'desc' = 'asc') => {
  return (a: T, b: T) => {
    const resolvePath = (value: T) => {
      return property.split('.').reduce((prev, curr) => (prev as any)?.[curr], value) as string;
    };

    if (typeof resolvePath(a) !== 'string') {
      return 1;
    }

    const result = (resolvePath(a)).localeCompare(resolvePath(b));

    return sort === 'desc' ? -1 * result : result;
  }
}
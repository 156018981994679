import { ArrayMaxSize, ArrayMinSize, ArrayNotEmpty, IsArray, IsNotEmpty, IsUUID } from "class-validator";

export class LocationPointDTO {
  @IsArray({ message: 'Les coordonnées doivent être sous forme d\'un tableau' })
  @ArrayNotEmpty({ message: 'Les coordonées GPS sont requises' })
  @ArrayMinSize(2, { message: 'La latitude et la longitude sont requises' })
  @ArrayMaxSize(2, { message: 'La latitude et la longitude sont requises' })
  coordinates: number[];

  @IsNotEmpty()
  type: 'Point' = 'Point';
}

export class EntityReadOneDTO {
  @IsNotEmpty()
  _id: string;
}

export class TypeORMRelationId {
  @IsNotEmpty({ message: 'Ce champs est requis' })
  @IsUUID('4')
  _id: string;
}
import { TextInput } from '@app/components';
import { Toggle } from '@app/components/form/Toggle';
import { StyledForms } from '@app/components/styled/form/Form';
import React from 'react';

const socialMediaPlatform = [
  { platform: 'facebook', label: 'Facebook' },
  { platform: 'twitter', label: 'Twitter' },
  { platform: 'linkedin', label: 'Linkedin' },
  { platform: 'instagram', label: 'Instagram' },
];

export const ShopForm = () => {
  return (
    <StyledForms.Form>

      <TextInput
        name="publicName"
        label="Nom publique"
        autoCapitalize="none" />

      <TextInput
        name="internalName"
        label="Nom interne"
        autoCapitalize="none" />

      <Toggle
        name='disabled'
        options={[
          { title: "Actif", value: false },
          { title: "Inactif", value: true },
        ]} />

      <TextInput
        name="siret"
        label="Siret" />

      <TextInput
        name="description"
        label="Description" />

      <TextInput
        name="address"
        label="Adresse" />

      <TextInput
        name="phone"
        label="Téléphone" />

      <TextInput
        name="email"
        label="E-mail" />

      <Toggle
        name='isDeliveryOpen'
        options={[
          { title: "Ouvert en livraison", value: true },
          { title: "Fermé en livraison", value: false },
        ]} />

      <Toggle
        name='isTakeawayOpen'
        options={[
          { title: "Ouvert à emporter", value: true },
          { title: "Fermé à emporter", value: false },
        ]} />


      {socialMediaPlatform.map((socialMedia, index) => {
        return (
          <TextInput
            key={index}
            name={`socialMediaLinks.${socialMedia.platform}`}
            label={`Lien ${socialMedia.label}`} />
        )
      })}
    </StyledForms.Form>
  );
};

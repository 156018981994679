import { BaseSelect } from '@app/components';
import { shopApi } from '@app/store';
import { createColumnHelper } from '@tanstack/react-table';
import { IShop } from '@tickeat/common';
import React from 'react';
import { ResourceSelect, ResourceSelectParentProps } from '../ResourceSelect';

const columnHelper = createColumnHelper<IShop>();

export const ShopSelect = ({ label = 'Restaurants', ...props }: ResourceSelectParentProps<IShop>) => {
  const columns = [
    // @ts-ignore
    columnHelper.accessor('publicName', {
      header: () => (
        <BaseSelect.ListHeaderItem width="100%">
          Nom publique
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="100%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
  ];

  return (
    <ResourceSelect
      label={label}
      nameKey='publicName'
      columns={columns}
      transform={{
        value: (item: any) => item,
        accessor: (item: any) => item?.['_id'],
        display: (item: any) => item?.['publicName'],
      }}
      useQuery={shopApi.useReadManyShopsQuery}
      {...props} />
  );
};

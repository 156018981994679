import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
const SvgList = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 32 24" width="100%" height="100%" {...props}>
    <G fill="none" fillRule="evenodd" opacity={0.34}>
      <Path d="M-4-8h40v40H-4z" />
      <Path
        stroke="#FDFFFC"
        strokeWidth={3.3}
        fill="#000"
        fillRule="nonzero"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 12h27.5M2.25 2h27.5M2.25 22h27.5"
      />
    </G>
  </Svg>
);
export default SvgList;


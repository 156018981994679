import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { optionApi } from '@app/store';
import { IOption, OptionDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { OptionForm } from './OptionForm';
import { OptionFormValues } from './option-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormOption = (option?: Partial<IOption>): OptionFormValues => {
  return {
    name: option?.name,
    disabled: option?.disabled,
    mandatory: option?.mandatory,
    freeQuantity: option?.freeQuantity,
    maxChoiceCount: option?.maxChoiceCount,
    products: option?.products,
    position: option?.position
  }
}

interface OptionUpdateProps {
  option?: IOption;
  isFetching?: boolean;
  onDelete: (product: Partial<IOption>) => void;
}

export const OptionUpdate = ({ option, isFetching, onDelete }: OptionUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = optionApi.useUpdateOneOptionMutation();

  const formMethods = useForm<OptionFormValues>({
    resolver: classValidatorResolver(OptionDTO),
    defaultValues: transformToUpdatableFormOption(option),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormOption(option));
  }, [option, formMethods.reset]);

  const onUpdate = async (formValues: OptionFormValues) => {
    try {
      const updatedOption = await update({ _id: option?._id, ...formValues }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedOption });

      toast.success('Votre option a bien été modifiée.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification de votre option.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(option!)}
              bordered
              important />
          </Panel.Header>

          <OptionForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

import React from 'react';
import { TextInputProps } from 'react-native';
import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { row, worksansoxford } from '@app/style';
import SvgPlus from '../icons/SvgPlus';
import { ButtonIcon } from './buttons/ButtonIcon';
import SvgMinus from '../icons/SvgMinus';
import { isNumber } from '@app/helpers/kindOf';
import { useInput } from '@app/hooks/useInput';

export interface NumberTextInputProps {
  inside?: boolean;
  size?: "large" | "small";
}

export const NumberTextInput = styled.TextInput<NumberTextInputProps>(({ theme, props }) => ({
  height:
    props.size === "large" ? normalize(92) :
      props.size === "small" ? normalize(58) :
        normalize(76),
  ...worksansoxford,
  fontSize: normalize(21),
  fontWeight: '500',
  aspectRatio: "1/1",
  color: theme.colors.babypowder,
  textAlign: 'center',
  borderRadius: 100,
  backgroundColor: props.size === "small" ? theme.colors.oxfordblue : 'rgba(240, 249 , 252, .04)',
  paddingHorizontal: props.size === "small" ? normalize(21) : normalize(34),
}));

const NumberSelectorContainer = styled.View(({ theme }) => ({
  ...row,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  columnGap: normalize(21),
  alignItems: 'center',
  justifyContent: 'center',
}));
const NumberSelectorLabel = styled.Text(({ theme }) => ({
  ...worksansoxford,
  color: theme.colors.babypowder,
  flexBasis: '100%',
  fontWeight: '700',
  fontSize: normalize(21),
  paddingBottom: normalize(21),
  textAlign: 'center',
}));

interface NumberSelectorProps extends TextInputProps {
  name: string;
  label: string;
  min?: number;
  max?: number;
}

export const NumberSelector = ({ name, min = 0, max, label }: NumberSelectorProps) => {
  const { field } = useInput(name);

  const onChangeText = (value: string) => {
    field.onChange(+value.replace(/[^0-9]/g, ''));
  }

  const decrement = () => {
    if (!isNumber(min) || (isNumber(min) && (field.value - 1) >= min)) {
      field.onChange(field.value - 1);
    }
  }

  const increment = () => {
    if (!isNumber(max) || (isNumber(max) && (field.value + 1) <= max)) {
      field.onChange(field.value + 1);
    }
  }

  return (
    <NumberSelectorContainer>
      {label && <NumberSelectorLabel>{label}</NumberSelectorLabel>}

      <ButtonIcon onPress={decrement} Icon={SvgMinus} />

      <NumberTextInput
        onBlur={field.onBlur}
        value={field.value}
        ref={field.ref}
        onChangeText={onChangeText}
        size='large'
        placeholder="0"
        keyboardType='numeric'
        placeholderTextColor="white"
        autoCapitalize="none" />

      <ButtonIcon onPress={increment} Icon={SvgPlus} />

    </NumberSelectorContainer>
  );
};

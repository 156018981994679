import { DeleteModal, DeleteModalProps, Modal } from "@app/components/modals"
import { ModalInstance } from "@app/hooks/useModal"
import { ICatalog } from "@tickeat/common";

interface CatalogDeleteModalProps extends ModalInstance<ICatalog>, Omit<DeleteModalProps<ICatalog>, 'children'> {
  onDelete: (product: Partial<ICatalog>) => void;
}

export const CatalogDeleteModal = (props: CatalogDeleteModalProps) => {
  return (
    <DeleteModal {...props} >
      <Modal.Subtitle>Vous allez supprimer un catalogue.</Modal.Subtitle>
      <Modal.Message>
        Cette suppression est irréversible.
      </Modal.Message>
    </DeleteModal>
  )
}
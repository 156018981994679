import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { useResourceScreenHelpers } from '@app/hooks';
import { shopApi } from '@app/store';
import { SettingHeader } from '@app/features/SettingHeader';
import { IAdvertisement } from '@tickeat/common';
import { AdvertisementList } from '@app/features/Advertisement/AdvertisementList';
import { AdvertisementCreate } from '@app/features/Advertisement/AdvertisementCreate';
import { AdvertisementUpdate } from '@app/features/Advertisement/AdvertisementUpdate';
import { AdvertisementDeleteModal } from '@app/features/Advertisement/AdvertisementDeleteModal';
import { advertisementApi } from '@app/store/services/advertisement';

export const PublicityScreen = () => {
    const [readOne, { isFetching }] = advertisementApi.useLazyReadOneAdvertisementQuery();
    const [deleteOne, { isLoading }] = advertisementApi.useDeleteOneAdvertisementMutation();

    const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: advertisement } = useResourceScreenHelpers<IAdvertisement>();

    const openCard = (item: Partial<IAdvertisement>) => {
        onUpdate(readOne({
            filters: { _id: { $eq: item?._id } },
            populate:['shopIds'],
        }), item)
    }

    return (
        <MainLayout
            header={(
                <SettingHeader
                    buttonTitle='Ajouter une publicité'
                    onPress={() => triptych.open({ mode: 'CREATE' })} />
            )}>

            <AdvertisementDeleteModal
                {...modal}
                onDelete={(advertisement) => onDelete(deleteOne(advertisement._id!))}
                isLoading={isLoading} />

            <Triptych
                list={(
                    <AdvertisementList
                        selectedRowItem={selectedItem}
                        onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
                        onUpdate={openCard}
                        onDelete={modal.open} />
                )}
                form={(
                    mode === 'CREATE'
                        ? <AdvertisementCreate />
                        : <AdvertisementUpdate
                            advertisement={advertisement ?? selectedItem}
                            toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.close()}
                            isFetching={isFetching}
                            onDelete={modal.open} />
                )} />
        </MainLayout>
    );
};


import React from 'react';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';
const SvgInfo = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 34 34" width="100%" height="100%" {...props}>
    <G transform="translate(-3 -3)" fill="none" fillRule="evenodd">
      <Path d="M0 0h40v40H0z" />
      <Circle
        stroke={"#FDFFFC"}
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        cx={20}
        cy={20}
        r={15}
      />
      <Path
        stroke={"#FDFFFC"}
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.75 18.75H20v8.75h1.25"
      />
      <Circle fill={"#FDFFFC"} fillRule="nonzero" cx={19.688} cy={13.125} r={1.875} />
    </G>
  </Svg>
);
export default SvgInfo;


import { isDefined } from "@app/helpers/kindOf";
import { IBase, ICatalog, IShop } from "@tickeat/common";
import { UseFormReturn } from "react-hook-form";

export interface CatalogFormValues extends Partial<ICatalog> {
  image: any;
}

export const transformCatalogFormValuesToPayload = (values: Omit<Partial<ICatalog>, keyof IBase>): any => {

  (values.catalogToShops as unknown as IShop[]).map((shop) => (console.log(shop)))

  return {
    ...values,
    catalogToShops: (values.catalogToShops as unknown as { _id: string, catalogShopId?: string }[]).map((item) => ({
      _id: item.catalogShopId,
      shopId: item._id
    })),
    catalogToPacks: values.catalogToPacks?.map(catalogToPack => ({
      ...catalogToPack,
      pack: { _id: catalogToPack.pack._id }
    })),
    catalogToProducts: values.catalogToProducts?.map(catalogToProduct => ({
      ...catalogToProduct,
      product: { _id: catalogToProduct.product._id },
      catalogProductToProductVariations: catalogToProduct.catalogProductToProductVariations.map(catalogProductToProductVariation => ({
        ...catalogProductToProductVariation,
        productVariation: { _id: catalogProductToProductVariation.productVariation._id }
      }))
    }))
  }
}

export const useCatalogFormSubmit = () => {
  return async (formMethods: UseFormReturn<any, any>, submitCb: (payload: FormData) => void) => {
    const values = formMethods.getValues();

    const payload = transformCatalogFormValuesToPayload(values);

    const formData = new FormData();

    for (const [key, value] of Object.entries(payload) as any) {
      if (key === 'ticketInfos') {
        for (let [nestedKey, nestedValue] of Object.entries(value) as any) {
          if (isDefined(nestedValue)) {
            formData.append(`${key}[${nestedKey}]`, nestedValue as string);
          }
        }
      } else {
        formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value as string);
      }
    }

    const isValid = await formMethods.trigger();

    if (isValid) {
      submitCb(formData);
    }
  }
}

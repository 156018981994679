import { ForwardedRef, useCallback, useImperativeHandle, useState } from "react";
import { Easing, runOnJS, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

export interface TranslateRef {
  open: () => void;
  close: () => void;
  toggle: () => void;
  getIsOpen: () => boolean;
}

interface UseTranslateProps {
  maxValue: number;
  duration?: number;
}

export const useTranslate = (ref: ForwardedRef<TranslateRef> | null, { maxValue, duration = 350 }: UseTranslateProps) => {
  const offset = useSharedValue(0);

  const [isOpen, setIsOpen] = useState(false);

  const handleAnimationEnd = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const slide = useCallback((toValue: number) => {
    offset.value = withTiming(
      toValue,
      { duration, easing: Easing.out(Easing.ease) },
      () => {
        runOnJS(handleAnimationEnd)(toValue !== 0);
      },
    );
  }, [duration]);

  const open = useCallback(() => {
    slide(-maxValue);
  }, [maxValue]);

  const close = useCallback(() => {
    slide(0);
  }, []);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [isOpen])

  const getIsOpen = useCallback(() => {
    return isOpen;
  }, [isOpen])

  const styles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offset.value }],
    };
  });

  useImperativeHandle(ref, () => ({
    open,
    close,
    toggle,
    getIsOpen
  }), [isOpen, maxValue]);

  return {
    styles,
    offset,
    isOpen,
    open,
    close,
    toggle,
    getIsOpen
  };
}
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { ICatalogToPack } from "../../interfaces";
import { ICatalogToProduct } from "../../interfaces/catalog/catalog-to-product.interface";
import { ICatalogToTag } from "../../interfaces/catalog/catalog-to-tag.interface";
import { CatalogToProductDTO } from "./catalog-to-product.dto";
import { CatalogToPackDTO } from "./catalog-to-pack.dto";
import { ICatalogToPromotion } from "../../interfaces/catalog/catalog-to-promotion.interface";

export class CatalogDTO {
  @IsNotEmpty({message: 'Le nom du catalogue est requis'})
  name: string;

  @Type(() => Object)
  image: any;

  @IsOptional()
  startDate: Date;

  @IsOptional()
  endDate: Date;

  @IsOptional()
  @IsArray({ message: 'Le format de données est inccorect'})
  catalogToPromotions: ICatalogToPromotion[];

  // @IsNotEmpty({message: 'Les plateformes du catalogue sont requises'})
  // @IsArray({ message: 'Le format de données est inccorect'})
  // @ArrayMinSize(1, { message: 'Au moins une platforme est requise' })
  // platforms: IPlatform[];

  @IsOptional()
  @IsArray({ message: 'Le format de données est inccorect'})
  catalogToTags: ICatalogToTag[];

  @IsOptional()
  @IsArray({ message: 'Le format de données est inccorect'})
  @Type(() => CatalogToPackDTO)
  @ValidateNested({
    message: 'Le pack n\'est pas correct'
  })
  catalogToPacks: ICatalogToPack[];

  @IsOptional()
  @IsArray({ message: 'Le format de données est inccorect'})
  @Type(() => CatalogToProductDTO)
  @ValidateNested({
    message: 'Le produit n\'est pas correct'
  })
  catalogToProducts: ICatalogToProduct[];

  // @IsOptional()
  // @IsArray({ message: 'Le format de données est inccorect'})
  // @Type(() => CatalogShopDTO)
  // @ValidateNested({
  //   message: 'Le catalogue magasin n\'est pas correct'
  // })
  // catalogToShops: CatalogShopDTO[];
}
import { Card, CardButtonActionMode, HeaderGradient, MiniCard, Row } from '@app/components';
import React, { memo } from 'react';
import { theme } from '@app/theme';
import { useDynamicSizes } from '@app/hooks';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet } from 'react-native';
import { IAccountCode } from '@tickeat/common';
import { useAppSelector } from '@app/store/hooks';
import { triptychSelectors } from '@app/store';

type AccountCodeCardProps = {
  toggle: (mode: CardButtonActionMode) => void;
};

const formatCardData = (partialAccountCode: Partial<IAccountCode>) => {
  return {
    name: partialAccountCode?.name || 'Nouveau code comptable'
  }
}

export const AccountCodeCard = memo(({ toggle }: AccountCodeCardProps) => {
  const { subContainerHeight } = useDynamicSizes();

  const partialAccountCode = useAppSelector<Partial<IAccountCode>>(triptychSelectors.selectData);
  const cardData = useAppSelector<Partial<IAccountCode>>(triptychSelectors.selectCardData);

  const { name } = formatCardData(cardData ?? partialAccountCode);

  return (
    <Card>

      <HeaderGradient>
        <LinearGradient
          start={{ x: 0.5, y: 0 }}
          end={{ x: 0.5, y: 1 }}
          locations={[0, 1]}
          colors={['rgba(253, 255, 252, 0)', 'rgba(253, 255, 252, 1)']}
          style={styles.headerGradient}
        />
      </HeaderGradient>

      <Card.TopHeader>
        <Card.TopHeaderLabel>Fiche code comptable</Card.TopHeaderLabel>
      </Card.TopHeader>

      <Card.Container height={subContainerHeight}>
        <Card.Background source={require('@app/assets/img/cardbackground.png')} />

        <Card.Content>
          <Card.Header>
            <Row justify="space-between" align="center">
              <Card.HeaderTitles>
                <Card.Title numberOfLines={2}>{name}</Card.Title>
              </Card.HeaderTitles>

              <Card.ButtonAction onPress={toggle} />
            </Row>
          </Card.Header>
        </Card.Content>

        <MiniCard>
          <MiniCard.Container />
        </MiniCard>
      </Card.Container>
    </Card>
  );
});


const styles = StyleSheet.create({
  headerGradient: {
    flex: 1,
    height: theme.sizes.listHeaderHeight,
  },
});

import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { col, row, worksansbaby } from '@app/style';
import { theme } from '@app/theme';
import { Portal } from '@tickeat/ui';
import React, { Children, Fragment, useCallback, useEffect, useState } from 'react';
import { Keyboard, KeyboardAvoidingView, StyleSheet, View } from 'react-native';
import Animated, { Extrapolation, interpolate, useAnimatedStyle, useDerivedValue, withDelay, withTiming } from 'react-native-reanimated';
import SvgWizardbackground from '../decor/SvgWizardBackground';
import { ButtonIcon, Form } from '../form';
import SvgArrowDown from '../icons/SvgArrowDown';
import { SvgArrowLeft } from '../icons/SvgArrowLeft';
import { SvgArrowRight } from '../icons/SvgArrowRight';
import { SvgArrowUp } from '../icons/SvgArrowUp';
import { SvgCross } from '../icons/SvgCross';
import { SvgSave } from '../icons/SvgSave';
import { Row } from '../layout';
import { SlantTitle } from '../typography';
import { WizardProps, WizardStepProps } from './wizard.interface';
import { WizardProvider } from './WizardProvider';
import { useWizard } from './useWizard';
import { useWizardState } from './useWizardState';
import { useFormContext } from 'react-hook-form';
import { useInput } from '@app/hooks/useInput';

const Background = styled.View({
  width: '100%',
  height: '100%',
  opacity: 1,
  position: 'absolute',
  top: 0,
  left: 0
});
const Container = styled.View({
  zIndex: 1,
  ...row,
  height: '100%',
  columnGap: normalize(89),
  paddingLeft: normalize(89),
  paddingRight: normalize(34),
});

const Help = styled.View({
  width: '30%',
  justifyContent: 'center',
  rowGap: normalize(34),
});

const HelpText = styled.Text({
  ...worksansbaby,
  textAlign: 'center',
  fontSize: normalize(21),
  lineHeight: normalize(28),
});

const FormSlider = styled.View({
  flexGrow: 1,
  justifyContent: 'center',
});

const FormStep = styled.View<{ active?: boolean, outofscope?: boolean, ghost?: boolean, previous?: boolean, next?: boolean }>(({ props }) => ({
  // paddingVertical: props.active ? normalize(55) : 0,
  marginBottom: props.previous ? normalize(55) : 0,
  marginTop: props.next ? normalize(55) : 0,
  opacity: props.ghost ? 0 : 1,
  display: props.outofscope ? 'none' : 'flex',
  maxHeight: '60%',
}));

const FormStepTrigger = styled.TouchableOpacity({
  marginBottom: normalize(21),
});

const FormStepTitle = Animated.Text;

const FormStepContent = styled.ScrollView({
  overflow: 'hidden',
});

const Actions = styled.View({
  width: normalize(66),
  height: '100%',
  alignItems: 'center',
});

const Navigation = styled.View<{ visible?: boolean }>(({ props }) => ({
  ...col,
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  rowGap: props.visible ? normalize(21) : normalize(89),
  opacity: props.visible ? 0 : 1,
}));

const Close = styled.View({
  position: 'absolute',
  zIndex: 9,
  top: normalize(34),
  justifyContent: 'center',
  alignItems: 'center'
});

const Stepper = styled.Text({
  ...worksansbaby,
  fontSize: normalize(21),
  letterSpacing: 2,
  opacity: 0.4,
});

const Ghost = styled.View({
  height: 80
});

interface GhostsProps {
  total: number;
}

const Ghosts = ({ total }: GhostsProps) => {
  return (
    <Fragment>
      {[...new Array(total)].map((_, index) => <Ghost key={index} />)}
    </Fragment>
  )
}

const WizardStepper = ({ nbOfSteps, children }) => {
  const [step, setStep] = useState(1);

  const childrenAsArray = Children.toArray(children);

  const next = () => {
    if (step < nbOfSteps) {
      setStep(prev => prev += 1);
    }
  }

  const previous = () => {
    if (step > 1) {
      setStep(prev => prev -= 1);
    }
  }

  return (
    <Fragment>
      {childrenAsArray[step - 1]}

      <Row justify="space-between" align='center'>
        <ButtonIcon
          onPress={previous}
          Icon={SvgArrowLeft}
          nude
          bordered />
        <Stepper>{step}/{nbOfSteps}</Stepper>
        <ButtonIcon
          onPress={next}
          Icon={SvgArrowRight}
          nude
          bordered />
      </Row>
    </Fragment>
  )
}

const fadeInDuration = 150;
const fadeOutDuration = 150;

// Update on children change, id too??
const Step = ({ id, title, helpText, children }: WizardStepProps) => {
  const { addStep, removeStep } = useWizard();

  const handleOnMountOrUpdate = useCallback((id, children) => {
    addStep({ id, title, helpText, nodes: Children.toArray(children) });
  }, [addStep, title, helpText]);

  const handleRemove = useCallback((id) => {
    removeStep({ id });
  }, [removeStep]);

  useEffect(() => {
    handleOnMountOrUpdate(id, children);

    return () => {
      handleRemove(id);
    }
  }, [id, children]);

  return children;
}

const WizardStep = ({ id, title, children }) => {
  const state = useWizardState();
  const { goToStep } = useWizard();

  const nbOfSteps = state.steps.length;

  const { error } = useInput(id);

  const step = state.steps.findIndex(step => step.id === id) + 1;

  const nbOfChildrens = Children.count(children);

  const isActive = state.currentStep === step;
  const isPrevious = state.currentStep > step;
  const isNext = state.currentStep < step;
  const isOutOfScope = (step + 2) < state.currentStep || (step - 2) > state.currentStep;

  const derivedActive = useDerivedValue(() => {
    return isActive;
  }, [isActive]);

  const animatedTitleStyles = useAnimatedStyle(() => {
    const duration = fadeInDuration;

    const opacity = withTiming(
      interpolate(+isActive, [0, 1], [0.34, 1], Extrapolation.CLAMP),
      { duration }
    );

    const fontSize = withTiming(
      interpolate(+isActive, [0, 1], [25, 31], Extrapolation.CLAMP),
      { duration }
    );

    return {
      opacity,
      fontSize
    };
  }, [isActive]);

  const contentHeight = useDerivedValue(() => {
    const isActiveAsNumber = +derivedActive.value;

    const animation = (duration: number) => withTiming(
      interpolate(isActiveAsNumber, [0, 1], [0, 100], Extrapolation.CLAMP),
      { duration }
    );

    if (isActiveAsNumber === 1) {
      return withDelay(fadeOutDuration, animation(fadeInDuration));
    }

    return animation(fadeOutDuration);
  }, []);

  const animatedContentStyles = useAnimatedStyle(() => {
    return {
      flexGrow: 0,
      height: `${contentHeight.value}%`,
    };
  }, []);

  return (
    <Fragment>
      {(isActive && state.currentStep === 0) && <Ghosts total={2} />}
      {(isActive && state.currentStep === 1) && <Ghosts total={1} />}

      <FormStep outofscope={isOutOfScope} active={isActive} previous={isPrevious} next={isNext}>

        <FormStepTrigger onPress={() => goToStep(step)}>
          <FormStepTitle style={[styles.formStepTitle, animatedTitleStyles, { color: !!error ? theme.colors.crimson : theme.colors.babypowder }]}>
            {title}
          </FormStepTitle>
        </FormStepTrigger>

        <Animated.View style={[styles.formStepContent, animatedContentStyles]}>
          <FormStepContent contentContainerStyle={{ rowGap: normalize(21) }}>
            {nbOfChildrens > 1
              ? <WizardStepper nbOfSteps={nbOfChildrens}>
                {children}
              </WizardStepper>
              : children
            }
          </FormStepContent>
        </Animated.View>

      </FormStep>

      {(isActive && state.currentStep === (nbOfSteps - 1)) && <Ghosts total={1} />}
      {(isActive && state.currentStep === nbOfSteps) && <Ghosts total={2} />}
    </Fragment>
  )
}

const WizardContainer = ({ onClose, onSave }) => {
  const state = useWizardState();
  const { nextStep, previousStep } = useWizard();

  const { helpText } = state.steps[state.currentStep - 1] ?? {};

  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false);
    });

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  return (
    <Container>

      <Help>
        <SlantTitle label='Aide' color={theme.colors.gold} />
        <HelpText>
          {helpText}
        </HelpText>
      </Help>

      <FormSlider>

        {state.steps.map(({ id, title, nodes }) => (
          <WizardStep key={id} id={id} title={title}  >
            {nodes}
          </WizardStep>
        ))}

      </FormSlider>

      <Actions>

        <Close>
          <ButtonIcon onPress={onClose} Icon={SvgCross} />
        </Close>

        <Navigation visible={isKeyboardVisible}>
          <ButtonIcon onPress={previousStep} Icon={SvgArrowUp} nude />
          <ButtonIcon onPress={onSave} large Icon={SvgSave} />
          <ButtonIcon onPress={nextStep} Icon={SvgArrowDown} nude />
        </Navigation>

      </Actions>

    </Container>
  )
}

// TODO: Passer les props au context pour sauvegarder/fermer de n'importe ou
export const Wizard = ({ isOpen, onClose, onSave, children, ...formProps }: WizardProps) => {
  // const opacity = useDerivedValue(() => {
  //   return withTiming(+isOpen, { duration: 150 });
  // }, [isOpen]);

  // const animatedStyles = useAnimatedStyle(() => {
  //   return {
  //     opacity: opacity.value,
  //     display: opacity.value === 0 ? 'none' : 'flex'
  //   };
  // });

  if (!isOpen) {
    return (
      <Form {...formProps}>
        {children}
      </Form>
    )
  }

  return (
    <Portal.Gate hostName='root'>
      <WizardProvider>
        <Animated.View style={[styles.keyboardAvoidingView]}>
          <KeyboardAvoidingView behavior='padding' style={[styles.keyboardAvoidingView]}>
            <Background>
              <SvgWizardbackground />
            </Background>

            <Form {...formProps}>
              <WizardContainer onClose={onClose} onSave={onSave} />

              <View style={{ display: 'none' }}>
                {children}
              </View>
            </Form>
          </KeyboardAvoidingView>
        </Animated.View>
      </WizardProvider>
    </Portal.Gate>
  )
}

Wizard.Step = Step;

const styles = StyleSheet.create({
  formStepContent: {
    overflow: 'hidden',
  },
  formStepTitle: {
    ...worksansbaby,
    fontWeight: '700',
  },
  keyboardAvoidingView: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 99999,
    flex: 1,
    overflow: 'hidden'
  }
})
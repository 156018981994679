import React from 'react';
import { BaseResourceListProps, SimpleList, SvgBin, SvgEdit } from '@app/components';
import { packApi } from '@app/store';
import { IPack } from '@tickeat/common';
import { theme } from '@app/theme';
import { createColumnHelper } from '@tanstack/react-table';
import { useTable } from '@app/hooks/useTable';

const columnHelper = createColumnHelper<IPack>();

export const PackList = ({ onUpdate, onDelete, onRowPress, selectedRowItem }: BaseResourceListProps<IPack>) => {
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Nom</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue()}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.accessor('packToSteps', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Etapes</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText align="left" bold numberOfLines={2} active={info.active}>
            {info.getValue().length}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.accessor('tags', {
      header: () => (
        <SimpleList.HeaderItem width="25%">
          <SimpleList.HeaderItemLabel>Catégories</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: (info) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemText numberOfLines={2} active={info.active}>
            {info.getValue()?.map(tag => tag.name).join(', ')}
          </SimpleList.ItemText>
        </SimpleList.ItemCol>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <SimpleList.HeaderItem width="25%" align='center'>
          <SimpleList.HeaderItemLabel>Actions</SimpleList.HeaderItemLabel>
        </SimpleList.HeaderItem>
      ),
      cell: ({ row, active }) => (
        <SimpleList.ItemCol width="25%">
          <SimpleList.ItemActions>
            <SimpleList.ItemAction onPress={() => onUpdate(row.original)}>
              <SvgEdit color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
            <SimpleList.ItemAction onPress={() => onDelete(row.original)}>
              <SvgBin color={active ? '#FFF' : theme.colors.celadonblue} />
            </SimpleList.ItemAction>
          </SimpleList.ItemActions>
        </SimpleList.ItemCol >
      ),
    }),
  ];

  const { table, setSearch, isFetching } = useTable({
    columns,
    useQuery: ({ pagination, search }) => packApi.useReadManyPacksQuery({
      pagination,
      filters: {
        name: {
          $contains: search
        }
      },
      sort: ['name:ASC'],
      populate: [
        'tags',
        'catalogToPacks',
        'catalogToPacks.catalog',
        'packToSteps',
        // 'packToSteps.step',
      ]
    })
  });

  return <SimpleList
    table={table}
    isFetching={isFetching}
    onSearch={setSearch}
    selectedRowItem={selectedRowItem}
    onRowPress={onRowPress} />;
};

import { BaseSelect } from '@app/components';
import { tagApi } from '@app/store';
import { createColumnHelper } from '@tanstack/react-table';
import { ITag } from '@tickeat/common';
import React from 'react';
import { ResourceSelect, ResourceSelectParentProps } from '../ResourceSelect';

const columnHelper = createColumnHelper<ITag>();

export const TagSelect = ({ label = 'Catégories', ...props }: ResourceSelectParentProps<ITag>) => {
  const columns = [
    // @ts-ignore
    columnHelper.accessor('name', {
      header: () => (
        <BaseSelect.ListHeaderItem width="100%">
          Nom
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => (
        <BaseSelect.ListItemCol width="100%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {info.getValue()}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      ),
    }),
  ];

  return (
    <ResourceSelect
      label={label}
      columns={columns}
      useQuery={tagApi.useReadManyTagQuery}
      {...props} />
  );
};

import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const SvgEdit = ({ strokePath, ...props }: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        d="M18.105 42.188h-8.73c-.863 0-1.563-.7-1.563-1.563v-8.73c0-.41.16-.804.45-1.094L31.699 7.363a1.562 1.562 0 0 1 2.227 0l8.71 8.711a1.563 1.563 0 0 1 0 2.227L19.2 41.738c-.29.29-.684.45-1.094.45h0ZM26.563 12.5 37.5 23.438M32.031 17.969l-18.75 18.75M18.652 42.09 7.91 31.348"
        stroke={props.color}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);


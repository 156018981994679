import { useEffect, useState } from "react";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
import { useTriptych } from "./useTriptych";
import { useAppSelector } from "@app/store/hooks";
import { triptychSelectors } from "@app/store";

// TODO: Supprimer cette chose et passer au même fonctionement que les produits (utilisation de watch)
export const useUpdateCardData = <TFieldsValues extends FieldValues, TFieldNames extends readonly FieldPath<TFieldsValues>[]>(
  formMethods: UseFormReturn<TFieldsValues, any, any>,
  watchKeys: readonly [...TFieldNames],
  exceptKeys?: readonly [...TFieldNames]
) => {
  const triptych = useTriptych();
  const [isDirty, setIsDirty] = useState(false);
  const isOpen = useAppSelector(triptychSelectors.selectIsOpen);

  useEffect(() => {
    if (!isDirty && formMethods.formState.isDirty) {
      setIsDirty(true);
    }

    return () => {
      setIsDirty(false);
    }
  }, [formMethods.formState.isDirty]);

  useEffect(() => {
    if (isOpen && isDirty) {
      const values = formMethods.getValues();

      for (const key of exceptKeys ?? []) {
        delete values[key];
      }

      triptych.setCardData(values);
    }
  }, [
    isDirty,
    isOpen,
    exceptKeys,
    triptych.setCardData,
    formMethods.getValues,
    formMethods.watch(watchKeys)
  ]);
}
export type Primitive =
  | null
  | undefined
  | string
  | number
  | boolean
  | symbol
  | bigint;

export type Writeable<T> = { - readonly [P in keyof T]: T[P] };

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

type Join<K, P> = K extends string | number
  ? P extends string | number
  ? `${K}${"" extends P ? "" : "."}${P}`
  : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? {
    [K in keyof T]-?: K extends string | number
    ? K | Join<K, Paths<T[K], Prev[D]>>
    : never;
  }[keyof T]
  : "";
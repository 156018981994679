import React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const SvgMenuCards = ({ strokePath, ...props }: SvgProps) => (
  <Svg viewBox="0 0 50 50" width={'100%'} height={'100%'} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h50v50H0z" />
      <Path
        d="M6 8.4v27.533a1 1 0 0 0 .676.946l17.544 6.013a1 1 0 0 0 .635.004l18.455-6.024a1 1 0 0 0 .69-.95V8.378a1 1 0 0 0-1.31-.95l-17.835 5.821a1 1 0 0 1-.635-.005L7.324 7.454A1 1 0 0 0 6 8.4Zm18.5 5.019V43"
        stroke={strokePath}
        strokeWidth={4.13}
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

import { getDynamicSizes, theme } from "@app/theme";
import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

export const useDynamicTheme = () => {
  const { width, height } = useWindowDimensions();

  return useMemo(() => ({
    ...theme,
    sizes: getDynamicSizes(width, height)
  }), [theme, width, height]);
}
import { IIngredient } from '@tickeat/common';
import { crudEndpoints } from '../crud-endpoints.factory';
import { rootApi } from '../api';

export const ingredientApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const endpoints = crudEndpoints<IIngredient>(builder, 'ingredients', 'Ingredients');

    return {
      createOneIngredient: endpoints.createOne(),
      readManyIngredient: endpoints.readMany(),
      readOneIngredient: endpoints.readOne(),
      updateOneIngredient: endpoints.updateOne(),
      deleteOneIngredient: endpoints.deleteOne(),
    }
  },
});

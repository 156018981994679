import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers/normalize';
import { ImageBackground, View } from 'react-native';

export const SkeletonFrame = () => {
  return (
    <ImageBackground
      style={{ width: '100%', height: '100%' }}
      resizeMode='repeat'
      source={require('../../assets/img/skeleton.gif')} />
  )
}

const Form = styled.View({
  gap: normalize(42),
  paddingLeft: 55,
  paddingRight: 55,
  paddingBottom: normalize(55),
  paddingTop: normalize(55),
  flexGrow: 1,
});

const InputFrame = styled.View({
  width: '100%',
  height: normalize(76),
  borderRadius: 5,
  overflow: 'hidden',
  opacity: 0.2,
});

const ButtonFrame = styled.View<{ large?: boolean }>(({ props }) => ({
  width: 200,
  height: props.large ? normalize(76) : normalize(58),
  borderRadius: 76,
  overflow: 'hidden',
  opacity: 0.2,
}));

interface SkeletonFormProps {
  inputTotal?: number;
}

export const SkeletonForm = ({ inputTotal = 5 }: SkeletonFormProps) => {
  return (
    <Form>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <ButtonFrame><SkeletonFrame /></ButtonFrame>
      </View>

      {[...new Array(inputTotal)].map((_, index) => (
        <InputFrame key={index}>
          <SkeletonFrame />
        </InputFrame>
      ))}

      <View style={{ flexDirection: 'row', marginTop: 'auto', justifyContent: 'space-between' }}>
        <ButtonFrame large><SkeletonFrame /></ButtonFrame>
        <ButtonFrame large><SkeletonFrame /></ButtonFrame>
      </View>
    </Form>
  )
}
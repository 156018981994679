import { useInput } from "@app/hooks/useInput";
import { forwardRef, useImperativeHandle, useRef } from "react"

export interface InputFileProps {
  name: string;
  isMultiple?: boolean;
}

export interface InputFileRef {
  open: () => void;
}

export const InputFile = forwardRef<InputFileRef, InputFileProps>(({ name, isMultiple }, ref) => {
  const { field } = useInput(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        inputRef.current?.click()
      }
    };
  }, [ref]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const maxUploadAllowedSizeInBytes = 20 * 1024 * 1024;
    const maxUploadAllowedSizeInMb = maxUploadAllowedSizeInBytes / (1024 * 1024);

    if (event.target.files && event.target.files![0].size > maxUploadAllowedSizeInBytes) {
      alert(`Le fichier est trop volumineux. Taille maximum: ${maxUploadAllowedSizeInMb} Mo`);
      event.target.value = '';
      return;
    }

    const files = isMultiple ? event.target.files! : event.target.files![0];

    field.onChange(files);
  };

  return (
    <input
      ref={inputRef}
      type="file"
      multiple={isMultiple}
      accept=".jpg,.png,.jpeg,.webp"
      onChange={onChange}
      style={{ display: 'none' }} />
  )
})
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
export const SvgSwitch = ({ strokePath, ...props }: SvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 50 50" {...props}>
    <G
      stroke={strokePath}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <Path d="m15 22-6-6.5L15 9M40.625 15.625H9.375M34 28l6 6.5-6 6.5M9.375 34.375h31.25" />
    </G>
  </Svg>
);

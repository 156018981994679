import React, { Fragment } from 'react';
import styled from '@2pulse/responsive';
import { normalize } from '@app/helpers';
import { row } from '@app/style';
import LinearGradient from 'react-native-linear-gradient';
import { Button } from './buttons/Button';
import { useInput } from '@app/hooks/useInput';

const ToggleContainer = styled.View(({ theme }) => ({
  ...row,
  height: normalize(76),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const ToggleBar = styled.View({
  height: 5,
  width: 35,
  backgroundColor: 'red',
  borderRadius: 40,
  transform: [{ rotate: '90deg' }]
});

type ToggleOption = {
  title: string;
  value: any;
}

type ToggleButtonProps = {
  formValue: any;
  onPress: (value: any) => void;
  option: ToggleOption;
};

const ToggleButton = ({ option, formValue, onPress }: ToggleButtonProps) => {
  return (
    <Button
      onPress={() => onPress(option.value)}
      title={option.title}
      square
      large
      grow
      inactive={formValue !== option.value}
      smallText />
  )
}

interface ToggleProps {
  name: string;
  options: ToggleOption[];
}

export const Toggle = ({ name, options }: ToggleProps) => {
  const { field } = useInput(name);

  return (
    <ToggleContainer>

      {options.map((option, index) => (
        <Fragment key={index}>
          <ToggleButton formValue={field.value} option={option} onPress={field.onChange} />

          {index !== (options.length - 1) && (
            <ToggleBar>
              <LinearGradient
                style={{ borderRadius: 5, flex: 1, width: '100%', position: 'absolute', height: '100%' }}
                start={{ x: .5, y: 0 }}
                end={{ x: 0.5, y: 1.0 }}
                locations={[0, .7]}
                colors={['#00BEE6', '#0089C8']} >
              </LinearGradient>
            </ToggleBar>
          )}

        </Fragment>
      ))}

    </ToggleContainer>
  );
};

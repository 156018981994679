import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { paymentMethodApi } from '@app/store';
import { IPaymentMethod, PaymentMethodDTO } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { PaymentMethodForm } from './PaymentMethodForm';
import { PaymentMethodFormValues } from './payment-method-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

const transformToUpdatableFormPaymentMethod = (paymentMethod?: Partial<IPaymentMethod>): PaymentMethodFormValues => {
  return {
    name: paymentMethod?.name,
    disabled: paymentMethod?.disabled,
    specialType: paymentMethod?.specialType
  }
}

interface PaymentMethodUpdateProps {
  paymentMethod?: IPaymentMethod;
  isFetching?: boolean;
  onDelete: (product: Partial<IPaymentMethod>) => void;
}

export const PaymentMethodUpdate = ({ paymentMethod, isFetching, onDelete }: PaymentMethodUpdateProps) => {
  const toast = useToast();

  const triptych = useTriptych()

  const [update, { isLoading, error: apiErrors }] = paymentMethodApi.useUpdateOnePaymentMethodsMutation();

  const formMethods = useForm<PaymentMethodFormValues>({
    resolver: classValidatorResolver(PaymentMethodDTO),
    defaultValues: transformToUpdatableFormPaymentMethod(paymentMethod),
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  useEffect(() => {
    formMethods.reset(transformToUpdatableFormPaymentMethod(paymentMethod));
  }, [paymentMethod, formMethods.reset]);

  const onUpdate = async (formValues: PaymentMethodFormValues) => {
    try {
      const updatedPaymentMethod = await update({ _id: paymentMethod?._id, ...formValues }).unwrap();

      triptych.close({ mode: 'UPDATE', data: updatedPaymentMethod });

      toast.success('Modification effectuée avec succès.')
    } catch {
      toast.error('Une erreur est survenue lors de la modification.')
    }
  }

  return (
    <Panel.Content>

      <Form isLoading={isFetching} formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <Panel.Header>
            <Button
              title="Supprimer"
              onPress={() => onDelete(paymentMethod!)}
              bordered
              important />
          </Panel.Header>

          <PaymentMethodForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            onPress={formMethods.handleSubmit(onUpdate)}
            title="Valider"
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
};

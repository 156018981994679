import { isFunction } from "@app/helpers/kindOf";
import { useAppDispatch } from "@app/store/hooks";
import { IToast, toasterSlice } from "@app/store/slices/toaster.slice";
import { ThunkDispatch, nanoid } from "@reduxjs/toolkit";
import { useCallback } from "react";

export const toast = {
  promise: (dispatch: ThunkDispatch<any, any, any>) => {
    return async (promiseOrFunc: (() => Promise<any>) | Promise<any>, messages: { pending: string, sucess: string; error: string }, options?: IToast['options']) => {
      const id = toast.open(dispatch)({
        message: messages.pending,
        options: {
          ...options!,
          autoClose: 999999
        },
        type: 'promise'
      });

      const promise = isFunction(promiseOrFunc) ? promiseOrFunc() : promiseOrFunc;

      promise
        .then(() => toast.update(dispatch)(id, {
          type: 'success',
          message: messages.sucess,
          options: { ...options!, autoClose: 3000 }
        }))
        .catch(() => toast.update(dispatch)(id, {
          type: 'error',
          message: messages.error,
          options: { ...options!, autoClose: 3000 }
        }))

      return promise;
    }
  },
  open: (dispatch: ThunkDispatch<any, any, any>) => {
    return (props: Pick<IToast, 'message' | 'options' | 'type'>) => {
      const id = nanoid();

      dispatch(toasterSlice.actions.openToast({ id, ...props }));

      return id;
    }
  },
  update: (dispatch: ThunkDispatch<any, any, any>) => {
    return (toastId: string, updates: Omit<IToast, 'id' | 'createdAt'>) => {
      dispatch(toasterSlice.actions.updateToast({ id: toastId, ...updates }));
    }
  },
  success: (dispatch: ThunkDispatch<any, any, any>) => {
    return (message: IToast['message'], options?: IToast['options']) => {
      return toast.open(dispatch)({ message, options, type: 'success' });
    }
  },
  error: (dispatch: ThunkDispatch<any, any, any>) => {
    return (message: IToast['message'], options?: IToast['options']) => {
      return toast.open(dispatch)({ message, options, type: 'error' });
    }
  },
  dismiss: (dispatch: ThunkDispatch<any, any, any>) => {
    return (id?: string) => {
      if (id) {
        dispatch(toasterSlice.actions.dismissOneToast(id));

        return;
      }

      dispatch(toasterSlice.actions.dismissAllToast());
    }
  }
}

export const useToast = () => {
  const dispatch = useAppDispatch();

  const open = useCallback((message: string, options?: IToast['options'] & { type: IToast['type'] }) => {
    return toast.open(dispatch)({ message, type: options?.type, options });
  }, []);

  const error = useCallback((message: string, options?: IToast['options']) => {
    return toast.error(dispatch)(message, options);
  }, []);

  const success = useCallback((message: string) => {
    return toast.success(dispatch)(message);
  }, []);

  const update = useCallback((toastId: string, updates: Omit<IToast, 'id' | 'createdAt'>) => {
    return toast.update(dispatch)(toastId, updates);
  }, []);

  const promise = useCallback((promise: (() => Promise<any>) | Promise<any>, messages: { pending: string, sucess: string; error: string }) => {
    return toast.promise(dispatch)(promise, messages);
  }, []);

  const dismiss = useCallback((id?: string) => {
    return toast.dismiss(dispatch)(id);
  }, []);

  return {
    promise,
    dismiss,
    update,
    open,
    error,
    success
  }
}

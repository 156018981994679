import styled from '@2pulse/responsive';
import { theme } from '@app/theme';
import { TouchableOpacityProps } from 'react-native';

const BaseButton = styled.TouchableOpacity<{ grow?: boolean, bordered?: boolean, nude?: boolean, large?: boolean }>(({ theme, props }) => ({
  borderRadius: 100,
  flexGrow: props.grow ? 1 : 0,
  height: props.large ? 66 : 48,
  width: props.large ? 66 : 48,
  alignItems: 'center',
  backgroundColor: props.nude ? 'transparent' : 'rgba(0,137,200,0.21)',
  borderWidth: props.bordered ? 2 : 0,
  borderColor: theme.colors.celadonblue,
}));

const ButtonIconFrame = styled.View<{ large?: boolean }>(({ props }) => ({
  height: '100%',
  width: props.large ? 40 : 32,
}));

type ButtonProps = {
  grow?: boolean;
  nude?: boolean;
  large?: boolean;
  bordered?: boolean;
  Icon: (...args: any[]) => JSX.Element;
} & TouchableOpacityProps;

export const ButtonIcon = ({ Icon, large, nude, grow, bordered, ...props }: ButtonProps) => {
  return (
    <BaseButton grow={grow} nude={nude} large={large} bordered={bordered} {...props}>
      <ButtonIconFrame large={large}>
        <Icon color={nude ? theme.colors.babypowder : theme.colors.celadonblue} />
      </ButtonIconFrame>
    </BaseButton>
  )
}
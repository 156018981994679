import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { accountCodeApi } from '@app/store';
import { AccountCodeDTO, getRandomInt } from '@tickeat/common';
import { Button, Form, Panel } from '@app/components';
import { AccountCodeForm } from './AccountCodeForm';
import { AccountCodeFormValues } from './account-code-form.helpers';
import { useToast, useTriptych, useUpdateCardData } from '@app/hooks';

export const AccountCodeCreate = memo(() => {
  const toast = useToast();

  const triptych = useTriptych()

  const [create, { isLoading, error: apiErrors }] = accountCodeApi.useCreateOneAccountCodesMutation();

  const formMethods = useForm<AccountCodeFormValues>({
    resolver: classValidatorResolver(AccountCodeDTO),
    defaultValues: {
      name: '',
      code: getRandomInt(0, 999999)
    },
  });

  // @ts-ignore
  useUpdateCardData(formMethods, ['name']);

  const onCreate = async (formValues: AccountCodeFormValues) => {
    try {
      const accountCode = await create(formValues).unwrap();

      triptych.close({ mode: 'UPDATE', data: accountCode });

      toast.success('Votre compte comptable a bien été ajouté.');
    } catch {
      toast.error('Une erreur est survenue lors de la création de votre compte comptable.');
    }
  }

  return (
    <Panel.Content>

      <Form formMethods={formMethods} apiErrors={apiErrors}>

        <Panel.FormContainer>

          <AccountCodeForm />

        </Panel.FormContainer>

        <Panel.Actions>
          <Button
            title="Retour"
            grow
            large
            bordered
            onPress={() => triptych.close()} />

          <Button
            loading={isLoading}
            title="Valider"
            onPress={formMethods.handleSubmit(onCreate)}
            grow
            large />
        </Panel.Actions>

      </Form>

    </Panel.Content>
  );
});

import { BaseSelect, Select, SelectProps } from '@app/components';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

const columnHelper = createColumnHelper<Days>();

export enum Days {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export const getDaysFR = (day: Days) => {
  switch (day) {
    case Days.MONDAY:
      return "Lundi";
    case Days.TUESDAY:
      return "Mardi";
    case Days.WEDNESDAY:
      return "Mercredi";
    case Days.THURSDAY:
      return "Jeudi";
    case Days.FRIDAY:
      return "Vendredi";
    case Days.SATURDAY:
      return "Samedi";
    case Days.SUNDAY:
      return "Dimanche";
  }
}

export const DaysSelect = ({ label = 'Jour de disponibilité', ...props }: Omit<SelectProps<any>, 'table'>) => {
  const columns = [
    columnHelper.display({
      id: 'jour',
      header: () => (
        <BaseSelect.ListHeaderItem width="100%">
          Jours
        </BaseSelect.ListHeaderItem>
      ),
      cell: (info) => {
        return <BaseSelect.ListItemCol width="100%">
          <BaseSelect.ListItemText numberOfLines={1} bold>
            {getDaysFR(info.row.original)}
          </BaseSelect.ListItemText>
        </BaseSelect.ListItemCol>
      },
    }),
  ];

  const table = useReactTable({
    data: Object.values(Days) as Days[],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Select
      label={label}
      table={table}
      transform={{
        accessor: (item) => item,
        value: (item) => item,
        display: (item) => getDaysFR(item)
      }}
      {...props} />
  );
};

import React from 'react';
import { Platform, Switch as RNSwitch, SwitchProps as RNSwitchProps } from 'react-native';
import { StyledInputs } from '../styled/form/Inputs';
import { StyledForms } from '../styled/form/Form';
import { useInput } from '@app/hooks/useInput';
import { theme } from '@app/theme';

export interface SwitchProps extends RNSwitchProps {
  name: string;
  inversed?: boolean;
}

export const Switch = ({ name, inversed, ...props }: SwitchProps) => {
  const { field, error } = useInput(name);

  const value = inversed ? !field.value : field.value;

  return (
    <StyledInputs.Holder style={{ backgroundColor: 'transparent' }}>
      {!!error && (
        <StyledForms.Error>
          <StyledForms.ErrorLabel>
            {error}
          </StyledForms.ErrorLabel>
        </StyledForms.Error>
      )}

      <RNSwitch
        onValueChange={(value) => field.onChange(inversed ? !value : value)}
        value={value}
        {...Platform.select({
          web: {
            activeThumbColor: theme.colors.celadonblue
          }
        })}
        thumbColor={value ? theme.colors.celadonblue : theme.colors.babypowder}
        trackColor={{
          false: theme.colors.satanGray,
          true: theme.colors.babypowder
        }}
        ref={field.ref}
        {...props} />
    </StyledInputs.Holder>
  );
};

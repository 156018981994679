import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';

type IToastOption = {
  onOpen?: () => void;
  onClose?: () => void;
  autoClose: number | false;
}

export type IToast = {
  id: string;
  type?: 'promise' | 'success' | 'error';
  message: string;
  options?: IToastOption;
  createdAt: string;
}

const toastersAdapter = createEntityAdapter<IToast>();

const defaultOptions: IToastOption = {
  autoClose: 3000,
}

export const toasterSlice = createSlice({
  name: 'toasters',
  initialState: toastersAdapter.getInitialState(),
  reducers: {
    openToast(state, action: PayloadAction<Pick<IToast, 'id' | 'message' | 'options' | 'type'>>) {
      toastersAdapter.addOne(state, {
        id: action.payload.id,
        type: action.payload.type ?? 'error',
        message: action.payload.message,
        options: action.payload.options ?? defaultOptions,
        createdAt: new Date().toISOString(),
      })
    },
    updateToast(state, action: PayloadAction<Pick<IToast, 'id' | 'message' | 'options' | 'type'>>) {
      toastersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          type: action.payload.type,
          message: action.payload.message,
          options: action.payload.options ?? defaultOptions,
        }
      })
    },
    dismissAllToast(state) {
      toastersAdapter.removeAll(state)
    },
    dismissOneToast(state, action: PayloadAction<IToast['id']>) {
      toastersAdapter.removeOne(state, action.payload)
    },
  },
});

export const toastersSelectors = toastersAdapter.getSelectors((state: RootState) => state.toasters);

import React from 'react';
import { MainLayout, Triptych } from '@app/components';
import { PromotionList, PromotionCard, PromotionDeleteModal, PromotionCreate, PromotionUpdate } from '@app/features/Promotion';
import { useResourceScreenHelpers } from '@app/hooks';
import { promotionApi } from '@app/store';
import { IPromotion } from '@tickeat/common';
import { SettingHeader } from '@app/features/SettingHeader';

export const PromotionScreen = () => {
  const [readOne, { isFetching }] = promotionApi.useLazyReadOnePromotionsQuery();
  const [deleteOne, { isLoading }] = promotionApi.useDeleteOnePromotionsMutation();

  const { triptych, modal, onDelete, onUpdate, mode, selectedItem, item: promotion } = useResourceScreenHelpers<IPromotion>();

  const openCard = (item: Partial<IPromotion>) => {
    onUpdate(readOne({
      filters: { _id: { $eq: item._id } },
      populate: [
        'requiredProductVariations',
        'discountableProductVariations',
      ]
    }), item)
  }

  return (
    <MainLayout
      header={(
        <SettingHeader
          buttonTitle='Ajouter une promotion'
          onPress={() => triptych.open({ mode: 'CREATE' })} />
      )}>

      <PromotionDeleteModal
        {...modal}
        onDelete={(promotion) => onDelete(deleteOne(promotion._id!))}
        isLoading={isLoading} />

      <Triptych
        list={(
          <PromotionList
            selectedRowItem={selectedItem}
            onRowPress={(item) => triptych.close({ data: item, mode: 'UPDATE' })}
            onUpdate={openCard}
            onDelete={modal.open} />
        )}
        card={(
          <PromotionCard toggle={(mode) => mode === 'UPDATE' ? openCard(selectedItem) : triptych.toggle()} />
        )}
        form={(
          mode === 'CREATE'
            ? <PromotionCreate />
            : <PromotionUpdate
              promotion={promotion}
              isFetching={isFetching}
              onDelete={modal.open} />
        )} />
    </MainLayout>
  );
};


import React, { useState } from 'react';
import { Button, Calendar, CardComposer, Col, Row, SelectOptionsClose, SelectOptionsCloseLabel, SvgBin } from '@app/components';
import { modalsSelectors, packApi, productApi } from '@app/store';
import { SvgSwitch } from '@app/components/icons/SvgSwitch';
import { SvgTransfer } from '@app/components/icons/SvgTransfer';
import { SlantTitle } from '@app/components/typography';
import { theme } from '@app/theme';
import { extractData } from '@app/helpers/utils';
import { Modal } from '@app/components/modals';
import { useAppSelector } from '@app/store/hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useModal } from '@app/hooks/useModal';
import { CatalogToCatalogProduct } from './CatalogToCatalogProduct';
import { getPlatformFR } from '@tickeat/common/src/interfaces/catalog/platform.interface';
import { ICatalogToProduct } from '@tickeat/common/src/interfaces/catalog/catalog-to-product.interface';
import { ICatalogToTag } from '@tickeat/common/src/interfaces/catalog/catalog-to-tag.interface';
import { ICatalogToPack, ITag } from '@tickeat/common';
import { CatalogToCatalogPack } from './CatalogToCatalogPack';

export const CatalogOverrides = ({ modalId }: { modalId: string }) => {
  const modal = useModal(modalId);
  const { control, watch } = useFormContext();

  const [selectedTagId, setSelectedTagId] = useState<string | undefined>();

  const modalState = useAppSelector(state => modalsSelectors.selectById(state, modalId));

  const [
    name,
    startDate,
    endDate,
    platforms = [],
    catalogToTags = []
  ] = watch(['name', 'startDate', 'endDate', 'platforms', 'catalogToTags']);

  const tags = (catalogToTags as ICatalogToTag[]).map(catalogToTag => catalogToTag.tag);

  const { prepend: prependProduct, remove: removeProduct, fields: productFields } = useFieldArray({
    name: 'catalogToProducts',
    control
  });

  const { prepend: prependPack, remove: removePack, fields: packsFields } = useFieldArray({
    name: 'catalogToPacks',
    control
  });

  const packIdsInCatalog = packsFields.map(catalogToPack => (catalogToPack as unknown as ICatalogToPack)?.pack?._id);
  const productIdsInCatalog = productFields.map(catalogToProduct => (catalogToProduct as unknown as ICatalogToProduct)?.product?._id);

  const { data: productsData } = productApi.useReadManyProductsQuery({
    filters: {},
    sort: ['name:ASC'],
    populate: [
      'tags',
      'productVariations'
    ],
  });

  const { data: packsData } = packApi.useReadManyPacksQuery({
    filters: {},
    sort: ['name:ASC'],
    populate: [
      'tags',
    ],
  });

  const packs = extractData(packsData)
    .filter(pack => {
      const isInCatalog = packIdsInCatalog.some(packId => packId === pack._id);
      const hasSelectedTag = !selectedTagId || pack.tagsIds.includes(selectedTagId);

      return !isInCatalog && hasSelectedTag;
    });

  const products = extractData(productsData)
    .filter(product => {
      const isInCatalog = productIdsInCatalog.some(productId => productId === product._id);
      const hasSelectedTag = !selectedTagId || product.tagsIds.includes(selectedTagId);

      return !isInCatalog && hasSelectedTag;
    });

  const onSelectTag = (tag?: ITag) => {
    setSelectedTagId(tag?._id);
  }

  return (
    <Modal isOpen={!!modalState?.isOpen}>
      <Calendar.Details>

        <Calendar.DetailsHeader>
          <Calendar.DetailsTitle numberOfLines={2}>{name}</Calendar.DetailsTitle>

          {/* <Row justify="space-between">
            <Col align='center'>
              <Calendar.DetailsKey>Plateformes</Calendar.DetailsKey>
              <Calendar.DetailsValue>{platforms.map(getPlatformFR).join(', ')}</Calendar.DetailsValue>
            </Col>
            <Col align='center'>
              <Calendar.DetailsKey>Date de début</Calendar.DetailsKey>
              <Calendar.DetailsValue>{startDate || '-'}</Calendar.DetailsValue>
            </Col>
            <Col align='center'>
              <Calendar.DetailsKey>Date de fin</Calendar.DetailsKey>
              <Calendar.DetailsValue>{endDate || '-'}</Calendar.DetailsValue>
            </Col>
          </Row> */}

          <SelectOptionsClose>
            <SelectOptionsCloseLabel>
              <Button
                title="Fermer"
                grow
                large
                bordered
                onPress={() => modal.close()} />
            </SelectOptionsCloseLabel>
          </SelectOptionsClose>
        </Calendar.DetailsHeader>

        <Calendar.DetailsBody>

          <CardComposer>

            <CardComposer.Container>

              <CardComposer.ProductList>

                <SlantTitle label="Vos produits & menus" color={theme.colors.gold} />

                <CardComposer.List contentContainerStyle={{ display: 'flex', rowGap: 13 }} showsVerticalScrollIndicator={false}>

                  {packs
                    .map((pack) => (
                      <CardComposer.ListItem key={pack._id} onPress={() => prependPack({
                        pack,
                        disabled: false
                      })}>
                        <CardComposer.ListItemName>{pack.name}</CardComposer.ListItemName>
                        <Row align='center'>
                          <CardComposer.ListItemCategory>{pack.tags[0]?.name}</CardComposer.ListItemCategory>
                          <CardComposer.ListItemIcon>
                            <SvgTransfer color={theme.colors.babypowder} />
                          </CardComposer.ListItemIcon>
                        </Row>
                      </CardComposer.ListItem>
                    ))}

                  {products
                    .map((product) => (
                      <CardComposer.ListItem key={product._id} onPress={() => prependProduct({
                        product,
                        catalogProductToProductVariations: product.productVariations.map(productVariation => ({
                          price: null,
                          deliveryPrice: null,
                          takeAwayPrice: null,
                          onSpotPrice: null,
                          disabled: false,
                          productVariation
                        }))
                      })}>
                        <CardComposer.ListItemName>{product.name}</CardComposer.ListItemName>
                        <Row align='center'>
                          <CardComposer.ListItemCategory>{product.tags[0]?.name}</CardComposer.ListItemCategory>
                          <CardComposer.ListItemIcon>
                            <SvgTransfer color={theme.colors.babypowder} />
                          </CardComposer.ListItemIcon>
                        </Row>
                      </CardComposer.ListItem>
                    ))}

                </CardComposer.List>

              </CardComposer.ProductList>

              <CardComposer.Divider>

                <CardComposer.DividerHeader>
                  <SvgSwitch color={theme.colors.babypowder} />
                </CardComposer.DividerHeader>

                <CardComposer.Categories contentContainerStyle={{gap:8}}>
                  <Button
                    title="Toutes"
                    onPress={() => onSelectTag()}
                    square
                    bordered={!!selectedTagId} />

                  {tags.map(tag => (
                    <Button
                      key={tag._id}
                      onPress={() => onSelectTag(tag)}
                      title={tag.name}
                      square
                      bordered={selectedTagId !== tag._id} />
                  ))}
                </CardComposer.Categories>

              </CardComposer.Divider>

              <CardComposer.ProductList>

                <SlantTitle label="Votre catalogue" color={theme.colors.gold} />

                <CardComposer.List contentContainerStyle={{ display: 'flex', rowGap: 13 }} showsVerticalScrollIndicator={false}>

                  {packsFields
                    .map((field: any, index) => {
                      const isDisplayed = !selectedTagId || field.pack.tagsIds.includes(selectedTagId);

                      if (!isDisplayed) {
                        return null;
                      }

                      return (
                        <CatalogToCatalogPack
                          key={field.id}
                          name={`catalogToPacks.${index}`}
                          onRemove={() => removePack(index)} />
                      )
                    })}

                  {productFields
                    .map((field: any, index) => {
                      const isDisplayed = !selectedTagId || field.product.tagsIds.includes(selectedTagId);

                      if (!isDisplayed) {
                        return null;
                      }

                      return (
                        <CatalogToCatalogProduct
                          key={field.id}
                          name={`catalogToProducts.${index}`}
                          catalogToProduct={field}
                          onRemove={() => removeProduct(index)} />
                      )
                    })}

                </CardComposer.List>

              </CardComposer.ProductList>

            </CardComposer.Container>

          </CardComposer>

        </Calendar.DetailsBody>

      </Calendar.Details>
    </Modal>
  )

}